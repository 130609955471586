import { Box, Checkbox, CheckboxProps, useTheme } from '@mui/material';
import { Row } from '@tanstack/table-core';
import { useEffect, useRef } from 'react';
import { Holiday } from '../../../types';

type Props = {
  row?: Row<Holiday>;
  inHeader?: boolean;
  indeterminate?: boolean;
} & CheckboxProps;

export function CheckboxComponent({ indeterminate, ...rest }: Props) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const ref = useRef<HTMLInputElement>(null!);
  const { colors } = useTheme();

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <Box
      paddingLeft={4}
      maxHeight={48}
      minHeight={48}
      display={'flex'}
      alignItems={'center'}
      gap={'8px'}
    >
      <Checkbox
        inputRef={ref}
        indeterminate={indeterminate}
        inputMode='text'
        sx={{
          color: colors.border.default,
          '&.Mui-checked': {
            color: colors.textAccent.default,
          },
        }}
        {...rest}
      />
    </Box>
  );
}
