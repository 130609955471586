import React, { useEffect } from 'react';

type TUseOutsideClick = (ref: React.RefObject<HTMLElement> | null, callback: () => void) => void;

export const useOutsideClick: TUseOutsideClick = (ref, callback) => {
  useEffect(() => {
    if (!ref) return;
    const handleClickOutside = (event: globalThis.MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        callback();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};
