import { PageHeader } from '../layout/PageHeader';
import { ContentWrapper } from '../layout/styled';
import { AccontantTimesheetStatusesTable } from './components/AccontantTimesheetStatusesTable';

export function AccountantTimesheetStatuses() {
  return (
    <>
      <PageHeader
        title='Timesheet statuses'
        description=''
        breadcrumbs={[{ title: 'Timesheet statuses' }]}
      />
      <ContentWrapper>
        <AccontantTimesheetStatusesTable />
      </ContentWrapper>
    </>
  );
}
