import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export interface IAxiosHttpConf {
  baseURL: string;
}

export class AxiosHttp {
  private readonly config: IAxiosHttpConf;

  private axiosInstance: AxiosInstance;

  constructor(config: IAxiosHttpConf) {
    this.config = config;
    this.axiosInstance = this.initAxios();
  }

  async get<ResponseType>(
    url: string,
    config: AxiosRequestConfig<null> | undefined = undefined
  ): Promise<AxiosResponse<ResponseType>> {
    return this.axiosInstance.get<ResponseType, AxiosResponse<ResponseType>, null>(url, config);
  }

  async post<RequestType, ResponseType>(
    url: string,
    data?: RequestType
  ): Promise<AxiosResponse<ResponseType>> {
    return this.axiosInstance.post<ResponseType, AxiosResponse<ResponseType>, RequestType>(
      url,
      data
    );
  }

  async put<RequestType, ResponseType>(
    url: string,
    data?: RequestType
  ): Promise<AxiosResponse<ResponseType>> {
    return this.axiosInstance.put<ResponseType, AxiosResponse<ResponseType>, RequestType>(
      url,
      data
    );
  }

  async delete<ResponseType>(
    url: string,
    config: AxiosRequestConfig<any> | undefined = undefined
  ): Promise<AxiosResponse<ResponseType>> {
    return this.axiosInstance.delete<ResponseType, AxiosResponse<ResponseType>, null>(url, config);
  }

  async patch<RequestType, ResponseType>(
    url: string,
    data?: RequestType
  ): Promise<AxiosResponse<ResponseType>> {
    return this.axiosInstance.patch<ResponseType, AxiosResponse<ResponseType>, RequestType>(
      url,
      data
    );
  }

  async setHeader(header: string, value: string): Promise<void> {
    this.axiosInstance.defaults.headers.common[header] = value;
  }

  private initAxios(): AxiosInstance {
    const { baseURL } = this.config;
    const axiosInstance = axios.create({
      baseURL,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      },
    });
    (async () => {
      // Request interceptor
      axiosInstance.interceptors.request.use(
        (config) => {
          const authState = localStorage.getItem('okta-token-storage')
            ? JSON.parse(localStorage.getItem('okta-token-storage') as string)
            : null;

          if (authState && authState.accessToken && authState.accessToken.accessToken) {
            const { accessToken } = authState;
            config.headers['x-amz-authorization'] = `Bearer ${accessToken.accessToken}`;
          }
          return config;
        },
        (error) => {
          Promise.reject(error);
        }
      );

      // Add a response interceptor
      axiosInstance.interceptors.response.use(
        (response) => {
          return response;
        },
        async function (error) {
          return Promise.reject(error);
        }
      );
    })();

    return axiosInstance;
  }
}

export class Http {
  static axios: AxiosHttp;

  static init() {
    this.axios = new AxiosHttp({
      baseURL: process.env.REACT_APP_API_BASE_URL as string,
    });
  }
}
