import { ColorPalette } from './structure';

export const colorPalette: ColorPalette = {
  basic: {
    black: '#0F1824',
    white: '#FFFFFF',
    blueSurface: '#F2FAFF',
    bgDarkGradient: '#232331',
    overlayLight: 'rgba(0, 0, 0, 0.3)',
    overlayDark: 'rgba(0, 0, 0, 0.7)',
  },
  primary: {
    80: '#163051',
    70: '#2B486E',
    60: '#3E5B7E',
    50: '#4B6D98',
    40: '#7792B5',
    30: '#99B2D2',
    20: '#BBD0EB',
    10: '#DAE7F8',
    5: '#F2F6FC',
    1: '#F8FBFF',
  },
  gold: {
    80: '#4B330F',
    70: '#7D5B29',
    60: '#AC8449',
    50: '#D6AB6B',
    40: '#EAC185',
    30: '#EECB97',
    20: '#F9DCB1',
    10: '#FAE4C6',
    5: '#FEF5E6',
    1: '#FFFAF3',
  },
  turquoise: {
    80: '#1A4947',
    70: '#2C6B68',
    60: '#419894',
    50: '#6DB4AF',
    40: '#91C3C0',
    30: '#A9DCD9',
    20: '#C5EFED',
    10: '#E1F8F6',
    5: '#F1FEFD',
    1: '#F6FFFE',
  },
  green: {
    80: '#1D4936',
    70: '#41715C',
    60: '#4D8F73',
    50: '#60B691',
    40: '#7ED0AD',
    30: '#A1DAC1',
    20: '#BEE8D6',
    10: '#DAF1E7',
    5: '#EDF8F3',
    1: '#F4FDF9',
  },
  red: {
    80: '#59160D',
    70: '#8B1F11',
    60: '#AA3121',
    50: '#CD4532',
    40: '#D26354',
    30: '#EB8778',
    20: '#F6ACA1',
    10: '#FDD9D4',
    5: '#FFEEEC',
    1: '#FFF7F5',
  },

  grey: {
    80: '#2D3239',
    70: '#444A55',
    60: '#626B7A',
    50: '#727C8D',
    40: '#8E96A4',
    30: '#AAB0BB',
    20: '#C6CBD2',
    10: '#E3E5E8',
    5: '#F1F2F4',
    1: '#F9F9FB',
  },
  blueDark: {
    80: '#073154',
    70: '#0B518B',
    60: '#076ABC',
    50: '#068EFE',
    40: '#64B9FF',
    30: '#99D0FE',
    20: '#BDE1FF',
    10: '#D4ECFF',
    5: '#E5F3FF',
    1: '#F4FAFF',
  },
  yellowDark: {
    80: '#4A4511',
    70: '#CCBC00',
    60: '#EEE040',
    50: '#FFEB01',
    40: '#FBF070',
    30: '#FFF799',
    20: '#FFFBCF',
    10: '#FFFCE1',
    5: '#FFFCE1',
    1: '#FFFEF0',
  },
  greenDark: {
    80: '#10380D',
    70: '#297124',
    60: '#449E3E',
    50: '#63D05C',
    40: '#7EEC76',
    30: '#A3F29D',
    20: '#C9F8C5',
    10: '#DDFEDA',
    5: '#E8FFE6',
    1: '#F7FFF6',
  },
  redDark: {
    80: '#500606',
    70: '#A00909',
    60: '#CC1515',
    50: '#F22E2E',
    40: '#FF5959',
    30: '#FF8888',
    20: '#FFAFAF',
    10: '#FFD2D2',
    5: '#FFEAEA',
    1: '#FFF6F6',
  },
  greyDark: {
    80: '#1D1E25',
    70: '#24252D',
    60: '#40404E',
    50: '#5C5C70',
    40: '#9C9EB3',
    30: '#BABCCE',
    20: '#CACCD8',
    10: '#DADBE5',
    5: '#E6E7EE',
    1: '#F6F6FB',
  },
};
