import { Chip, styled, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { ReactComponent as UserIcon } from '../../../../assets/icons/user.svg';
import { FormField } from '../../../../components/FormField/FormField';
import { Typography } from '../../../../components/Typography/Typography';
import { SelectItem, User, UserRole } from '../../../../types';
import { IUserFileds } from './UserModal';

type Props = {
  user?: User;
};

export function RolesSection({ user }: Props) {
  const { colors } = useTheme();
  const { setFieldValue, values, touched, errors, setFieldTouched, resetForm } =
    useFormikContext<IUserFileds>();
  const handleError = (field) => (touched[field] ? errors[field] : '');

  useEffect(() => {
    const dealsValue = (() => {
      let deals;
      switch (values.role) {
        case UserRole.OPERATING_PARTNER:
          deals = user?.operatingPartnerDeals;
          break;
        case UserRole.DEAL_OWNER:
          deals = user?.dealOwnerDeals;
          break;
        default:
          deals = [];
          break;
      }
      return deals?.map((deal) => ({
        id: deal?.id,
        value: deal?.name,
      })) as SelectItem[];
    })();
    const newValues = {
      ...values,
      deals: dealsValue,
    };
    resetForm({ values: newValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.role]);

  const userRoles = Object.keys(UserRole);

  return (
    <FormField
      label={'User Type'}
      error={handleError('role')}
      labelStyle={{
        color: colors.text.caption,
      }}
      style={{
        marginBottom: 8,
      }}
    >
      <Container>
        {userRoles.map((item) => {
          const role = UserRole[item];
          const selectedRole = values?.role === role;
          return (
            <Chip
              key={role}
              onClick={() => {
                setFieldValue('role', role);
                setFieldValue('isOp', role === UserRole.OPERATING_PARTNER);
              }}
              onBlur={() => {
                setFieldTouched('role');
                setFieldValue('isOp', role === UserRole.OPERATING_PARTNER);
              }}
              style={{
                backgroundColor: selectedRole
                  ? colors.surfaceInteraction.pressed
                  : colors.surfaceStatus.noAction,
                height: '28px',
              }}
              label={
                <RoleWrap>
                  {selectedRole && <StyledUserIcon />}
                  <Typography
                    variant='body'
                    color={selectedRole ? colors.text.main : colors.textAccent.default}
                  >
                    {role}
                  </Typography>
                </RoleWrap>
              }
            />
          );
        })}
      </Container>
    </FormField>
  );
}
const Container = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const RoleWrap = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4;
`;

const StyledUserIcon = styled(UserIcon)`
  path {
    fill: ${({ theme }) => theme.colors.textAccent.inverse};
  }
`;
