import { Box, Chip, useTheme } from '@mui/material';
import { useAtomValue } from 'jotai';
import { AvatarCell } from '../../../components/Table/CellRenderers/AvatarCell';
import { Typography } from '../../../components/Typography/Typography';
import { userState } from '../../../state/UIState';
import { MonthlyTimesheet } from '../../../types';
import { getMonthStatus } from '../../../utils/formatters';
import { getIsPendingDealOwnerStatus } from '../../../utils/getIsPendingDealOwnerTimesheetStatus';

type Props = {
  timesheet: MonthlyTimesheet;
};

export function OperatingPartnerCell({ timesheet }: Props) {
  const { colors } = useTheme();
  const user = useAtomValue(userState);

  const month = timesheet.month;
  const year = timesheet.year;
  const statusValue = getMonthStatus(new Date(year, month - 1, 1));
  const isNewWeek = statusValue === 'New';

  const statusColor = isNewWeek ? colors.textStatus.active : colors.textStatus.warning;

  const isPendingStatus = getIsPendingDealOwnerStatus(timesheet, user);

  return (
    <Box
      maxHeight={48}
      minHeight={48}
      display={'flex'}
      alignItems={'center'}
      paddingLeft={'16px'}
      gap={'8px'}
    >
      <AvatarCell
        avatarLetterStyle={{
          color: colors.textAccent.inverse,
        }}
        name={timesheet.user?.name || ''}
      />
      {isPendingStatus && (
        <Chip
          style={{
            backgroundColor: colors.surfaceStatus.noAction,
            minWidth: 'auto',
            height: '28px',
          }}
          label={
            <Typography variant='body' color={statusColor}>
              {statusValue}
            </Typography>
          }
        />
      )}
    </Box>
  );
}
