import { IconButton, styled, useTheme } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-grey.svg';

import { Typography } from '../Typography/Typography';

type Props = {
  title: string;
  onClose: () => void;
};

export const ModalHeader = ({ title, onClose }: Props) => {
  const { colors } = useTheme();
  return (
    <Container>
      <TitleWrapper>
        <Typography variant='h4' color={colors.text.main}>
          {title}
        </Typography>
      </TitleWrapper>
      <IconButton onClick={onClose} style={{ padding: '0px' }}>
        <CloseIcon />
      </IconButton>
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.surfaceBackground.bg1};

  position: sticky;
  top: 0;
  z-index: 1;
`;

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;
