import { useMutation } from 'react-query';
import { Http } from '../../services/Http';
import { TimesheetCell } from '../../types';
import { MUTATION_KEYS } from '../constants';
import { useInvalidateSingleTimesheet } from './useSingleTimesheet';

type Props = {
  timesheetId: number;
};

async function clearTimesheetTable({ timesheetId }: Props): Promise<TimesheetCell> {
  const { data } = await Http.axios.patch<null, TimesheetCell>(
    `/timesheet/${timesheetId}/clear/all`
  );
  return data;
}

export const useClearTimesheetTable = () => {
  const invalidateSingleTimesheet = useInvalidateSingleTimesheet();
  return useMutation(clearTimesheetTable, {
    mutationKey: MUTATION_KEYS.CLEAR_TIMESHEET_TABLE,
    onSuccess: () => {
      invalidateSingleTimesheet();
    },
  });
};
