import { Box, Button, Chip, styled, useTheme } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { Table } from '@tanstack/table-core';
import { useParams } from 'react-router';
import { TableSkeletonLoader } from '../../../../components/SkeletonLoader/Table.SkeletonLoader';
import { AvatarCell } from '../../../../components/Table/CellRenderers/AvatarCell';
import { Typography } from '../../../../components/Typography/Typography';
import { useTimesheetStatusColor } from '../../../../hooks/useTimesheetStatusColor';
import { useSingleTimesheet } from '../../../../queries/hooks/useSingleTimesheet';
import {
  LoadingId,
  TimesheetRow,
  TimesheetStatus,
  TimesheetStatusLabel,
  UserRole,
} from '../../../../types';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check-icon-default.svg';
import { useLoadingBar } from '../../../../hooks/useLoadingBar';
import { useToastMessage } from '../../../../hooks/useToastMessage';
import { useClearTimesheetTable } from '../../../../queries/hooks/useClearTimesheetTable';
import { SubmitTimesheetModal } from '../SubmitTimesheetModal';
import { TimesheetNavigation } from './TimesheetNavigation';
import { userState } from '../../../../state/UIState';
import { useAtomValue } from 'jotai';
import { SINGLE_TS_MOCK_DATA } from '../../../../components/Onboarding/OnboardingMockData';
import { getOpTsStatusLabel } from '../../../../utils/tsMappers';
import { loadingClearColumn } from '../../../../state/UITimesheetsState';

type Props = {
  table: Table<TimesheetRow>;
  data: TimesheetRow[];
  updateOnboardingData: boolean;
};

export function SingleTimesheetHeader({ table, data, updateOnboardingData }: Props) {
  const user = useAtomValue(userState);
  const { colors } = useTheme();
  const { id } = useParams();
  const isOnboarding = id === 'demo';
  const { pushErrorToast, pushSuccessToast } = useToastMessage();
  const { startLoading, stopLoading } = useLoadingBar();
  const [submitTimesheetOpen, setSubmitTimesheetOpen] = useState(false);
  const isLoadingClearColumn = useAtomValue(loadingClearColumn);

  const { data: singleTimesheet, isLoading: dataIsLoading } = useSingleTimesheet({
    id: Number(id),
    enabled: !isOnboarding,
  });

  const expectedSum = useMemo(() => {
    if (!singleTimesheet) return 0;
    return (singleTimesheet?.weekDates.length + singleTimesheet?.holidays.length) * 100;
  }, [singleTimesheet]);

  const tsStatusLabel = useMemo(() => {
    if (!singleTimesheet) return;
    return getOpTsStatusLabel(singleTimesheet?.status);
  }, [singleTimesheet]);

  const { mutate: onClearTable, isLoading: loadingClearTable } = useClearTimesheetTable();

  const toggleSubmitTsOpen = useCallback(() => {
    setSubmitTimesheetOpen((prev) => !prev);
  }, []);

  const isSubmitDisabled = useMemo(() => {
    if (updateOnboardingData) return false;
    else if (loadingClearTable) return true;
    else if (isLoadingClearColumn) return true;
    const accessors = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
    const cellValues: number[] = table
      .getFilteredRowModel()
      .rows.flatMap((row) => accessors.flatMap((day) => row.getValue(day)));
    const columnTotals: number[] = [];
    accessors.forEach((accessor) => {
      const dayTotal = table
        .getFilteredRowModel()
        .rows.reduce((total, row) => total + Number(row.getValue(accessor)), 0);
      columnTotals.push(dayTotal);
    });
    if (columnTotals.some((total) => total > 100)) {
      return true;
    }
    if (cellValues.some((cell) => cell > 100)) {
      return true;
    }
    let sum = 0;
    accessors.forEach((accessor) => {
      const total = table.getFilteredRowModel().rows.reduce((total, row) => {
        return total + Number(row.getValue(accessor));
      }, 0);
      sum += total;
    });
    return sum !== expectedSum;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, data, loadingClearTable, isOnboarding, isLoadingClearColumn]);

  const statusColor = useTimesheetStatusColor(
    isOnboarding ? (SINGLE_TS_MOCK_DATA.status as TimesheetStatusLabel) : tsStatusLabel
  );

  const isSubmitHidden = useMemo(() => {
    if (isOnboarding) return false;
    return (
      (singleTimesheet?.status !== TimesheetStatus.ISSUED &&
        singleTimesheet?.status !== TimesheetStatus.REJECTED) ||
      user?.role === UserRole.PROXY
    );
  }, [isOnboarding, singleTimesheet?.status, user?.role]);

  const disableClear = useMemo(() => {
    if (isOnboarding) return false;
    return (
      (singleTimesheet?.status !== TimesheetStatus.ISSUED &&
        singleTimesheet?.status !== TimesheetStatus.REJECTED) ||
      dataIsLoading
    );
  }, [isOnboarding, dataIsLoading, singleTimesheet?.status]);

  if (!isOnboarding && dataIsLoading) return <TableSkeletonLoader />;

  return (
    <Container>
      <UserInfoWrap>
        <AvatarCell
          name={(isOnboarding ? SINGLE_TS_MOCK_DATA : singleTimesheet)?.user?.name || ''}
          avatarBoxStyle={{
            width: 34,
            height: 34,
            minWidth: 34,
            maxWidth: 34,
            background: colors.surfaceIndicator.needsReview,
          }}
          avatarLetterStyle={{
            color: colors.text.main,
            fontSize: 14,
          }}
        />
        <Box display={'flex'} gap={'8px'} flex={1}>
          <Chip
            style={{
              backgroundColor: colors.surfaceStatus.noAction,
              minWidth: '66px',
              height: '28px',
            }}
            label={
              <Typography variant='body' color={statusColor}>
                {isOnboarding ? SINGLE_TS_MOCK_DATA.status : tsStatusLabel}
              </Typography>
            }
          />
        </Box>
      </UserInfoWrap>
      <TimesheetNavigation
        OPId={singleTimesheet?.user?.id}
        timesheet={singleTimesheet}
        isOnboarding={isOnboarding}
      />
      <ActionBtnWrap>
        {!disableClear && (
          <Button
            variant='text'
            sx={{
              paddingRight: 0,
            }}
            onClick={() => {
              startLoading(LoadingId.clearTable);
              onClearTable(
                {
                  timesheetId: Number(id),
                },

                {
                  onSuccess: () => {
                    pushSuccessToast({
                      title: 'Timesheet is Cleared',
                      message: `The timesheet has been cleared successfully.`,
                    });
                  },
                  onError: () => {
                    pushErrorToast({ message: 'Failed to clear timesheet' });
                  },
                  onSettled: () => {
                    stopLoading(LoadingId.clearTable);
                  },
                }
              );
            }}
          >
            <Typography color={colors.textAccent.default} variant='subtitle1'>
              Clear All Entries
            </Typography>
          </Button>
        )}
        {!isSubmitHidden && (
          <Button
            startIcon={<StyledCheckIcon disabled={isSubmitDisabled} />}
            variant='contained'
            disabled={isSubmitDisabled}
            onClick={() => {
              toggleSubmitTsOpen();
            }}
            className='btn-submit'
          >
            Submit
          </Button>
        )}
      </ActionBtnWrap>
      {submitTimesheetOpen && (
        <SubmitTimesheetModal
          timesheet={isOnboarding ? SINGLE_TS_MOCK_DATA : singleTimesheet}
          submitTsOpen={submitTimesheetOpen}
          toggleSubmitTimesheet={toggleSubmitTsOpen}
          isSubmitDisabled={isSubmitDisabled}
        />
      )}
    </Container>
  );
}

const Container = styled('div')`
  display: flex;
  padding-bottom: 28px;
  justify-content: center;
  padding-bottom: 28px;
`;

const UserInfoWrap = styled('div')`
  display: flex;
  align-items: center;
  width: 35%;
  gap: 20px;
`;

const ActionBtnWrap = styled('div')`
  display: flex;
  justify-content: flex-end;
  gap: 25px;
  align-items: center;
  width: 35%;
`;

const StyledCheckIcon = styled(CheckIcon)<{ disabled: boolean }>`
  width: 20px;
  height: 20px;
  path {
    fill: ${({ disabled, theme }) =>
      disabled ? theme.colors.icon.disabled : theme.colors.icon.inverse};
  }
`;
