import { useAtomValue } from 'jotai';
import { ROUTES } from '../../constants/routes';
import { Unauthorized } from '../../pages/Unauthorized/Unauthorized';
import { userState } from '../../state/UIState';
import { canSeeConfig } from '../../constants/constants';
import { useMemo } from 'react';
import { UserRole } from '../../types';

type Props = {
  component: JSX.Element;
  path: ROUTES;
};

export const GuardedRoute = ({ component: Component, path }: Props) => {
  const user = useAtomValue(userState);

  const config = useMemo(() => {
    if (user?.role === UserRole.OP_MANAGER && user?.isOp) {
      return user?.isAdmin
        ? canSeeConfig[UserRole.OP_MANAGER].OPAdminPages.includes(path)
        : canSeeConfig[UserRole.OP_MANAGER].OPPages.includes(path);
    }
    return !user?.isAdmin
      ? canSeeConfig[user?.role as keyof typeof canSeeConfig].pages.includes(path)
      : canSeeConfig[user?.role as keyof typeof canSeeConfig].adminPages.includes(path);
  }, [user?.role, user?.isOp, user?.isAdmin, path]);

  return config ? Component : <Unauthorized />;
};
