import { CSSProperties, ReactNode } from 'react';

export interface ColumnMeta {
  width: string;
  minWidth?: string;
  maxWidth?: string;
  alignHeader?: 'left' | 'right';
  hideHeader?: boolean;
  rightBorder?: string;
  headerWrapperWidth?: string;
  tdStyles?: CSSProperties;
  thStyles?: CSSProperties;
  headerWrapperStyles?: CSSProperties;
}

export interface ComponentWithChildren {
  children: ReactNode;
}

export type SelectItem = {
  id: number | string;
  value: string;
  icon?: JSX.Element;
  secondaryId?: number | string;
  tertiaryId?: number | string;
  isHighlighted?: boolean;
};

export enum LoadingId {
  assignDealsToUser = 'assignDealsToUser',
  addDeal = 'addDeal',
  addHoliday = 'addHoliday',
  assignProxyToUser = 'assignProxyToUser',
  addUser = 'addUser',
  editUser = 'editUser',
  deleteUser = 'deleteUser',
  deleteDeal = 'deleteDeal',
  updateCell = 'updateCell',
  clearColumn = 'clearColumn',
  clearTable = 'clearTable',
  approveTimesheet = 'approveTimesheet',
  sendBackTimesheet = 'sendBackTimesheet',
  bulkApproveTimesheet = 'bulkApproveTimesheet',
  bulkSendBackTimesheet = 'bulkSendBackTimesheet',
}

export interface ToastProps {
  title?: string;
  message: string;
  action?: () => void;
  actionLabel?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
}

export enum ToastMessageType {
  info = 'info',
  warning = 'warning',
  error = 'error',
  success = 'success',
}

export interface ExtendedToastProps extends ToastProps {
  type: ToastMessageType;
}

export interface MenuItem {
  route: string;
  id: string;
  title: string;
  show: boolean;
}

export interface MainMenuItemProps extends MenuItem {
  icon: ReactNode;
  children: MenuItem[];
}

export enum THEME {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface Breadcrumb {
  title: string;
  url?: string;
  color?: string;
}

export interface User {
  id: number;
  created_at?: string;
  updated_at?: string;
  name: string;
  email: string;
  role?: UserRole;
  isAdmin: boolean;
  isOp: boolean;
  isSelfApprovingEnabled: boolean;
  operatingPartnerDeals?: UserDeal[];
  dealOwnerDeals?: UserDeal[];
  operatingPartners?: Partner[];
  operatingPartnerManagers?: Partner[];
  proxyOperatingPartners?: Partner[];
  proxy?: User;
  monthlyTimesheets?: MonthlyTimesheet[];
  endDate?: string;
  startDate?: string;
  country?: USER_COUNTRY;
}

export type UserWithTsPeriodData = { name: string; id: number } & Record<string, MonthlyTimesheet>;

export enum UserRole {
  OPERATING_PARTNER = 'Operating Partner',
  PROXY = 'Proxy',
  OP_MANAGER = 'OP Manager',
  DEAL_OWNER = 'Deal Owner',
  ACCOUNTANT = 'Accountant',
}

export interface Partner {
  id: number;
  created_at: Date;
  updated_at: Date;
  name: string;
  email: string;
  role: UserRole;
}

export interface UserDeal {
  created_at?: string;
  updated_at?: string;
  name: string;
  id: number;
}
export interface Deal {
  id: number;
  name: string;
  dealOwners?: Partner[];
  operatingPartners?: Partner[];
  created_at?: string;
  updated_at?: string;
}

export interface Issuer {
  id: number;
  name: string;
}

export interface ActionButtonsProps {
  onCancel: () => void;
  onSubmit: () => void;
  isSubmitDisabled?: boolean;
  cancelText?: string;
  submitText?: string;
  cancelBtnStyle?: React.CSSProperties;
  submitBtnStyle?: React.CSSProperties;
}

export interface AddUserPayload {
  name: string;
  email: string;
  role: string;
  isAdmin: boolean;
  isOp?: boolean; 
  isSelfApprovingEnabled?: boolean;
  operatingPartnerManagers?: number[];
  proxy?: number;
  operatingPartnerDeals?: number[];
  proxyOperatingPartners?: number[];
  operatingPartners?: number[];
  dealOwnerDeals?: number[];
  startDate?: string;
  endDate?: string;
  country?: USER_COUNTRY;
}

export interface EditUserPayload {
  role: string;
  isAdmin: boolean;
  isOp?: boolean;
  isSelfApprovingEnabled?: boolean;
  operatingPartnerManagers?: number[];
  proxy?: number;
  operatingPartnerDeals?: number[];
  proxyOperatingPartners?: number[];
  operatingPartners?: number[];
  dealOwnerDeals?: number[];
  startDate?: string;
  endDate?: string;
  country?: USER_COUNTRY;
}

export enum TimesheetStatus {
  REJECTED = '0',
  ISSUED = '1',
  PENDING_OP_MANAGER = '2',
  PENDING_DEAL_OWNERS = '3',
  PENDING_ACCOUNTANT = '4',
  CLOSED = '5',
}

export enum TimesheetStatusLabel {
  REJECTED = 'Rejected',
  ISSUED = 'Issued',
  SUBMITTED = 'Submitted',
  PENDING = 'Pending',
  CLOSED = 'Closed',
  PENDING_OP_MANAGER = 'Pending OP Manager',
  PENDING_DEAL_OWNERS = 'Pending Deal Owner',
}

export enum TimesheetLogType {
  REJECT = 'Reject',
}
export interface Timesheet {
  id: number;
  status: TimesheetStatus | TimesheetStatusLabel;
  created_at?: string;
  updated_at?: string;
  updatedBy?: User;
  weekStart: string;
  weekDates: string[];
  timesheetRows: TimesheetRow[];
  user?: User;
  holidays: Holiday[];
}

type MonthlyTSDataObject = {
  [key: string]: string;
};

export interface MonthlyTimesheetRow {
  id: number;
  created_at: string;
  updated_at: string;
  dealId: number;
  name: string;
  data: MonthlyTSDataObject;
  avg: number;
  dealOwners?: User[];
  isApprovedByDealOwner?: boolean;
}
export interface WeeklyTimesheet {
  id: number;
  created_at: string;
  updated_at: string;
  weekStart: string;
  weekDates: string[];
  status: TimesheetStatus;
  timesheetRows: TimesheetRow[];
  timesheetLogs: TimesheetLog[];
  user: User;
  holidays: Holiday[];
}

export interface MonthlyTimesheet {
  id: number;
  created_at?: string;
  updated_at?: string;
  month: number;
  year: number;
  status: TimesheetStatus | TimesheetStatusLabel;
  data?: MonthlyTSDataObject;
  weeklyTimesheetIds?: number[];
  weeklyTimesheets: WeeklyTimesheet[];
  avgWork: number;
  avgNotWork: number;
  rejectNote?: null | string;
  timesheetRows: MonthlyTimesheetRow[];
  user?: User;
  approvedByDealOwners?: Partner[];
  approvedByOpManagers?: User[];
  updatedBy?: User;
}

export interface MonthlyTimesheetToExport {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  email: string;
  role: UserRole;
  timesheets: MonthlyTimesheet[];
}

export interface AvailableMonthlyTimesheet {
  year: string;
  months: string[];
}

export interface TimesheetRow {
  id: number;
  created_at: string;
  updated_at: string;
  dealId: number;
  name: string;
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  editDisabled?: boolean;
}

export interface TimesheetLog {
  id: number;
  created_at: string;
  updated_at: string;
  createdBy: User;
  note: string;
  type: TimesheetLogType;
}
export interface CreateUserPayload {
  name: string;
  email: string;
  role?: UserRole;
}

export interface TimesheetCell {
  id: number;
  monday: string | null;
  dealId: number | null;
  name: string | null;
  tuesday: string | null;
  wednesday: string | null;
  thursday: string | null;
  friday: string | null;
  updated_at: string;
}

export type WeekDay = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday';

export enum AccountantTSView {
  month = 'month',
  weekday = 'weekday',
  week = 'week',
}

export interface WeekdayTimesheetTableData {
  id: number;
  dealId: number;
  name: string;
  avg: number;
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
}

export interface Holiday {
  id: number;
  country: string;
  frequency: string;
  isMultiday: boolean;
  name: string;
  type: string;
  startDate: string;
  created_at?: string;
  day: number;
  endDate: string | null;
  month: number;
  multidayId: string;
  updated_at: string;
  year: number;
}

export enum HOLIDAY_FREQUENCY {
  ONE_TIME_EVENT = 'One Time Event',
  YEARLY = 'Yearly',
}

export enum USER_COUNTRY {
  US = 'US',
  UK = 'UK',
}

export enum HOLIDAY_TYPE {
  BANK_HOLIDAY = 'Bank Holiday',
  DUVET_DAY = 'Duvet Day',
  OFFSITE_EVENT = 'Offsite Event',
  COMPANY_EVENT = 'Company Event',
  GENERAL_RESTRUCTURING = 'General Restructuring',
  OTHER = 'Other',
}

export const HolidayTypeItems: SelectItem[] = [
  {
    id: 1,
    value: HOLIDAY_TYPE.BANK_HOLIDAY,
  },
  {
    id: 2,
    value: HOLIDAY_TYPE.DUVET_DAY,
  },
  {
    id: 3,
    value: HOLIDAY_TYPE.OFFSITE_EVENT,
  },
  {
    id: 4,
    value: HOLIDAY_TYPE.COMPANY_EVENT,
  },
  {
    id: 5,
    value: HOLIDAY_TYPE.GENERAL_RESTRUCTURING,
  },
  {
    id: 6,
    value: HOLIDAY_TYPE.OTHER,
  },
];

export const HolidayFrequencyItems: SelectItem[] = [
  {
    id: 1,
    value: HOLIDAY_FREQUENCY.ONE_TIME_EVENT,
  },
  {
    id: 2,
    value: HOLIDAY_FREQUENCY.YEARLY,
  },
];

export enum HolidayTab {
  Calendar,
  List,
}
