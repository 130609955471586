import { useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { Timesheet, UserRole } from '../../../types';
import { getMonthLabelShort } from '../../../utils/formatters';
import { useMemo } from 'react';

type Props = {
  updatedBy: Timesheet['updatedBy'];
  updated_at: Timesheet['updated_at'];
};
export function LastEditedCell({ updatedBy, updated_at }: Props) {
  const { colors } = useTheme();

  const proxyUserRole = updatedBy?.role === UserRole.PROXY;

  const formatedUpdatedAt = useMemo(() => {
    if (updated_at) {
      const date = updated_at?.split('T')[0].split('-').reverse();
      return `${Number(date[0]) > 10 ? date[0] : `0${date[0]}`} ${getMonthLabelShort(
        Number(date[1])
      )} ${date[2]}`;
    }
    return '';
  }, [updated_at]);
  
  return (
    <Typography variant='body' color={colors.text.main}>
      {formatedUpdatedAt}

      {proxyUserRole ? ` by ${updatedBy?.name}` : null}
    </Typography>
  );
}
