import { Button, styled, useTheme } from '@mui/material';
import { Table } from '@tanstack/react-table';
import { Typography } from '../../../components/Typography/Typography';
import { Holiday } from '../../../types';

interface Props {
  table: Table<Holiday>;
  handleBulkHolidayModal: () => void;
}

export const HolidayBulkActions = ({ table, handleBulkHolidayModal }: Props) => {
  const { colors } = useTheme();
  const selectedHolidays = table.getSelectedRowModel().rows.map((row) => row.original);

  return (
    <Container>
      <Typography variant='body' color={colors.text.main}>
        {`${selectedHolidays.length} Event/s selected`}
      </Typography>
      <ActionBtnsWrap>
        <Button
          variant='contained'
          onClick={handleBulkHolidayModal}
          style={{
            backgroundColor: colors.surfaceAccent.default,
            color: colors.textAccent.inverse,
          }}
        >
          Remove Events
        </Button>
        <Button
          variant='text'
          onClick={() => {
            table.resetRowSelection();
          }}
          style={{
            backgroundColor: 'transparent',
            color: colors.text.main,
          }}
        >
          Clear Selection
        </Button>
      </ActionBtnsWrap>
    </Container>
  );
};

const Container = styled('div')`
  background: ${({ theme }) => theme.colors.surfaceInteraction.selected};
  border-radius: 4px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
`;

const ActionBtnsWrap = styled('div')`
  background: ${({ theme }) => theme.colors.surfaceInteraction.selected};
  border-radius: 4px;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
`;
