import { styled, useTheme } from '@mui/material';
import { useLocation, useParams } from 'react-router';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { useSingleTimesheet } from '../../queries/hooks/useSingleTimesheet';
import { formatWeekName } from '../../utils/formatters';
import { ContentWrapper } from '../layout/styled';
import { SingleTimesheetTable } from './components/SingleTimesheetTable/SingleTimesheetTable';
import { ROUTES } from '../../constants/routes';
import { useMemo } from 'react';
import { getWeeklyTimesheetWeekStart } from '../../utils/getWeeklyTimesheetWeekStart';

function SingleTimesheet() {
  const { id } = useParams();
  const location = useLocation();

  const isAllTimesheets = location.pathname.includes(ROUTES.ALL_TIMESHEETS);
  const isIssuedTimesheets = location.pathname.includes(ROUTES.ISSUED_TIMESHEETS);
  const { colors } = useTheme();
  const { data: singleTimesheet } = useSingleTimesheet({
    id: Number(id),
    enabled: id !== 'demo',
  });

  const weekStart = useMemo(() => {
    if (!singleTimesheet) return '';
    return getWeeklyTimesheetWeekStart(singleTimesheet);
  }, [singleTimesheet]);

  const weekLength = useMemo(() => {
    if (!singleTimesheet) return 0;
    return singleTimesheet.weekDates.length + singleTimesheet.holidays.length;
  }, [singleTimesheet]);

  const week = formatWeekName(weekStart, weekLength);

  const breadcrumbTitle = isAllTimesheets
    ? 'All Timesheets'
    : isIssuedTimesheets
    ? 'Issued Timesheets'
    : 'Pending Timesheets';

  const breadcrumbUrl = isAllTimesheets
    ? `/${ROUTES.ALL_TIMESHEETS}`
    : isIssuedTimesheets
    ? `/${ROUTES.ISSUED_TIMESHEETS}`
    : `/${ROUTES.PENDING_TIMESHEETS}`;
  return (
    <>
      <Wrapper>
        <Breadcrumbs
          breadcrumbs={[
            ...(!isAllTimesheets
              ? [
                  {
                    title: 'All Timesheets',
                    color: colors.textAccent.default,
                    url: `/${ROUTES.ALL_TIMESHEETS}`,
                  },
                ]
              : []),
            { title: breadcrumbTitle, url: breadcrumbUrl, color: colors.textAccent.default },
            { title: week || '' },
          ]}
        />
      </Wrapper>
      <ContentWrapper>
        <SingleTimesheetTable />
      </ContentWrapper>
    </>
  );
}

export default SingleTimesheet;
const Wrapper = styled('div')`
  padding: 28px 36px 0;
`;
