import { useTheme } from '@mui/material';
import { useMemo } from 'react';

import { createColumnHelper } from '@tanstack/react-table';

import { AvatarCell } from '../../../components/Table/CellRenderers/AvatarCell';
import { TableHeaderCell } from '../../../components/Table/CellRenderers/TableHeaderCell';
import { Typography } from '../../../components/Typography/Typography';
import { MonthlyTimesheetRow } from '../../../types';
import TotalCell from '../components/TotalCell';
import { useAtomValue } from 'jotai';
import { userState } from '../../../state/UIState';

const columnHelper = createColumnHelper<MonthlyTimesheetRow>();

export const useDealOwnersSingleTableColumns = () => {
  const { colors } = useTheme();
  const user = useAtomValue(userState);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => {
          const timesheet = info.row.original;
          const isFixedDeal = timesheet.dealId === 0;
          const highlighted = user?.dealOwnerDeals?.find((deal) => deal.id === timesheet.dealId);

          return (
            <AvatarCell
              name={info.row.original.name}
              avatarBoxStyle={{
                background: isFixedDeal
                  ? colors.surfaceBackground.highlighted
                  : colors.surfaceIndicator.needsReview,
              }}
              avatarNameStyle={{
                color: highlighted ? colors.text.main : colors.text.caption,
              }}
              avatarLetterStyle={{
                color: colors.text.main,
              }}
            />
          );
        },
        enableSorting: false,

        header: () => <TableHeaderCell text='Deal' />,
        footer: () => {
          return (
            <Typography
              variant='body'
              color={colors.text.main}
              style={{
                paddingLeft: '30px',
                fontSize: '17px',
              }}
            >
              Total per Month
            </Typography>
          );
        },
        meta: {
          tdStyles: { paddingLeft: '16px', borderRight: `none` },
          thStyles: { borderRight: `none` },
        },
      }),
      columnHelper.accessor('avg', {
        cell: (info) => {
          const timesheet = info.row.original;
          const totalPerDeal = timesheet.avg?.toFixed() ?? 0;

          const highlighted = user?.dealOwnerDeals?.find((deal) => deal.id === timesheet.dealId);
          return (
            <Typography
              variant='body'
              color={colors.text.main}
              style={{
                textWrap: 'wrap',
                color: highlighted ? colors.text.main : colors.text.caption,
              }}
            >
              {`${totalPerDeal}%`}
            </Typography>
          );
        },
        header: () => <TableHeaderCell text='Total per deal' />,
        footer: TotalCell,

        enableSorting: false,

        meta: {
          width: '20%',
          maxWidth: '20%',
          minWidth: '20%',
          tdStyles: { paddingLeft: '16px', paddingRight: '16px', borderLeft: `none` },
          thStyles: { borderRight: `none`, paddingRight: '16px', borderLeft: `none` },
        },
      }),
    ],
    [
      colors.surfaceBackground.highlighted,
      colors.surfaceIndicator.needsReview,
      colors.text.caption,
      colors.text.main,
      user?.dealOwnerDeals,
    ]
  );

  return columns;
};
