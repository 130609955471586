import { styled } from '@mui/material';

interface Props {
  name: string;
  size?: 'medium' | 'large';
  style?: React.CSSProperties;
  lettersStyle?: React.CSSProperties;
}

const getInitials = (name: string) => {
  const names = name?.split(' ');
  if (names?.length) {
    const initials = names.length > 1 ? `${names[0][0]}${names[names.length - 1][0]}` : names[0][0];
    return initials ? initials.toUpperCase() : '';
  }
};

const Wrapper = styled('div')<{ size: 'medium' | 'large' }>`
  background-color: ${({ theme }) => theme.colors.textAccent.default};
  border-radius: 50%;
  width: ${({ size }) => (size === 'medium' ? '24px' : '40px')};
  height: ${({ size }) => (size === 'medium' ? '24px' : '40px')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const Letters = styled('p')<{ size: 'medium' | 'large' }>`
  color: ${({ theme }) => theme.colors.surfaceBackground.bg1};
  border-radius: 50%;
  line-height: 27px;
  font-size: ${({ size }) => (size === 'medium' ? '10px' : '18px')};
  font-family: ${({ size }) =>
    size === 'medium' ? 'BasierSquare-Regular' : 'BasierSquare-Medium'};
  font-weight: ${({ size }) => (size === 'medium' ? '400' : '500')};
`;

export const UserAvatar = ({ name, size = 'large', style, lettersStyle }: Props) => {
  return (
    <Wrapper size={size} style={{ ...style }}>
      <Letters size={size} style={{ ...lettersStyle }}>
        {getInitials(name)}
      </Letters>
    </Wrapper>
  );
};
