import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { isUserLoadingState, userState } from '../state/UIState';
import { useOktaAuth } from '@okta/okta-react';
import { fetchUserByEmail, useCreateUser } from '../queries/hooks/useUsers';

const useInitUser = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const setIsLoadingUser = useSetAtom(isUserLoadingState);
  const setUserState = useSetAtom(userState);
  const createUser = useCreateUser();

  const login = async () => oktaAuth.signInWithRedirect();

  useEffect(() => {
    if (!authState?.idToken?.claims?.email) return;

    const initUser = async () => {
      const email = authState?.idToken?.claims?.email?.toLowerCase();
      const user = await fetchUserByEmail((email as string) ?? '');
      if (user) {
        setUserState(user);
        setIsLoadingUser(false);
      }
      if (!user) {
        const newUser = await createUser.mutateAsync({
          name: authState?.idToken?.claims?.name ?? '',
          email: email ?? '',
        });
        setUserState(newUser);

        setIsLoadingUser(false);
      }
    };

    initUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.idToken?.claims?.email]);

  if (!authState) {
    return null;
  }

  if (!authState.isAuthenticated) {
    login();
  }
};

export default useInitUser;
