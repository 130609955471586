import { styled } from '@mui/material';

export const ContentWrapper = styled('div')`
  padding: 12px 36px 28px 36px;
`;

export const HeaderActionWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;
