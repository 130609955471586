import { useTheme } from '@mui/material';
import { useState } from 'react';
import { STATUS, Step, ACTIONS, CallBackProps } from 'react-joyride';
import { useNavigate, useParams } from 'react-router';
import OnboardingTooltip from '../../../../components/Onboarding/OnboardingTooltip';
import { useToastMessage } from '../../../../hooks/useToastMessage';

interface OnboardingSingleTimesheetProps {
  onUpdateOnboardingData: () => void;
}

function OnboardingSingleTimesheet({ onUpdateOnboardingData }: OnboardingSingleTimesheetProps) {
  const { colors } = useTheme();
  const { id } = useParams();
  const [run, setRun] = useState(id === 'demo');
  const navigate = useNavigate();
  const { pushSuccessToast } = useToastMessage();

  const steps: Step[] = [
    {
      target: '.single-timesheet',
      title: 'Fill out the Timesheet',
      content:
        'Enter the percentage of your daily work hours spent on each deal. The daily total must add up to 100%',
      placement: 'bottom',
      styles: {
        tooltip: {
          bottom: '26px',
          width: '320px',
        },
      },
    },
    {
      target: '.btn-submit',
      title: 'Submit Timesheet',
      content:
        'When all days add up to 100%, the submit button will become available for you to submit the dimesheet',
      placement: 'auto',
      locale: {
        last: 'Finish',
      },
      styles: {
        tooltip: {
          width: '320px',
        },
        options: {
          backgroundColor: colors.surfaceBackground.bg1,
          textColor: colors.text.main,
          arrowColor: colors.surfaceBackground.bg1,
        },
      },
    },
  ];

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, action, index } = data;
    if (status === STATUS.SKIPPED || action === ACTIONS.CLOSE) {
      setRun(false);
      window.localStorage.setItem('hasCompletedOnboarding', 'true');
      navigate('/pending-timesheets');
    } else if (status === STATUS.FINISHED) {
      setRun(false);
      window.localStorage.setItem('hasCompletedOnboarding', 'true');
      navigate('/pending-timesheets');
      pushSuccessToast({
        title: 'Congratulations! You have submitted your first timesheet',
        message:
          'Continue tracking and managing your work hours on various deals in Issues Timesheets page. You can find this guide in the Help Center located in the sidebar.',
      });
    } else if (index === 1) {
      onUpdateOnboardingData();
    }
  };

  return (
    <OnboardingTooltip run={run} steps={steps} handleJoyrideCallback={handleJoyrideCallback} />
  );
}

export default OnboardingSingleTimesheet;
