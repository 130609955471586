import { Box, Button, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ROUTES } from '../../../constants/routes';
import { MonthlyTimesheet, TimesheetStatus, TimesheetStatusLabel } from '../../../types';
import { getAccountantTsStatusLabel } from '../../../utils/tsMappers';
import { ProgressBar } from '../../../components/ProgressBar/ProgressBar';
import { PendingStatusTooltip } from './PendingStatusTooltip';
import { useAllUserOperatingPartners } from '../../../queries/hooks/useAllUserOperatingPartners';

type Props = {
  timesheet?: MonthlyTimesheet;
};

export function AccountantStatusCell({ timesheet }: Props) {
  const { colors } = useTheme();
  const navigate = useNavigate();

  const statusLabel = useMemo(() => {
    return getAccountantTsStatusLabel(timesheet?.status as TimesheetStatus);
  }, [timesheet?.status]);

  const isTSDisabled =
    statusLabel === TimesheetStatusLabel.ISSUED || statusLabel === TimesheetStatusLabel.REJECTED;

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const yearQueryParam = params.get('year');

  const { data: operatingPartners } = useAllUserOperatingPartners(yearQueryParam || '');

  const operatingPartner = useMemo(() => {
    if (operatingPartners) {
      return operatingPartners.find((op) =>
        op.monthlyTimesheets?.some((monthlyTs) => monthlyTs.id === timesheet?.id)
      );
    }
  }, [operatingPartners, timesheet?.id]);

  const redirectToSingleTs = () => {
    const queryParam = yearQueryParam ? `?year=${yearQueryParam}` : '';
    navigate(`/${ROUTES.ACCOUNTANT_TIMESHEETS_STATUSES}/${timesheet?.id}${queryParam}`);
  };

  const backgroundStyle = useMemo(() => {
    if (statusLabel === TimesheetStatusLabel.SUBMITTED) return colors.surfaceAccent.default;
    else if (isTSDisabled) return colors.surfaceInteraction.selectedLight;
    else if (timesheet?.status === TimesheetStatus.CLOSED) return 'transparent';
    return colors.surfaceStatus.active;
  }, [timesheet, colors, statusLabel, isTSDisabled]);

  const colorStyle = useMemo(() => {
    if (statusLabel === TimesheetStatusLabel.SUBMITTED) return colors.textAccent.inverse;
    else if (isTSDisabled) return colors.icon.disabled;
    else if (
      statusLabel === TimesheetStatusLabel.PENDING_DEAL_OWNERS ||
      statusLabel === TimesheetStatusLabel.PENDING_OP_MANAGER
    )
      return colors.icon.disabled;
    return colors.textStatus.active;
  }, [colors, statusLabel, isTSDisabled]);

  const isPending = useMemo(() => {
    return (
      statusLabel === TimesheetStatusLabel.PENDING_DEAL_OWNERS ||
      statusLabel === TimesheetStatusLabel.PENDING_OP_MANAGER
    );
  }, [statusLabel]);

  const cellLabel = useMemo(() => {
    if (statusLabel === TimesheetStatusLabel.SUBMITTED) return 'Review';
    return statusLabel;
  }, [statusLabel]);

  const progressPercentage = useMemo(() => {
    const allOpManagersCount = operatingPartner?.operatingPartnerManagers?.length ?? 0;
    const approvedManagersCount = timesheet?.approvedByOpManagers?.length ?? 0;

    const allDeals = timesheet?.timesheetRows.filter((row) => row.dealOwners?.length && row.avg > 0);
    const allDealsCount = allDeals?.length ?? 0;
    const approvedDealsCount = allDeals?.filter((deal) => deal.isApprovedByDealOwner).length ?? 0;

    return ((approvedDealsCount + approvedManagersCount) / (allDealsCount + allOpManagersCount)) *
      100 >
      100
      ? 100
      : ((approvedDealsCount + approvedManagersCount) / (allDealsCount + allOpManagersCount)) * 100;
  }, [
    operatingPartner?.operatingPartnerManagers,
    timesheet?.approvedByOpManagers,
    timesheet?.timesheetRows,
  ]);

  if (!timesheet) return <></>;

  if (isPending)
    return (
      <PendingStatusTooltip timesheet={timesheet} operatingPartner={operatingPartner}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'start'}>
          <Button
            variant='text'
            disabled={isTSDisabled}
            style={{
              background: backgroundStyle,
              color: colorStyle,
              width: '100%',
              height: '90%',
              borderRadius: '4px 4px 0px 0px',
            }}
            onClick={redirectToSingleTs}
          >
            {cellLabel}
          </Button>
          <ProgressBar
            progress={progressPercentage}
            color={colors.textAccent.default}
            height='5px'
          />
        </Box>
      </PendingStatusTooltip>
    );

  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'start'}>
      <Button
        variant='text'
        disabled={isTSDisabled}
        style={{
          background: backgroundStyle,
          color: colorStyle,
          width: '100%',
          height: '90%',
          borderRadius: '4px',
        }}
        onClick={redirectToSingleTs}
      >
        {cellLabel}
      </Button>
    </Box>
  );
}
