import { useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { configToastMessageState, isOpenToastMessageState } from '../state/UIState';
import { ExtendedToastProps, ToastMessageType, ToastProps } from '../types';

export function useToastMessage() {
  const [, setIsOpen] = useAtom(isOpenToastMessageState);
  const [, setConfig] = useAtom(configToastMessageState);

  const pushToast = useCallback(
    (config: ExtendedToastProps): void => {
      setConfig(config);
      setIsOpen(true);
    },
    [setConfig, setIsOpen]
  );

  const pushSuccessToast = useCallback(
    (config: ToastProps): void => {
      pushToast({ ...config, type: ToastMessageType.success });
    },
    [pushToast]
  );
  const pushWarningToast = useCallback(
    (config: ToastProps): void => {
      pushToast({ ...config, type: ToastMessageType.warning });
    },
    [pushToast]
  );
  const pushErrorToast = useCallback(
    (config: ToastProps): void => {
      pushToast({ ...config, type: ToastMessageType.error });
    },
    [pushToast]
  );
  const pushInfoToast = useCallback(
    (config: ToastProps): void => {
      pushToast({ ...config, type: ToastMessageType.info });
    },
    [pushToast]
  );

  const value = useMemo(() => {
    return {
      pushSuccessToast,
      pushWarningToast,
      pushErrorToast,
      pushInfoToast,
    };
  }, [pushErrorToast, pushInfoToast, pushSuccessToast, pushWarningToast]);

  return value;
}
