import { useTheme } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { AvatarCell } from '../../../components/Table/CellRenderers/AvatarCell';
import { TableHeaderCell } from '../../../components/Table/CellRenderers/TableHeaderCell';
import { Typography } from '../../../components/Typography/Typography';
import { SelectItem, User } from '../../../types';
import { ActionsCell } from '../components/ActionsCell';
import DealCell from '../components/DealCell';

const columnHelper = createColumnHelper<User>();

export const useAssigmentUsersTableColumns = () => {
  const { colors } = useTheme();

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        cell: (info) => (
          <Typography variant='body' color={colors.text.caption}>
            {info.getValue()}
          </Typography>
        ),
        header: () => <TableHeaderCell text='ID' />,
        meta: {
          width: '50px',
          minWidth: '50px',
          maxWidth: '50px',
          tdStyles: { paddingLeft: '16px', borderRight: `none` },
          thStyles: { borderRight: `none` },
        },
      }),
      columnHelper.accessor('name', {
        cell: (info) => (
          <AvatarCell
            name={info.getValue()}
            avatarBoxStyle={{
              background: colors.surfaceIndicator.needsReview,
            }}
          />
        ),
        header: () => <TableHeaderCell text='Operating Partner' />,
        filterFn: (row, id, filterValue: SelectItem[]) => {
          if (!filterValue.length) return true;
          return filterValue.some((user) => user.id === row.original.id);
        },
        enableSorting: false,
        meta: {
          width: '30%',
          maxWidth: '30%',
          minWidth: '30%',
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
        },
      }),
      columnHelper.accessor('operatingPartnerDeals', {
        cell: (info) => <DealCell deals={info.row.original.operatingPartnerDeals} />,
        header: () => <TableHeaderCell text='Deals' />,
        enableSorting: false,

        meta: {
          width: '60%',
          maxWidth: '60%',
          minWidth: '60%',
          thStyles: { paddingLeft: '16px', borderRight: `none` },
          tdStyles: { paddingLeft: '16px', borderRight: `none` },
        },
        filterFn: (row, id, filterValue) => {
          const isUserNameMatched = (row.original.name ?? '')
            .toLowerCase()
            .includes(filterValue.toLowerCase());
          const isDealNameMatched = row.original.operatingPartnerDeals
            ? row.original.operatingPartnerDeals.some((deal) => {
                return deal?.name.toLowerCase().includes(filterValue.toLowerCase());
              })
            : false;
          return isUserNameMatched || isDealNameMatched;
        },
      }),

      columnHelper.display({
        id: 'actions',
        cell: (info) => <ActionsCell {...info} />,
        header: () => <TableHeaderCell text='' />,
        meta: {
          width: '200px',
          minWidth: '200px',
          maxWidth: '200px',
          tdStyles: { paddingLeft: '0', borderLeft: `none` },
          thStyles: { borderLeft: `none` },
        },
      }),
    ],
    [colors]
  );

  return columns;
};
