import { useMutation } from 'react-query';
import { Http } from '../../services/Http';
import { MUTATION_KEYS } from '../constants';
import { useInvalidateHolidays } from './useHolidays';
import { Holiday } from '../../types';

type AddHolidayPayload = {
  type: string;
  name: string;
  country: string;
  frequency: string;
  startDate: string;
  endDate: string;
};

async function addHoliday(payload: AddHolidayPayload): Promise<Holiday[]> {
  const { data } = await Http.axios.post<AddHolidayPayload, Holiday[]>(`/holiday`, payload);
  return data;
}

export const useAddHoliday = () => {
  const invalidateHolidays = useInvalidateHolidays();
  return useMutation(addHoliday, {
    mutationKey: MUTATION_KEYS.ADD_HOLIDAY_KEY,
    onSuccess: () => {
      invalidateHolidays();
    },
  });
};
