import { styled } from '@mui/material/styles';
import { Switch, useTheme } from '@mui/material';
import { CSSProperties, ReactNode } from 'react';
import { Typography } from '../Typography/Typography';

interface FormFieldProps {
  children: ReactNode;
  label: string;
  error?: string;
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  canBeDisabled?: boolean;
  disabled?: boolean;
  onToggleDisabled?: () => void;
}

const FieldWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
  .MuiSwitch-root {
    padding: 0;
    border-radius: 50px;
    height: 16px;
    width: 32px;
  }

  .MuiSwitch-root .MuiButtonBase-root.MuiSwitch-switchBase .MuiSwitch-thumb {
    height: 12px;
    width: 12px;
    background-color: ${({ theme }) => theme.colors.textAccent.disabled};
  }

  .MuiSwitch-root .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
    height: 12px;
    width: 12px;
    background-color: ${({ theme }) => theme.colors.surfaceBackground.bg1};
  }

  .MuiSwitch-root .MuiSwitch-switchBase {
    padding: 2px;
  }

  .MuiButtonBase-root.MuiSwitch-switchBase + .MuiSwitch-track {
    background-color: ${({ theme }) => theme.colors.surfaceBackground.bg1};
    border: 1px solid ${({ theme }) => theme.colors.textAccent.disabled};
    opacity: 1;
  }
  .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: ${({ theme }) => theme.colors.surfaceAccent.default};
    opacity: 1;
  }
`;

const ChildrenWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LabelWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export function FormField({
  children,
  error,
  label,
  style,
  labelStyle,
  canBeDisabled,
  disabled,
  onToggleDisabled,
}: FormFieldProps) {
  const { colors } = useTheme();

  return (
    <FieldWrapper data-testid={'form-field'} style={style}>
      <LabelWrapper>
        <Typography
          as={'label'}
          variant={'srOnly'}
          color={colors.text.secondary}
          className='Label'
          style={labelStyle}
        >
          {label}
        </Typography>
        {canBeDisabled && (
          <Switch
            color={'primary'}
            size={'small'}
            checked={!disabled}
            onChange={onToggleDisabled}
          />
        )}
      </LabelWrapper>

      <ChildrenWrapper>
        {children}
        {error && (
          <Typography variant={'caption'} color={colors.iconStatus.error}>
            {error}
          </Typography>
        )}
      </ChildrenWrapper>
    </FieldWrapper>
  );
}
