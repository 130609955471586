import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, FormControlLabel, styled } from '@mui/material';
import { Field, FieldArray, getIn, useFormikContext } from 'formik';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar.svg';
import { AvailableMonthlyTimesheet } from '../../../../types';
import { getMonthLabelShort } from '../../../../utils/formatters';
import { IExportMultiTSForm } from './ExportMultiTimesheetModal';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';

interface MultiSelectPeriodProps {
  availablePeriods: AvailableMonthlyTimesheet[];
}

export const MultiSelectPeriod = ({ availablePeriods }: MultiSelectPeriodProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const boxRef = useRef(null);
  const { setFieldValue, values } = useFormikContext<IExportMultiTSForm>();
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (availablePeriods) {
      const currentYearPeriod = availablePeriods.find(
        (period) => Number(period.year) === currentYear
      );
      if (currentYearPeriod) {
        setFieldValue(`selectedPeriod[0]`, {
          year: currentYearPeriod.year,
          months: currentYearPeriod.months.reduce((acc, key) => {
            acc[key] = true;
            return acc;
          }, {}),
        });
      }
    }
  }, [availablePeriods, currentYear, setFieldValue]);

  useOutsideClick(boxRef, () => {
    if (dropdownOpen) {
      setDropdownOpen(false);
    }
  });

  const handleDropdownOpen = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const selectLabel = useMemo(() => {
    const labels: string[] = [];
    const currentYearData = values.selectedPeriod.find(({ year }) => year === currentYear);
    const allRestFalse = values.selectedPeriod
      .filter(({ year }) => year !== currentYear)
      .every((item) => {
        return Object.values(item.months).every((value) => value === false);
      });
    if (
      currentYearData &&
      Object.values(currentYearData.months).every((month) => month) &&
      allRestFalse
    ) {
      return 'Current year';
    }
    values.selectedPeriod.forEach(({ year, months }) => {
      Object.entries(months).forEach(([month, value]) => {
        if (value) {
          labels.push(`${getMonthLabelShort(Number(month))}-${year}`);
        }
      });
    });
    if (labels.length) {
      const labelStr = labels.join(', ');
      return labelStr.length > 24 ? labelStr.slice(0.24) + '...' : labelStr;
    }
    return 'Select period';
  }, [values.selectedPeriod, currentYear]);

  return (
    <Box position='relative' ref={boxRef}>
      <SelectPeriod onClick={handleDropdownOpen}>
        <span>{selectLabel}</span>
        <CalendarIcon />
      </SelectPeriod>
      {dropdownOpen && (
        <SelectPeriodDropdown>
          <FieldArray
            name='selectedPeriod'
            render={() =>
              availablePeriods.map((period, ind) => (
                <Box key={ind} marginY={1}>
                  <FormControlLabel
                    control={
                      <Field
                        id={period.year.toString()}
                        checked={
                          getIn(values, `selectedPeriod[${ind}].months`)
                            ? Object.values(
                                getIn(values, `selectedPeriod[${ind}].months`) ?? []
                              ).every((val) => val)
                            : false
                        }
                        as={Checkbox}
                        type='checkbox'
                        onChange={(e) => {
                          setFieldValue(`selectedPeriod[${ind}]`, {
                            year: period.year,
                            months: availablePeriods[ind].months.reduce((acc, key) => {
                              acc[key] = e.target.checked;
                              return acc;
                            }, {}),
                          });
                        }}
                      ></Field>
                    }
                    label={period.year}
                  />
                  <FieldArray
                    name={`selectedPeriod[${ind}].months]`}
                    render={() =>
                      period.months.map((month, i) => (
                        <Box key={i} paddingLeft={5} marginY={1}>
                          <FormControlLabel
                            control={
                              <Field
                                name={`selectedPeriod[${ind}].months[${month}]`}
                                as={Checkbox}
                                type='checkbox'
                                onChange={(e) => {
                                  setFieldValue(`selectedPeriod[${ind}]`, {
                                    year: period.year,
                                    months: {
                                      ...getIn(values, `selectedPeriod[${ind}].months`),
                                      [month.toString()]: e.target.checked,
                                    },
                                  });
                                }}
                              />
                            }
                            label={getMonthLabelShort(Number(month))}
                          />
                        </Box>
                      ))
                    }
                  />
                </Box>
              ))
            }
          ></FieldArray>
        </SelectPeriodDropdown>
      )}
    </Box>
  );
};

const SelectPeriod = styled('div')`
  border: 1px solid ${({theme}) => theme.colors.border.default};
  color: ${({theme}) => theme.colors.text.caption} !important;
  border-radius: 4px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  padding: 6px 10px 6px 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const SelectPeriodDropdown = styled('div')`
  border: 1px solid ${({theme}) => theme.colors.border.default};
  background-color: ${({theme}) => theme.colors.surfaceBackground.bg1};
  padding: 4px 6px 4px 26px;
  border-radius: 4px;
  position: absolute;
  top: 35px;
  z-index: 1;
  width: 100%;
  max-height: 250px;
  overflow-y: scroll;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .MuiFormControlLabel-root {
    width: 100%;
    padding: 2px 4px;
    border-radius: 4px;
  }
  .MuiFormControlLabel-root:hover {
    background-color: ${({theme}) => theme.colors.border.default};
  }
`;
