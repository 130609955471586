import ContentLoader from 'react-content-loader';
import { styled, useTheme } from '@mui/material/styles';
import { ReactElement } from 'react';

const SkeletonWrapper = styled('div')`
  display: flex;
  flex: 1;
`;

export function DealSkeletonLoading(): ReactElement {
  const { colors } = useTheme();
  return (
    <SkeletonWrapper>
      <ContentLoader
        height={210}
        width={'100%'}
        backgroundColor={colors.skeletonLoader.background}
        foregroundColor={colors.skeletonLoader.foreground}
        speed={2}
      >
        <rect y='10' width='25%' height='10' fill='#EEEFF1' />
        <rect y='30' width='100%' height='35' fill='#EEEFF1' />

        <rect y='85' width='25%' height='10' fill='#EEEFF1' />
        <rect y='105' width='100%' height='35' fill='#EEEFF1' />

        <rect y='160' width='25%' height='10' fill='#EEEFF1' />
        <rect y='180' width='100%' height='35' fill='#EEEFF1' />
        {/* <rect y='60' width='100%' height='38' fill='#EEEFF1' />
        <rect y='110' width='100%' height='38' fill='#EEEFF1' /> */}
      </ContentLoader>
    </SkeletonWrapper>
  );
}
