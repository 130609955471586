import { Workbook } from 'exceljs';
import {
  calcTotalPerDay,
  calcTotalPerWeekday,
  calcTotalWeekdayPerDeal,
  calcWeekdayTotal,
} from '../pages/AccountantSingleTimesheet/hooks/useTimesheetCalculations';
import {
  MonthlyTimesheetToExport,
  TimesheetRow,
  WeeklyTimesheet,
} from '../types';
import {
  addDaysToDate,
  formatUSDate,
  getDayLabel,
  getMonthLabelShort,
  getTodaysDate,
} from './formatters';
import { saveAs } from 'file-saver';

const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

const rowAvg = (timesheet: TimesheetRow, weekDatesLength: number, holidaysLength: number) => {
  if (
    !(
      timesheet.monday +
      timesheet.tuesday +
      timesheet.wednesday +
      timesheet.thursday +
      timesheet.friday
    )
  )
    return 0;
  return (
    (timesheet.monday +
      timesheet.tuesday +
      timesheet.wednesday +
      timesheet.thursday +
      timesheet.friday) /
    (weekDatesLength + holidaysLength)
  );
};

function calcTotal(weeklyTimesheet: WeeklyTimesheet) {
  return (
    weeklyTimesheet.timesheetRows.reduce(
      (acc, row) =>
        acc + rowAvg(row, weeklyTimesheet.weekDates.length, weeklyTimesheet.holidays.length),
      0
    ) + '%'
  );
}

export const generateMonthTemplate = async (timesheets: MonthlyTimesheetToExport[]) => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Sheet 1');

  worksheet.columns = [
    { header: 'Date Generated', key: 'date', width: 20 },
    { header: 'View', key: 'view', width: 20 },
    { header: 'OP', key: 'op', width: 20 },
    { header: 'Deal', key: 'deal', width: 20 },
    { header: 'Date', key: 'date', width: 20 },
    { header: 'Percentage', key: 'percentage', width: 20, style: { alignment: { horizontal: 'right' } } },
  ];

  timesheets
    .filter((monthlyTS) => monthlyTS.timesheets.length)
    .forEach((ts) => {
      return ts.timesheets
        .filter((timesheet) => timesheet.avgWork > 0)
        .forEach((montlyTS) => {
          return montlyTS.timesheetRows
            .filter((e) => e.avg > 0)
            .forEach((row) => {
              worksheet.addRow([
                getTodaysDate(),
                'by Month',
                montlyTS.user?.name,
                row.name,
                `${getMonthLabelShort(montlyTS.month)}-${montlyTS.year}`,
                row.avg.toFixed(2) + '%',
              ]);
            });
        });
    });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  saveAs(blob, `Timesheets_by_Month.xlsx`);
};

export const generateWeekdayTemplate = async (monthlyTS: MonthlyTimesheetToExport[]) => {
  const workbook = new Workbook();
  
  monthlyTS.filter((ts) => ts.timesheets.length).forEach((ts) => {
    const worksheet = workbook.addWorksheet(ts.name);

  worksheet.columns = [
    { header: 'Date Generated', key: 'date', width: 20 },
    { header: 'View', key: 'view', width: 20 },
    { header: '', width: 20 },
    { header: '', width: 20 },
    { header: '', width: 20 },
    { header: '', width: 20 },
    { header: '', width: 20 },
  ];
  worksheet.addRow([getTodaysDate(), 'by Weekday']);
  worksheet.addRow([]);

    ts.timesheets.forEach((timesheet) => {
      worksheet.addRow([timesheet.user?.name]);
      worksheet.addRow([`${getMonthLabelShort(timesheet.month)}, ${timesheet.year}`]);
      worksheet.addRow([]);
      worksheet.addRow(['Deal', ...weekdays, 'Total per Deal']);
      timesheet.timesheetRows
        .sort((a, b) => b.dealId - a.dealId)
        .filter(
          (row) =>
            calcTotalWeekdayPerDeal('monday', timesheet.weeklyTimesheets, row.dealId, row.name) > 0 ||
            calcTotalWeekdayPerDeal('tuesday', timesheet.weeklyTimesheets, row.dealId, row.name) > 0 ||
            calcTotalWeekdayPerDeal('wednesday', timesheet.weeklyTimesheets, row.dealId, row.name) > 0 ||
            calcTotalWeekdayPerDeal('thursday', timesheet.weeklyTimesheets, row.dealId, row.name) > 0 ||
            calcTotalWeekdayPerDeal('friday', timesheet.weeklyTimesheets, row.dealId, row.name)
        )
        .forEach((row, i) => {
          const rows = worksheet.addRow([
            timesheet.weeklyTimesheets[0]?.timesheetRows[i].name,
            calcTotalWeekdayPerDeal('monday', timesheet.weeklyTimesheets, row.dealId, row.name) + '%',
            calcTotalWeekdayPerDeal('tuesday', timesheet.weeklyTimesheets, row.dealId, row.name) + '%',
            calcTotalWeekdayPerDeal('wednesday', timesheet.weeklyTimesheets, row.dealId, row.name) + '%',
            calcTotalWeekdayPerDeal('thursday', timesheet.weeklyTimesheets, row.dealId, row.name) + '%',
            calcTotalWeekdayPerDeal('friday', timesheet.weeklyTimesheets, row.dealId, row.name) + '%',
            row.avg.toFixed(2) + '%',
          ]);
          [
            rows.getCell(2),
            rows.getCell(3),
            rows.getCell(4),
            rows.getCell(5),
            rows.getCell(6),
            rows.getCell(7),
          ].forEach((cell) => {
            cell.alignment = { horizontal: 'right' };
          });
        });
      const row = worksheet.addRow([
        'Total per day',
        calcTotalPerWeekday('monday', timesheet.weeklyTimesheets) + '%',
        calcTotalPerWeekday('tuesday', timesheet.weeklyTimesheets) + '%',
        calcTotalPerWeekday('wednesday', timesheet.weeklyTimesheets) + '%',
        calcTotalPerWeekday('thursday', timesheet.weeklyTimesheets) + '%',
        calcTotalPerWeekday('friday', timesheet.weeklyTimesheets) + '%',
        calcWeekdayTotal(timesheet.weeklyTimesheets) + '%',
      ]);
      [
        row.getCell(2),
        row.getCell(3),
        row.getCell(4),
        row.getCell(5),
        row.getCell(6),
        row.getCell(7),
      ].forEach((cell) => {
        cell.alignment = { horizontal: 'right' };
      });
      worksheet.addRow([]);
    })
  })

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  saveAs(blob, 'Timesheets_by_Weekday.xlsx');
};

export const generateWeekTemplate = async (monthlyTS: MonthlyTimesheetToExport[]) => {
  const workbook = new Workbook();
  monthlyTS
    .filter((ts) => ts.timesheets.length)
    .forEach((ts) => {
      const worksheet = workbook.addWorksheet(ts.name);

      worksheet.columns = [
        { header: 'Date Generated', key: 'date', width: 20 },
        { header: 'View', key: 'view', width: 20 },
        { header: '', width: 20 },
        { header: '', width: 20 },
        { header: '', width: 20 },
        { header: '', width: 20 },
        { header: '', width: 20 },
      ];
      worksheet.addRow([getTodaysDate(), 'by Week']);
      worksheet.addRow([]);
      worksheet.addRow([ts.name]);

      ts.timesheets.forEach((timesheet) => {
        timesheet.weeklyTimesheets.forEach((weeklyTS, i) => {
          worksheet.addRow([]);
          if (i === 0) {
            worksheet.addRow([`${getMonthLabelShort(timesheet.month)}, ${timesheet.year}`]);
            worksheet.addRow([]);
          }
          worksheet.addRow([`Week ${i + 1}`]);
          worksheet.addRow([
            'Deal',
            ...weekdays.map((_, index) => `${getDateLabel(index + 1, weeklyTS.weekStart)}`),
            'Total per Deal',
          ]);

          weeklyTS.timesheetRows
            .filter(
              (row) =>
                row.monday > 0 ||
                row.tuesday > 0 ||
                row.wednesday > 0 ||
                row.thursday > 0 ||
                row.friday > 0
            )
            .forEach((row) => {
              const rows = worksheet.addRow([
                row.name,
                ...weekdays.map((weekday) =>
                  typeof row[`${weekday.toLowerCase()}`] === 'number'
                    ? row[`${weekday.toLowerCase()}`] + '%'
                    : '0%'
                ),
                rowAvg(row, weeklyTS.weekDates.length, weeklyTS.holidays.length) + '%',
              ]);
              [
                rows.getCell(2),
                rows.getCell(3),
                rows.getCell(4),
                rows.getCell(5),
                rows.getCell(6),
                rows.getCell(7),
              ].forEach((cell) => {
                cell.alignment = { horizontal: 'right' };
              });
            });

          const row = worksheet.addRow(['Total per day', ...calcTotalPerDay(weeklyTS), calcTotal(weeklyTS)]);
          [
            row.getCell(2),
            row.getCell(3),
            row.getCell(4),
            row.getCell(5),
            row.getCell(6),
            row.getCell(7),
          ].forEach((cell) => {
            cell.alignment = { horizontal: 'right' };
          });
        });
      });
    });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  saveAs(blob, `Timesheets_by_Week.xlsx`);
};

const getDateLabel = (dayOrder: number, weekStart: string) => {
  if (weekStart) {
    const date = addDaysToDate(weekStart, dayOrder - 1);
    const formattedDate = formatUSDate(date.toISOString());
    return `${getDayLabel(dayOrder).slice(0, 3)} ${formattedDate}`;
  }
  return '';
};

