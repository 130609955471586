import { atom } from 'jotai';
import { Holiday, HolidayTab, SelectItem } from '../types';
import { Table } from '@tanstack/react-table';

const today = new Date();
const currentYear = today.getFullYear().toString();
const currentMonth = today.getMonth() + 1;

export const holidaySelectedTab = atom<HolidayTab>(0);
export const holidaySearch = atom<string>('');
export const holidaySelectedCountries = atom<SelectItem[]>([]);
export const holidaySelectedTypes = atom<SelectItem[]>([]);
export const holidaySelectedYear = atom<string>(currentYear);
export const holidaySelectedMonth = atom<number>(currentMonth);
export const holidayTable = atom<Table<Holiday> | null>(null);

// add-edit holiday modal
export const addHolidayModalOpen = atom<boolean>(false);
export const addHolidayModalSelected = atom<Holiday | null>(null);
export const addHolidayModalSelectedStart = atom<string>('');

// remove holiday(s) modal
export const removeHolidayModalOpen = atom<boolean>(false);
export const removeHolidayModalSelected = atom<Holiday[] | null>(null);
