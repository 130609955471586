import { useQuery, useQueryClient } from 'react-query';
import { Http } from '../../services/Http';
import { MonthlyTimesheetToExport } from '../../types';
import { QUERY_KEYS } from '../constants';

async function fetchMonthlyTimesheetsToExport(
  userIds: string,
  timeRange: string
): Promise<MonthlyTimesheetToExport[]> {
  const { data } = await Http.axios.get<MonthlyTimesheetToExport[]>(
    `/monthly-timesheet?ids=${userIds}&months=${timeRange}`
  );
  return data;
}

export const useMonthlyTimesheetsToExport = ({ userIds, timeRange }: {
    userIds: string,
    timeRange: string,
}) => {
  return useQuery(
    [QUERY_KEYS.MONTHLY_TIMESHEET_TO_EXPORT, userIds, timeRange],
    () => fetchMonthlyTimesheetsToExport(userIds, timeRange),
    { 
      staleTime: Infinity,
      enabled: !!timeRange,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    }
  );
};

export const useInvalidateMonthlyTimesheetsToExport = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.MONTHLY_TIMESHEET_TO_EXPORT,
    })
  };
};
