import { Box, useTheme } from '@mui/material';
import { Typography } from '../../Typography/Typography';
import { useMonthlySingleTimesheet } from '../../../queries/hooks/useMonthlySingleTimesheet';
import { useLocation, useParams } from 'react-router';
import { ROUTES } from '../../../constants/routes';
import { useMemo, useState } from 'react';
import TooltipComponent from '../../TooltipComponent/TooltipComponent';
import { ReactComponent as CalendarDisabledIcon } from '../../../assets/icons/calendar-disabled.svg';

interface Props {
  text: string;
  style?: React.CSSProperties;
}

export const TableHeaderCell = ({ text, style }: Props) => {
  const { colors } = useTheme();
  const { id } = useParams();
  const location = useLocation();
  const [showToolip, setShowToolip] = useState(false);

  const headerDay = text.split(',')[1]?.split('/')[1];
  const headerMonth = text.split(',')[1]?.split('/')[0];

  const { data: monthlyTimesheet } = useMonthlySingleTimesheet({
    id: Number(id),
    enabled: !!id && location.pathname.includes(ROUTES.ACCOUNTANT_TIMESHEETS_STATUSES),
  });

  const holiday = useMemo(() => {
    if (monthlyTimesheet) {
      const allHolidays = monthlyTimesheet?.weeklyTimesheets.flatMap((ts) => ts.holidays);
      return allHolidays.find(
        (holiday) => holiday.day === Number(headerDay) && holiday.month === Number(headerMonth)
      );
    }
    return;
  }, [monthlyTimesheet, headerDay, headerMonth]);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
      }}
    >
      <Typography
        variant='subtitle1'
        color={colors.text.main}
        style={{ letterSpacing: '0.25px', ...style }}
      >
        {text}
      </Typography>
      {!!holiday && (
        <Box marginTop='4px'>
          <TooltipComponent
            open={showToolip}
            onClose={() => setShowToolip(false)}
            onOpen={() => setShowToolip(true)}
            placement='top'
            content={
              <Typography variant='body' color={colors.text.main}>
                {holiday.name}
              </Typography>
            }
          >
            <CalendarDisabledIcon
              onMouseEnter={() => setShowToolip(true)}
              onMouseLeave={() => setShowToolip(false)}
            />
          </TooltipComponent>
        </Box>
      )}
    </Box>
  );
};
