import { useMutation } from 'react-query';
import { Http } from '../../services/Http';
import { TimesheetCell, WeekDay } from '../../types';
import { MUTATION_KEYS } from '../constants';
import { useInvalidateSingleTimesheet } from './useSingleTimesheet';

type Props = {
  weekDay: WeekDay;
  timesheetId: number;
};

async function clearTimesheetColumn({ weekDay, timesheetId }: Props): Promise<TimesheetCell> {
  const { data } = await Http.axios.patch<null, TimesheetCell>(
    `/timesheet/${timesheetId}/clear/${weekDay}`
  );
  return data;
}

export const useClearTimesheetColumn = () => {
  const invalidateSingleTimesheet = useInvalidateSingleTimesheet();
  return useMutation(clearTimesheetColumn, {
    mutationKey: MUTATION_KEYS.CLEAR_TIMESHEET_COLUMN,
    onSuccess: () => {
      invalidateSingleTimesheet();
    },
  });
};
