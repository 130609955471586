import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import * as yup from 'yup';
import { ModalComponent } from '../../../../components/ModalComponent/ModalComponent';
import { useLoadingBar } from '../../../../hooks/useLoadingBar';
import { useToastMessage } from '../../../../hooks/useToastMessage';
import { Deal, LoadingId, SelectItem } from '../../../../types';
import { DealFormContent } from './DealFormContent';
import { useAddDeal } from '../../../../queries/hooks/useAddDeal';
import { useEditDeal } from '../../../../queries/hooks/useEditDeal';

type Props = {
  addDealOpen: boolean;
  handleAddDealClose: () => void;
  deal?: Deal;
};

export interface IAddModalFields {
  name: string;
  operatingPartners: SelectItem[];
  dealOwners: SelectItem[];
}

const defaultInitialValues = {
  name: '',
  operatingPartners: [],
  dealOwners: [],
};

export function DealFormModal({ deal, addDealOpen, handleAddDealClose }: Props) {
  const { pushSuccessToast, pushErrorToast } = useToastMessage();
  const { startLoading, stopLoading } = useLoadingBar();

  const { mutate: onAddDeal, isLoading: addDealInProgress } = useAddDeal();
  const { mutate: onEditDeal, isLoading: editDealInProgress } = useEditDeal();

  const submitInProgress = addDealInProgress || editDealInProgress;

  const handleSubmitSuccess = (newDeal) => {
    pushSuccessToast({
      title: toastSuccesTitle,
      message: `Deal ${newDeal?.name} has been successfully ${deal ? 'updated' : 'added'}`,
    });
    handleAddDealClose();
  };
  const handleSubmitError = () => {
    pushErrorToast({ message: `Failed to ${deal ? 'update' : 'add'} deal` });
  };
  const handleSubmitSettled = () => {
    stopLoading(LoadingId.addDeal);
  };

  const initialValues = useMemo(() => {
    if (deal) {
      const dealOwners = (deal?.dealOwners || []).map((item) => {
        return {
          id: item.id,
          value: item.name,
        };
      });

      const operatingPartners = (deal?.operatingPartners || []).map((item) => {
        return {
          id: item.id,
          value: item.name,
        };
      });

      return {
        name: deal?.name,
        operatingPartners,
        dealOwners,
      };
    }

    return defaultInitialValues;
  }, [deal]);

  const validationSchema = yup.object({
    name: yup.string().required('Deal name is required field'),
    operatingPartner: yup
      .array()
      .min(1, 'You must select at least one operating partner')
      .max(2, 'You can select max 2 operationg partners'),
    dealOwners: yup
      .array()
      .min(1, 'You must select at least one deal owner')
      .max(2, 'You can select max 2 deal owners')
      .required(),
  });

  const title = deal ? `Edit ${deal?.name}` : 'Add a Deal';
  const toastSuccesTitle = deal ? 'Deal Updated' : 'Deal Added';

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      validateOnBlur
      initialValues={initialValues}
      validateOnMount
      validateOnChange
      onSubmit={(values: IAddModalFields) => {
        startLoading(LoadingId.addDeal);
        const payload = {
          name: values.name,
          operatingPartners: values.operatingPartners.map((item) => Number(item.id)),
          dealOwners: values.dealOwners.map((item) => Number(item.id)),
        };

        if (deal) {
          onEditDeal(
            {
              payload,
              id: deal.id,
            },
            {
              onSuccess: handleSubmitSuccess,
              onError: handleSubmitError,
              onSettled: handleSubmitSettled,
            }
          );
          return;
        }

        onAddDeal(payload, {
          onSuccess: handleSubmitSuccess,
          onError: handleSubmitError,
          onSettled: handleSubmitSettled,
        });
      }}
    >
      {({ handleSubmit, errors }) => {
        const hasValidationErrors = !!Object.keys(errors)?.length;
        return (
          <Form>
            <ModalComponent
              isOpen={addDealOpen}
              onClose={handleAddDealClose}
              actionButtonsProps={{
                isSubmitDisabled: submitInProgress || hasValidationErrors,
                onSubmit: handleSubmit,
                onCancel: handleAddDealClose,
                submitText: deal ? 'Update deal' : 'Add a deal',
              }}
              title={title}
              top='80px'
            >
              <DealFormContent />
            </ModalComponent>
          </Form>
        );
      }}
    </Formik>
  );
}
