import { useAtom } from 'jotai';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { loadingIdsState } from '../state/UIState';
import { LoadingId } from '../types';

export function useLoadingBar() {
  const [lodingIds, setLoadingIds] = useAtom(loadingIdsState);
  const [isLoading, setIsLoading] = useState(false);

  const startLoading = useCallback(
    (id: LoadingId): void => {
      setLoadingIds((prev) => [...prev, id]);
    },
    [setLoadingIds]
  );

  const stopLoading = useCallback(
    (id: LoadingId): void => {
      setLoadingIds((prev) => prev.filter((item) => item !== id));
    },
    [setLoadingIds]
  );

  useEffect(() => {
    setIsLoading(lodingIds.length > 0);
  }, [lodingIds]);

  const value = useMemo(() => {
    return {
      isLoading,
      startLoading,
      stopLoading,
    };
  }, [isLoading, startLoading, stopLoading]);

  return value;
}
