import { Checkbox as MuiCheckbox, CheckboxProps, useTheme } from '@mui/material';
import { FC } from 'react';

export const Checkbox: FC<CheckboxProps> = (props) => {
  const { colors } = useTheme();
  const { sx, ...rest } = props;
  return (
    <MuiCheckbox
      sx={{
        padding: '0',
        marginRight: '5px',
        color: colors.border.accent,
        '& .MuiSvgIcon-root': {
          fontSize: 16,
        },
        '&.Mui-checked': {
          color: colors.surfaceAccent.default,
        },
        ...sx,
      }}
      {...rest}
    />
  );
};
