import { useTheme } from '@mui/material';
import { TimesheetStatusLabel } from '../types';

export function useTimesheetStatusColor(status?: TimesheetStatusLabel) {
  const { colors } = useTheme();

  const statusColor = (() => {
    switch (status) {
      case TimesheetStatusLabel.ISSUED:
        return colors.textStatus.active;
      case TimesheetStatusLabel.PENDING:
        return colors.text.main;
      case TimesheetStatusLabel.REJECTED:
        return colors.textStatus.error;
      default:
        return colors.text.main;
    }
  })();

  return statusColor;
}
