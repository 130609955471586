import { AddUserPayload, EditUserPayload, UserRole } from '../../../../types';
import { IUserFileds } from './UserModal';

export const createAddUserPayload = ({
  name,
  email,
  managers,
  operatingPartnerDeals,
  role,
  isAdmin,
  isOp,
  operatingPartners,
  dealOwnerDeals,
  proxyOperatingPartners,
  isSelfApprovingEnabled,
  proxy,
  startDate,
  endDate,
  country,
}: IUserFileds): AddUserPayload => {
  if (role === UserRole.OPERATING_PARTNER) {
    return {
      name,
      email,
      role,
      isAdmin,
      isOp,
      operatingPartnerManagers: (managers || [])?.map((manager) => Number(manager.id)),
      operatingPartnerDeals: (operatingPartnerDeals || [])?.map((deal) => Number(deal.id)),
      proxy: Number(proxy?.id),
      startDate,
      endDate,
      country,
    };
  }
  if (role === UserRole.PROXY) {
    return {
      name,
      email,
      role,
      isAdmin,
      proxyOperatingPartners: proxyOperatingPartners?.map((partner) => Number(partner.id)),
      isOp: false,
    };
  }
  if (role === UserRole.OP_MANAGER) {
    if (isOp) {
      return {
        name,
        email,
        role,
        isAdmin,
        operatingPartners: operatingPartners?.map((partner) => Number(partner.id)),
        isOp,
        isSelfApprovingEnabled,
        operatingPartnerManagers: (managers || [])?.map((manager) => Number(manager.id)),
        operatingPartnerDeals: (operatingPartnerDeals || [])?.map((deal) => Number(deal.id)),
        proxy: Number(proxy?.id),
        startDate,
        endDate,
        country,
      };
    }
    return {
      name,
      email,
      role,
      isAdmin,
      isSelfApprovingEnabled,
      operatingPartners: operatingPartners?.map((partner) => Number(partner.id)),
      isOp,
    };
  }
  if (role === UserRole.DEAL_OWNER) {
    return {
      name,
      email,
      role,
      isAdmin,
      dealOwnerDeals: dealOwnerDeals?.map((deal) => Number(deal.id)),
      isOp: false,
    };
  }
  if (role === UserRole.ACCOUNTANT) {
    return {
      name,
      email,
      role,
      isAdmin,
      isOp: false,
    };
  }
  return {
    name,
    email,
    role,
    isAdmin,
    operatingPartnerManagers: (managers || [])?.map((manager) => Number(manager.id)),
    operatingPartnerDeals: (operatingPartnerDeals || [])?.map((deal) => Number(deal.id)),
    proxy: Number(proxy?.id),
    isOp: false,
  };
};

export const createEditUserPayload = ({
  managers,
  operatingPartnerDeals,
  role,
  isAdmin,
  isOp,
  isSelfApprovingEnabled,
  operatingPartners,
  dealOwnerDeals,
  proxyOperatingPartners,
  proxy,
  startDate,
  endDate,
  country,
}: IUserFileds): EditUserPayload => {
  if (role === UserRole.OPERATING_PARTNER) {
    return {
      role,
      isAdmin,
      isOp,
      operatingPartnerManagers: (managers || [])?.map((manager) => Number(manager.id)),
      operatingPartnerDeals: (operatingPartnerDeals || [])?.map((deal) => Number(deal.id)),
      proxy: Number(proxy?.id),
      startDate,
      endDate,
      country,
    };
  }
  if (role === UserRole.PROXY) {
    return {
      role,
      isAdmin,
      proxyOperatingPartners: proxyOperatingPartners?.map((partner) => Number(partner.id)),
      isOp: false,
    };
  }
  if (role === UserRole.OP_MANAGER) {
    if (isOp) {
      return {
        role,
        isAdmin,
        isOp,
        isSelfApprovingEnabled,
        operatingPartners: operatingPartners?.map((partner) => Number(partner.id)),
        operatingPartnerManagers: (managers || [])?.map((manager) => Number(manager.id)),
        operatingPartnerDeals: (operatingPartnerDeals || [])?.map((deal) => Number(deal.id)),
        proxy: Number(proxy?.id),
        startDate,
        endDate,
        country,
      };
    }
    return {
      role,
      isAdmin,
      isSelfApprovingEnabled,
      operatingPartners: operatingPartners?.map((partner) => Number(partner.id)),
      isOp: false
    };
  }
  if (role === UserRole.DEAL_OWNER) {
    return {
      role,
      isAdmin,
      dealOwnerDeals: dealOwnerDeals?.map((deal) => Number(deal.id)),
      isOp: false,
    };
  }
  if (role === UserRole.ACCOUNTANT) {
    return {
      role,
      isAdmin,
      isOp: false,
    };
  }
  return {
    role,
    isAdmin,
    operatingPartnerManagers: (managers || [])?.map((manager) => Number(manager.id)),
    operatingPartnerDeals: (operatingPartnerDeals || [])?.map((deal) => Number(deal.id)),
    proxy: Number(proxy?.id),
    isOp: false
  };
};
