import { useMutation } from 'react-query';
import { Http } from '../../services/Http';
import { MUTATION_KEYS } from '../constants';
import { useInvalidateDeals } from './useDeals';
import { useInvalidateUsers } from './useUsers';

async function deleteDeal(id: number): Promise<number> {
  const { data } = await Http.axios.delete<number>(`/deal/${id}`);
  return data;
}

export const useDeleteDeal = () => {
  const invalidateDeals = useInvalidateDeals();
  const invalidateUsers = useInvalidateUsers();

  return useMutation(deleteDeal, {
    mutationKey: MUTATION_KEYS.DELETE_DEAL_KEY,
    onSuccess: () => {
      invalidateDeals();
      invalidateUsers();
    },
  });
};
