import { User, UserWithTsPeriodData } from '../types';
import { useMemo } from 'react';

export function useUsersWithTsPeriodData(operatingPartners?: User[]) {
  const operatingPartnersWithMappedPeriods = useMemo(() => {
    if (!operatingPartners) return [];
    return operatingPartners.map((op) => {
      const newOp = { name: op.name, id: op.id };
      if (!op.monthlyTimesheets || !op.monthlyTimesheets.length) return newOp;
      op.monthlyTimesheets.forEach((ts) => {
        newOp[`${ts.year}-${ts.month}`] = ts;
      });
      return newOp as UserWithTsPeriodData;
    });
  }, [operatingPartners]);

  return operatingPartnersWithMappedPeriods;
}
