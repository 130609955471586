import { Box, Chip, Tooltip, TooltipProps, styled, tooltipClasses, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { MonthlyTimesheet, TimesheetStatus, User } from '../../../types';
import { useCallback } from 'react';

interface Props {
  children: JSX.Element;
  timesheet: MonthlyTimesheet;
  operatingPartner: User | undefined;
}

export const PendingStatusTooltip = ({ children, timesheet, operatingPartner }: Props) => {
  const { colors } = useTheme();

  const isPendingDealOwners = timesheet.status === TimesheetStatus.PENDING_DEAL_OWNERS;

  const timesheetStatus = useCallback(
    (id: number, role: 'manager' | 'dealOwner') => {
      if (role === 'manager') {
        if (timesheet.approvedByOpManagers?.some((manager) => manager.id === id))
          return {
            status: 'Approved',
            color: colors.textStatus.success,
          };
      }
      if (role === 'dealOwner') {
        if (timesheet.approvedByDealOwners?.some((dealOwner) => dealOwner.id === id))
          return {
            status: 'Approved',
            color: colors.textStatus.success,
          };
      }
      return {
        status: 'Pending Approval',
        color: colors.textStatus.active,
      };
    },
    [
      timesheet.approvedByOpManagers,
      timesheet.approvedByDealOwners,
      colors.textStatus.success,
      colors.textStatus.active,
    ]
  );

  if (!operatingPartner) return <></>;

  return (
    <StyledTooltip
      title={
        <Box paddingY={1}>
          <Wrapper>
            <Typography variant='body' color={colors.text.main}>
              OP
            </Typography>
            <Chip
              label={
                <Typography variant='body' color={colors.textStatus.success}>
                  Submitted
                </Typography>
              }
              style={{
                backgroundColor: colors.surfaceBackground.highlighted,
              }}
            />
          </Wrapper>
          <Wrapper>
            <Typography variant='body' color={colors.text.main}>
              Accountant
            </Typography>
            <Chip
              label={
                <Typography variant='body' color={colors.textStatus.success}>
                  Approved
                </Typography>
              }
              style={{
                backgroundColor: colors.surfaceBackground.highlighted,
              }}
            />
          </Wrapper>
          <BorderSection />
          <Wrapper>
            <Typography variant='body' color={colors.text.caption}>
              OP Manager(s)
            </Typography>
          </Wrapper>
          {operatingPartner.operatingPartnerManagers?.map((manager) => (
            <Wrapper key={manager.id}>
              <Typography variant='body' color={colors.text.main}>
                {manager.name}
              </Typography>
              <Chip
                label={
                  <Typography variant='body' color={timesheetStatus(manager.id, 'manager').color}>
                    {timesheetStatus(manager.id, 'manager').status}
                  </Typography>
                }
                style={{
                  backgroundColor: colors.surfaceBackground.highlighted,
                }}
              />
            </Wrapper>
          ))}
          {timesheet.timesheetRows
            .filter((row) => row.dealOwners?.length && row.avg > 0)
            .map((row) => (
              <>
                <BorderSection />
                <Wrapper key={row.id}>
                  <Typography variant='body' color={isPendingDealOwners ? colors.text.caption : colors.textAccent.disabled}>
                    {row.name} Deal Owners
                  </Typography>
                  {isPendingDealOwners && <Chip
                    label={
                      <Typography
                        variant='body'
                        color={
                          row.isApprovedByDealOwner
                            ? colors.textStatus.success
                            : colors.textStatus.active
                        }
                      >
                        {row.isApprovedByDealOwner ? 'Approved' : 'Pending Approval'}
                      </Typography>
                    }
                    style={{
                      backgroundColor: colors.surfaceBackground.highlighted,
                    }}
                  />}
                </Wrapper>
                {row.dealOwners?.map((dealOwner) => (
                  <Wrapper key={dealOwner.id}>
                    <Typography variant='body' color={isPendingDealOwners ? colors.text.main : colors.textAccent.disabled}>
                      {dealOwner.name}
                    </Typography>
                  </Wrapper>
                ))}
              </>
            ))}
        </Box>
      }
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -12],
              },
            },
          ],
        },
      }}
    >
      {children}
    </StyledTooltip>
  );
};

const BorderSection = styled('div')`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
  padding: 5px 0px;
  margin-bottom: 5px;
`;

const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
`;

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.surfaceStatus.active,
    color: theme.colors.text.main,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    minWidth: '300px',
    maxHeight: '400px',
    overflowY: 'scroll',
    borderRadius: '4px',
  },
}));
