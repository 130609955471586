import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QUERY_KEYS } from '../constants';
import { CreateUserPayload, User } from '../../types';
import { Http } from '../../services/Http';
import { AxiosError } from 'axios';

async function fetchUsers(): Promise<User[]> {
  // return await mockHttpRequest<User[]>(mockedUsers, 1000);
  const { data } = await Http.axios.get<User[]>(`/user`);
  return data;
}

export const useUsers = () => {
  return useQuery(QUERY_KEYS.USERS, () => fetchUsers(), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
};

export const useInvalidateUsers = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.USERS,
    });
  };
};

export async function fetchUserByEmail(email: string): Promise<User | null> {
  try {
    const response = await Http.axios.get<User>(`user/email/${email}`);
    return response.data;
  } catch (error) {
    if ((error as AxiosError)?.response?.status === 404) return null;
    throw error;
  }
}

async function createUser(payload: Partial<CreateUserPayload>): Promise<User> {
  const { data } = await Http.axios.post<Partial<CreateUserPayload>, User>(`/user`, payload);
  return data;
}

export function useCreateUser() {
  return useMutation(createUser, {
    onError: (error) => {
      console.error({ error });
    },
  });
}
