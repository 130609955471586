import { Box, Chip, useTheme } from '@mui/material';
import { CellContext } from '@tanstack/table-core';
import { useMemo } from 'react';
import { Typography } from '../../../components/Typography/Typography';
import { Timesheet, TimesheetStatusLabel } from '../../../types';
import { getWeekStatus, formatWeekName } from '../../../utils/formatters';
import { getWeeklyTimesheetWeekStart } from '../../../utils/getWeeklyTimesheetWeekStart';

type Props = {
  info: CellContext<Timesheet, string>;
};
export function WeekCell({ info }: Props) {
  const { colors } = useTheme();

  const weekStart = useMemo(() => {
    return getWeeklyTimesheetWeekStart(info.row.original);
  }, [info.row.original]);

  const weekLength = info.row.original.weekDates.length + info.row.original.holidays.length;
  const statusValue = getWeekStatus(weekStart);

  const isTSRejected = info.row.original.status === TimesheetStatusLabel.REJECTED;
  const isNewWeek = statusValue === 'New';

  const borderColor = useMemo(() => {
    if (isTSRejected) return colors.iconStatus.error;
    return isNewWeek ? colors.textStatus.active : colors.textStatus.warning;
  }, [isTSRejected, isNewWeek, colors]);

  const statusColor = useMemo(() => {
    return isNewWeek ? colors.textStatus.active : colors.textStatus.warning;
  }, [isNewWeek, colors]);

  const cellValue = formatWeekName(weekStart, weekLength);

  return (
    <Box
      borderLeft={'4px solid'}
      borderColor={borderColor}
      maxHeight={48}
      minHeight={48}
      display={'flex'}
      alignItems={'center'}
      paddingLeft={'16px'}
      gap={'8px'}
    >
      <Typography variant='body' color={colors.text.caption}>
        {cellValue}
      </Typography>
      <Chip
        style={{
          backgroundColor: colors.surfaceStatus.noAction,
          minWidth: 'auto',
          height: '28px',
        }}
        label={
          <Typography variant='body' color={statusColor}>
            {statusValue}
          </Typography>
        }
      />
    </Box>
  );
}

export default WeekCell;
