import { useTheme } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { TableHeaderCell } from '../../../components/Table/CellRenderers/TableHeaderCell';
import { Typography } from '../../../components/Typography/Typography';
import { Deal, SelectItem } from '../../../types';
import { OperatingPartnersCell } from '../components/OperatingPartnersCell';
import { DealOwnersCell } from '../components/DealOwnersCell';

const columnHelper = createColumnHelper<Deal>();

export const useDealsTableColumns = () => {
  const { colors } = useTheme();

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        cell: (info) => (
          <Typography variant='body' color={colors.text.caption}>
            {info.getValue()}
          </Typography>
        ),
        header: () => <TableHeaderCell text='ID' />,
        meta: {
          width: '45px',
          maxWidth: '50px',
          minWidth: '45px',
          tdStyles: { paddingLeft: '16px', borderRight: `none` },
          thStyles: { borderRight: `none` },
        },
      }),
      columnHelper.accessor('name', {
        cell: (info) => (
          <Typography variant='h4' color={colors.text.main}>
            {info.getValue()}
          </Typography>
        ),
        header: () => <TableHeaderCell text='Deal' />,
        filterFn: (row, id, filterValue) => {
          const isIssuerNameMatched = (row.original.name ?? '')
            .toLowerCase()
            .includes(filterValue.toLowerCase());
          const isOwnerMatched = !!row.original.dealOwners?.some((owner) => {
            return owner.name.toLowerCase().includes(filterValue.toLowerCase());
          });

          const isOPMatched = !!row.original.operatingPartners?.some((partner) => {
            return partner.name.toLowerCase().includes(filterValue.toLowerCase());
          });

          return isIssuerNameMatched || isOwnerMatched || isOPMatched;
        },
        meta: {
          width: '200px',
          maxWidth: '200px',
          minWidth: '200px',
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
        },
      }),
      columnHelper.accessor('dealOwners', {
        cell: (info) => <DealOwnersCell deals={info.row.original.dealOwners} />,
        header: () => <TableHeaderCell text='Deal Owners' />,
        enableSorting: false,
        meta: {
          width: '400px',
          maxWidth: '400px',
          minWidth: '400px',
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
        },
      }),
      columnHelper.accessor('operatingPartners', {
        cell: (info) => (
          <OperatingPartnersCell operatingPartners={info.row.original.operatingPartners} />
        ),
        header: () => <TableHeaderCell text='Operating Partners' />,
        enableSorting: false,
        filterFn: (row, id, filterValue: SelectItem[]) => {
          if (!filterValue.length) return true;
          return filterValue.some(
            (user) => user.id === row.original.operatingPartners?.find((op) => op.id === user.id)?.id
          );
        },
        meta: {
          width: '600px',
          maxWidth: '600px',
          minWidth: '600px',
          tdStyles: { borderRight: `none` },
          thStyles: { borderRight: `none` },
        },
      }),
    ],
    [colors]
  );

  return columns;
};
