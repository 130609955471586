import { ModalComponent } from '../../../components/ModalComponent/ModalComponent';
import { Deal, LoadingId } from '../../../types';

import { styled, useTheme } from '@mui/material';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info-blue.svg';
import { Typography } from '../../../components/Typography/Typography';
import { useLoadingBar } from '../../../hooks/useLoadingBar';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { useDeleteDeal } from '../../../queries/hooks/useDeleteDeal';

type Props = {
  deleteDealOpen: boolean;
  toggleDeleteDeal: () => void;
  deal: Deal;
};

export function DeleteDealModal({ deal, deleteDealOpen, toggleDeleteDeal }: Props) {
  const { colors } = useTheme();
  const { pushSuccessToast, pushErrorToast } = useToastMessage();
  const { startLoading, stopLoading } = useLoadingBar();

  const dealName = deal?.name;
  const { mutate: onDeleteDeal, isLoading: deleteInProgress } = useDeleteDeal();

  const handleDeleteDeal = async () => {
    startLoading(LoadingId.deleteDeal);
    onDeleteDeal(deal.id, {
      onSuccess: () => {
        pushSuccessToast({
          title: 'Deal is Deleted',
          message: `Deal ${dealName} has been successfully deleted.`,
        });
        toggleDeleteDeal();
      },
      onError: () => {
        pushErrorToast({ message: 'Failed to delete deal' });
      },
      onSettled: () => {
        stopLoading(LoadingId.deleteDeal);
      },
    });
  };

  return (
    <ModalComponent
      isOpen={deleteDealOpen}
      onClose={toggleDeleteDeal}
      actionButtonsProps={{
        isSubmitDisabled: deleteInProgress,
        onSubmit: handleDeleteDeal,
        onCancel: toggleDeleteDeal,
        submitText: 'Delete a Deal',
        submitBtnStyle: {
          background: deleteInProgress ? undefined : colors.iconStatus.error,
        },
      }}
      title={`Delete ${dealName} deal`}
      top='80px'
    >
      <InfoWrapper>
        <InfoIcon />
        <Typography variant='body' color={colors.textAccent.default}>
          {`Are you sure you want to delete deal ${dealName}?`}
        </Typography>
      </InfoWrapper>
    </ModalComponent>
  );
}

const InfoWrapper = styled('div')`
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.surfaceBackground.highlighted};
  border-radius: 2px;
  display: flex;
  gap: 8px;
  margin-bottom: 14px;
`;
