import { styled } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { LoadingBar } from '../../components/LoadingBar/LoadingBar';
import { ToastMessage } from '../../components/ToastMessage/ToastMessage';
import {
  MAIN_SIDEBAR_MENU_EXPANDED_WIDTH,
  MAIN_SIDEBAR_MENU_WIDTH,
} from '../../constants/layoutSizes';
import {
  configToastMessageState,
  isOpenToastMessageState,
  isUserLoadingState,
  sidebarMenuPinnedState,
  userState,
} from '../../state/UIState';
import { SidebarMenu } from './SidebarMenu';
import { motion, useAnimation } from 'framer-motion';
import { Outlet } from 'react-router';
import { Unauthorized } from '../Unauthorized/Unauthorized';
import useInitUser from '../../hooks/useInitUser';

const Wrapper = styled('div')`
  display: flex;
  height: 100vh;
`;

const Placeholder = styled('div')`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.surfaceBackground.bg1};
`;

const Content = styled(motion.div)`
  flex: 1;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.surfaceBackground.bg1};
  padding-left: ${MAIN_SIDEBAR_MENU_WIDTH}px;
`;

const LoadingBarWrapper = styled('div')`
  width: calc(100% - ${MAIN_SIDEBAR_MENU_WIDTH}px);
  position: absolute;
  top: 0;
`;

export const Layout = () => {
  useInitUser();
  const [config] = useAtom(configToastMessageState);
  const [isOpen, setIsOpen] = useAtom(isOpenToastMessageState);
  const [isSideMenuPinned, setIsSideMenuPinned] = useAtom(sidebarMenuPinnedState);
  const isLoadingUser = useAtomValue(isUserLoadingState);
  const user = useAtomValue(userState);

  const controls = useAnimation();

  const onPinToggle = () => {
    if (isSideMenuPinned) {
      setIsSideMenuPinned(false);
      controls.start({
        paddingLeft: ` ${MAIN_SIDEBAR_MENU_WIDTH}px`,
        transition: { duration: 0.3 },
      });
      return;
    }

    setIsSideMenuPinned(true);

    controls.start({
      paddingLeft: ` ${MAIN_SIDEBAR_MENU_EXPANDED_WIDTH}px`,
      transition: { duration: 0.3 },
    });
  };

  if (isLoadingUser) return <Placeholder />;

  return (
    <Wrapper>
      <SidebarMenu onPinToggle={onPinToggle} isPinned={isSideMenuPinned} />
      <Content animate={controls}>
        {config && (
          <ToastMessage {...config} isOpen={isOpen} handleClose={() => setIsOpen(false)} />
        )}
        <LoadingBarWrapper>
          <LoadingBar />
        </LoadingBarWrapper>
        {user && !user.role ? <Unauthorized /> : <Outlet />}
      </Content>
    </Wrapper>
  );
};
