import { useMutation } from 'react-query';
import { Http } from '../../services/Http';
import { MUTATION_KEYS } from '../constants';
import { useInvalidateHolidays } from './useHolidays';
import { Holiday } from '../../types';

async function deleteHoliday(multidayId: string): Promise<Holiday[]> {
  const { data } = await Http.axios.delete<Holiday[]>(`/holiday/${multidayId}`);
  return data;
}

export const useDeleteHoliday = () => {
  const invalidateHolidays = useInvalidateHolidays();

  return useMutation(deleteHoliday, {
    mutationKey: MUTATION_KEYS.DELETE_HOLIDAY_KEY,
    onSuccess: () => {
        invalidateHolidays();
    },
  });
};
