import { styled, useTheme } from '@mui/material';
import { Typography } from '../../Typography/Typography';
import { UserAvatar } from '../../UserAvatar/UserAvatar';

interface Props {
  name: string;
  avatarNameStyle?: React.CSSProperties;
  avatarBoxStyle?: React.CSSProperties;
  avatarLetterStyle?: React.CSSProperties;
}

const Wrapper = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const AvatarCell = ({ name, avatarNameStyle, avatarBoxStyle, avatarLetterStyle }: Props) => {
  const { colors } = useTheme();

  return (
    <Wrapper>
      <UserAvatar
        name={name}
        size='medium'
        style={avatarBoxStyle}
        lettersStyle={avatarLetterStyle}
      />
      <Typography variant='h4' color={colors.text.main} style={avatarNameStyle}>
        {name}
      </Typography>
    </Wrapper>
  );
};
