import { IconButton, Menu, MenuItem, styled, useTheme } from '@mui/material';
import { Table as TTable, Row as Trow, flexRender } from '@tanstack/react-table';
import { AnimatePresence } from 'framer-motion';
import { Fragment, MouseEvent, useCallback, useState } from 'react';
import { ReactComponent as MoreIcon } from '../../assets/icons/more-vertical.svg';
import { ReactComponent as SortDescIcon } from '../../assets/icons/sort-desc.svg';
import { MAIN_SIDEBAR_MENU_WIDTH } from '../../constants/layoutSizes';
import { ColumnMeta } from '../../types';
import { Typography } from '../Typography/Typography';
import { TableDataCell } from './TableDataCell';
import { TableRow } from './TableRow';

interface Props {
  table: TTable<any>;
  onRowClick?: (row: Trow<any>) => void;
  subComponent?: React.FC<{ row: Trow<any> }>;
  actions?: ActionOption[];
  onActionClick?: (actionId: string, row: Trow<any> | null) => void;
  tHeaderStyle?: React.CSSProperties;
  headerWrapperStyle?: React.CSSProperties;
  tableStyle?: React.CSSProperties;
  tdStyle?: React.CSSProperties;
  trStyle?: React.CSSProperties;
  headTrStyle?: React.CSSProperties;
  thStyle?: React.CSSProperties;
  height?: string;
  maxWidth?: string;
  disableAnimation?: boolean;
  disableRowHover?: boolean;
  disableLayoutAnimation?: boolean;
  disableLazyLoading?: boolean;
  hasFooter?: boolean;
  headerZIndex?: number;
  wrapperStyle?: React.CSSProperties;
  isLastColumnSticky?: boolean;
  className?: string;
}

interface ActionOption {
  id: string;
  value: string;
  icon: JSX.Element;
  getIsDisabled?: (row: Trow<any> | null) => boolean;
}

const Wrapper = styled('div')<{ height: string; maxWidth?: string }>`
  // viewport width minus sidebar width minus padding
  max-width: ${({ maxWidth }) =>
    maxWidth ? maxWidth : `calc(100vw - ${MAIN_SIDEBAR_MENU_WIDTH}px - 32px)`};
  // overflow: auto;
  max-height: ${({ height }) => height};
  height: ${({ height }) => height};
  border-top: none;
  border-radius: 0 0 4px 4px;
`;
const StyledTable = styled('table')<{ isLastColumnSticky: boolean }>`
  width: 100%;
  border-spacing: 0 0;
  border-collapse: separate;
  min-width: 840px;
  th,
  td {
    padding: 0 16px;
    border-right: 1px solid ${({ theme }) => theme.colors.border.default};
  }
  tr td:last-of-type,
  tr th:last-of-type {
    border-left: 1px solid ${({ theme }) => theme.colors.border.default};
    border-right: none;
    padding-right: 0;
  }

  tr.footer {
    height: 11px;
    background-color: ${({ theme }) => theme.colors.surfaceBackground.bg1};
    td:first-of-type {
      border-left: none;
    }
  }
  tr.sub-component > td {
    padding: 16px;
  }

  th:last-of-type {
    position: ${({ isLastColumnSticky }) => (isLastColumnSticky ? 'sticky' : 'relative')};
    right: -1px;
    z-index: 10;
  }
  td:last-of-type {
    position: ${({ isLastColumnSticky }) => (isLastColumnSticky ? 'sticky' : 'relative')};
    right: -1px;
    z-index: 9;
  }
  th:first-of-type {
    position: sticky;
    left: 0;
    z-index: 10;
  }
  td:first-of-type {
    position: sticky;
    left: 0;
    z-index: 9;
  }
  tbody {
    tr:nth-of-type(odd) {
      th,
      td {
        background-color: ${({ theme }) => theme.colors.surfaceBackground.bg2};
      }
    }
    tr:nth-of-type(even) {
      th,
      td {
        background-color: ${({ theme }) => theme.colors.surfaceBackground.bg1};
      }
    }
  }
`;
const Thead = styled('thead')<{ headerZIndex?: number }>`
  tr:first-of-type {
    position: sticky;
    top: 0;
    z-index: ${({ headerZIndex }) => headerZIndex ?? 10};
    th {
      border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
      background-color: ${({ theme }) => theme.colors.surfaceBackground.bg1};
    }
  }
`;
const Tbody = styled('tbody')`
  background-color: ${({ theme }) => theme.colors.surfaceBackground.bg1};
  padding: 0 19px;
`;

const Tfooter = styled('tfoot')`
  background-color: ${({ theme }) => theme.colors.surfaceStatus.noAction};
`;

const Th = styled('th')<{ width?: string; minWidth?: string; maxWidth?: string }>`
  padding-right: 16px;
  &:first-of-type {
    padding-left: 16px;
  }
  width: ${({ width }) => (width ? width : 'auto')};
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth};` : '')}
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : '')}
`;

const HeaderWrapper = styled('div')<{ isSortable: boolean; align?: 'right' | 'left' }>`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: ${({ align }) => (align === 'right' ? 'flex-end' : 'flex-start')};
  ${({ isSortable }) => (isSortable ? 'cursor: pointer;' : '')}
`;
const SortIconWrapper = styled('div')<{ align?: 'right' | 'left' }>`
  width: 16px;
  order: ${({ align }) => (align === 'right' ? 1 : 2)};
`;

const HeaderTextWrapper = styled('div')<{ align?: 'right' | 'left' }>`
  order: ${({ align }) => (align === 'right' ? 2 : 1)};
`;

const MoreButtonWrapper = styled('div')`
  position: relative;
  top: 0;
`;

const MenuItemDisabledOverlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.surfaceBackground.bg1};
  opacity: 0.5;
  z-index: 1;
  cursor: not-allowed;
`;

export const TableV1 = ({
  table,
  onRowClick,
  actions,
  onActionClick,
  tHeaderStyle,
  tableStyle,
  tdStyle,
  trStyle,
  thStyle,
  headTrStyle,
  height = '640px',
  maxWidth,
  disableAnimation = false,
  disableRowHover = false,
  disableLayoutAnimation = false,
  disableLazyLoading = false,
  subComponent: SubComponent,
  hasFooter = false,
  headerWrapperStyle,
  headerZIndex,
  wrapperStyle,
  isLastColumnSticky = true,
  className
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<Trow<any> | null>(null);
  const open = Boolean(anchorEl);
  const { colors } = useTheme();
  const onRowSelect = useCallback(
    (row: Trow<any>) => {
      if (onRowClick) onRowClick(row);
      if (row.getCanSelect()) {
        if (row.original.disabled) return;
        // not allowed unselecting for single select for now.
        if (row.getIsSelected() && !row.getCanMultiSelect()) return;
        const toggleSelected = row.getToggleSelectedHandler();
        toggleSelected(row);
      }
    },
    [onRowClick]
  );

  const handleClick = useCallback((event: MouseEvent<HTMLElement>, row: Trow<any>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  }, []);

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setTimeout(() => {
      setSelectedRow(null);
    }, 300);
  };
  const onMenuItemClick = useCallback(
    (actionId: string) => {
      onActionClick ? onActionClick(actionId, selectedRow) : null;
      handleCloseMenu();
    },
    [onActionClick, selectedRow]
  );

  return (
    <Wrapper className={className} height={height} maxWidth={maxWidth} style={{ ...wrapperStyle }}>
      <StyledTable style={tableStyle} isLastColumnSticky={isLastColumnSticky}>
        <Thead style={tHeaderStyle} headerZIndex={headerZIndex}>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow
              key={headerGroup.id}
              disableAnimation={disableAnimation}
              disableLayoutAnimation={disableLayoutAnimation}
              style={{ ...headTrStyle, height: '36px' }}
            >
              {headerGroup.headers.map((header) => {
                return (
                  <Th
                    key={header.id}
                    width={(header.column.columnDef.meta as ColumnMeta)?.width}
                    minWidth={(header.column.columnDef.meta as ColumnMeta)?.minWidth}
                    maxWidth={(header.column.columnDef.meta as ColumnMeta)?.maxWidth}
                    style={{
                      ...thStyle,
                      ...(header.column.columnDef.meta as ColumnMeta)?.thStyles,
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <HeaderWrapper
                        className='header-wrapper'
                        isSortable={header.column.getCanSort()}
                        onClick={header.column.getToggleSortingHandler()}
                        align={(header.column.columnDef.meta as ColumnMeta)?.alignHeader}
                        style={{
                          width: (header.column.columnDef.meta as ColumnMeta)?.headerWrapperWidth
                            ? (header.column.columnDef.meta as ColumnMeta)?.headerWrapperWidth
                            : 'auto',
                          ...headerWrapperStyle,
                          ...(header.column.columnDef.meta as ColumnMeta)?.headerWrapperStyles,
                        }}
                      >
                        {header.column.getIsSorted() ? (
                          <SortIconWrapper
                            align={(header.column.columnDef.meta as ColumnMeta)?.alignHeader}
                          >
                            {{
                              asc: <SortDescIcon style={{ transform: 'rotate(180deg)' }} />,
                              desc: <SortDescIcon />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </SortIconWrapper>
                        ) : null}
                        <HeaderTextWrapper
                          style={{
                            width: header.column.getIsSorted() ? 'auto' : '100%',
                            textAlign: 'left',
                          }}
                          align={(header.column.columnDef.meta as ColumnMeta)?.alignHeader}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </HeaderTextWrapper>
                      </HeaderWrapper>
                    )}
                  </Th>
                );
              })}
              {actions && <Th />}
            </TableRow>
          ))}
        </Thead>
        <AnimatePresence>
          <Tbody key={'tbody'}>
            {table.getRowModel().rows.length === 0 && (
              <TableRow className='no-data'>
                <TableDataCell
                  colSpan={table.getHeaderGroups()[0].headers.length}
                  disableLazyLoading={disableLazyLoading}
                  style={{ padding: '0 16px' }}
                >
                  <Typography variant='body' color={colors.text.main}>
                    No data was found.
                  </Typography>
                </TableDataCell>
              </TableRow>
            )}
            {table.getRowModel().rows.map((row) => (
              <Fragment key={row.id}>
                <TableRow
                  disableLayoutAnimation={disableLayoutAnimation}
                  disableHover={disableRowHover}
                  disabled={
                    (row.getCanSelect() || row.getCanMultiSelect()) && row.original.disabled
                  }
                  onClick={() => onRowSelect(row)}
                  selectable={!!onRowClick}
                  className={row.getIsSelected() && !row.getCanMultiSelect() ? 'selected-row' : ''}
                  style={trStyle}
                  disableAnimation={disableAnimation}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableDataCell
                      style={{
                        ...tdStyle,
                        ...(cell.column.columnDef.meta as ColumnMeta)?.tdStyles,
                      }}
                      key={cell.id}
                      width={(cell.column.columnDef.meta as ColumnMeta)?.width}
                      minWidth={(cell.column.columnDef.meta as ColumnMeta)?.minWidth}
                      maxWidth={(cell.column.columnDef.meta as ColumnMeta)?.maxWidth}
                      disableLazyLoading={disableLazyLoading}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableDataCell>
                  ))}
                  {actions && (
                    <TableDataCell style={tdStyle} disableLazyLoading={disableLazyLoading}>
                      <MoreButtonWrapper>
                        <IconButton
                          onClick={(e) => handleClick(e, row)}
                          style={{
                            borderRadius: '4px',
                          }}
                        >
                          <MoreIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                          onClose={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            e.stopPropagation();
                            handleCloseMenu();
                          }}
                          PaperProps={{
                            style: {
                              width: '145px',
                              transform: 'translateX(20px) ',
                              boxShadow: 'none',
                              border: '1px solid #D8DEE3',
                              borderRadius: '4px',
                            },
                          }}
                        >
                          {actions.map((c) => (
                            <MenuItem
                              key={c.id}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (c.getIsDisabled && c.getIsDisabled(selectedRow)) return;
                                onMenuItemClick(c.id);
                              }}
                              style={{ width: '100%', position: 'relative' }}
                            >
                              {c.getIsDisabled && c.getIsDisabled(selectedRow) && (
                                <MenuItemDisabledOverlay />
                              )}
                              {c.icon}
                              <Typography
                                variant='body'
                                color={colors.text.main}
                                style={{ marginLeft: '4px' }}
                              >
                                {c.value}
                              </Typography>
                            </MenuItem>
                          ))}
                        </Menu>
                      </MoreButtonWrapper>
                    </TableDataCell>
                  )}
                </TableRow>
                {Boolean(row.getIsExpanded() && row.getCanExpand()) && (
                  <TableRow disableHover={disableRowHover} className='sub-component'>
                    <TableDataCell
                      colSpan={table.getHeaderGroups()[0].headers.length}
                      disableLazyLoading
                      style={{ padding: 0 }}
                    >
                      {SubComponent && <SubComponent row={row} />}
                    </TableDataCell>
                  </TableRow>
                )}
              </Fragment>
            ))}
          </Tbody>
          {hasFooter && (
            <Tfooter key={'tfooter'}>
              {table.getFooterGroups().map((footerGroup, index) => (
                <TableRow
                  key={`footer-group-${footerGroup.id} ${index}`}
                  className={`footer-row`}
                  disableHover={disableRowHover}
                  style={{
                    height: 56,
                  }}
                >
                  {footerGroup.headers.map((header, index) => {
                    return (
                      <TableDataCell
                        key={`${header.id} ${index}`}
                        colSpan={header.colSpan}
                        style={{
                          border: 'none',
                        }}
                      >
                        {header.isPlaceholder ? null : (
                          <div key={`${header.id} ${index}`}>
                            {flexRender(header.column.columnDef.footer, header.getContext())}
                          </div>
                        )}
                      </TableDataCell>
                    );
                  })}
                </TableRow>
              ))}
            </Tfooter>
          )}
        </AnimatePresence>
      </StyledTable>
    </Wrapper>
  );
};
