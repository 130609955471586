import { useTheme } from '@mui/material';
import { Column, Table } from '@tanstack/react-table';
import { Typography } from '../../../components/Typography/Typography';
import { WeekdayTimesheetTableData } from '../../../types';
import { useMemo } from 'react';
import { formatDecimalNumber } from '../../../utils/formatters';

function TotalCellWeekdayTable(props: {
  table: Table<WeekdayTimesheetTableData>;
  column: Column<WeekdayTimesheetTableData>;
}) {
  const { colors } = useTheme();

  const total = useMemo(() => {
    const result = props.table
      .getFilteredRowModel()
      .rows.reduce((total, row) => total + Number(row.getValue(props.column.id)), 0);
    if (result >= 99 && result <= 101) return 100;
    return result;
  }, [props.column.id, props.table]);

  return (
    <Typography variant='body' color={colors.text.main}>
      {`${formatDecimalNumber(total, 2)}%`}
    </Typography>
  );
}

export default TotalCellWeekdayTable;
