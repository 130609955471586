import { useQuery, useQueryClient } from 'react-query';
import { Http } from '../../services/Http';
import { QUERY_KEYS } from '../constants';
import { WeeklyTimesheet } from '../../types';

async function fetchTimesheet(id: number): Promise<WeeklyTimesheet> {
  const { data } = await Http.axios.get<WeeklyTimesheet>(`/timesheet/${id}`);
  return data;
}

export const useSingleTimesheet = ({ id, enabled = !!id }) => {
  return useQuery([QUERY_KEYS.SINGLE_TIMESHEET, id], () => fetchTimesheet(id), {
    staleTime: 0,
    enabled: enabled,
    refetchInterval: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
};

export const useInvalidateSingleTimesheet = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.SINGLE_TIMESHEET,
    });
  };
};
