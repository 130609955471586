import { useQuery, useQueryClient } from 'react-query';
import { Http } from '../../services/Http';
import { MonthlyTimesheet } from '../../types';
import { QUERY_KEYS } from '../constants';

async function fetchMonthlySingleTimesheet(id: number): Promise<MonthlyTimesheet> {
  const { data } = await Http.axios.get<MonthlyTimesheet>(`/monthly-timesheet/${id}`);
  return data;
}

export const useMonthlySingleTimesheet = ({ id, enabled = !!id }) => {
  return useQuery(
    [QUERY_KEYS.MONTHLY_SINGLE_TIMESHEET, id],
    () => fetchMonthlySingleTimesheet(id),
    {
      staleTime: Infinity,
      enabled: enabled,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    }
  );
};

export const useInvalidateMonthlySingleTimesheet = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.MONTHLY_SINGLE_TIMESHEET,
    });
  };
};
