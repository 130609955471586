import { useTheme } from '@mui/material';
import { ROUTES } from '../../constants/routes';
import { PageHeader } from '../layout/PageHeader';
import { ContentWrapper } from '../layout/styled';
import { SubmitedTimesheeetsTable } from './components/SubmitedTimesheeetsTable';

export const OpManagerSubmittedTimesheets = () => {
  const { colors } = useTheme();
  return (
    <>
      <PageHeader
        title='Submitted Timesheets'
        description=''
        breadcrumbs={[
          {
            title: 'All timesheet',
            url: `/${ROUTES.OP_MANAGER_ALL_TIMESHEETS}`,
            color: colors.textAccent.default,
          },
          {
            title: 'Submitted Timesheets',
          },
        ]}
      />
      <ContentWrapper>
        <SubmitedTimesheeetsTable />
      </ContentWrapper>
    </>
  );
};
