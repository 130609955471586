import { Autocomplete, TextField, SxProps, styled, Button } from '@mui/material';
import { useTheme } from '@mui/material';
import { SelectItem } from '../../types';
import { ReactComponent as ClearIcon } from '../../assets/icons/close-grey.svg';
import { Typography } from '../Typography/Typography';
import { useMemo, useRef, useState } from 'react';
import { useIcons } from '../../hooks/useIcons';

interface Props {
  options: SelectItem[];
  hideClearIcon?: boolean;
  componentsProps?: {
    clearIndicator?: object;
    paper?: object;
    popper?: object;
    popupIndicator?: object;
  };
  onChange: (e: React.SyntheticEvent, newValue: SelectItem | null) => void;
  value: SelectItem | null | undefined;
  style?: React.CSSProperties;
  listboxStyle?: React.CSSProperties;
  disablePortal?: boolean;
  fieldName?: string;
  fieldPlaceholder?: string;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  openOnfocus?: boolean;
  blurOnSelect?: boolean;
  autofocus?: boolean;
  sx?: SxProps;
  disabled?: boolean;
  inputVariant?: 'outlined' | 'filled' | 'standard';
  className?: string;
  noOptionsAction?: (value: string) => void;
  noOptionsText?: string;
  highlightedOptionsLabel?: string;
  noHighlightedOptionsLabel?: string;
}

const StyleContainer = styled('div')`
  & .MuiAutocomplete-popup {
    z-index: 999999 !important;
  }

  &&& .MuiInputBase-root.Mui-focused {
    input {
      color: ${({ theme }) => theme.colors.text.main};
    }
    z-index: 1000;
  }
  &&& .MuiInputBase-root .MuiInputBase-input {
    color: ${({ theme }) => theme.colors.text.main};
  }
  .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 0 0 9px;
  }
  .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 6px 4px 6px 6px;
  }
  .MuiAutocomplete-listbox {
    padding: 4px 0;
  }
  .MuiFormControl-root.MuiTextField-root:hover .MuiButtonBase-root.MuiAutocomplete-clearIndicator {
    visibility: visible;
  }
  .MuiButtonBase-root.MuiAutocomplete-clearIndicator {
    visibility: hidden;
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-uwj8o8-MuiAutocomplete-root
    .MuiOutlinedInput-root {
    padding-right: 48px;
  }
`;
const OptionWrapper = styled('li')`
  &&&.MuiAutocomplete-option {
    padding: 6px 6px 6px 8px;
    height: 32px;
    margin: 4px 8px;
    border-radius: 4px;
    &.Mui-focused {
      background-color: ${({ theme }) => theme.colors.surfaceInteraction.selectedLight};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.surfaceInteraction.selectedLight};
    }
    &[aria-selected='true'] {
      background-color: ${({ theme }) => theme.colors.surfaceInteraction.selectedLight};
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const GroupWrapper = styled('div')`
  padding: 12px 8px;
  margin: 0 8px;
`;

const GroupOptionsWrapper = styled('div')``;

export function SingleSelect(props: Props): JSX.Element {
  const { colors } = useTheme();
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const { PlusIcon } = useIcons();

  const {
    fieldName,
    disabled = false,
    fieldPlaceholder,
    disablePortal = true,
    openOnfocus = true,
    autofocus = false,
    hideClearIcon = false,
    blurOnSelect = false,
    inputVariant = 'outlined',
    listboxStyle,
    sx,
    className,
    noOptionsAction,
    noOptionsText,
    options,
    highlightedOptionsLabel,
    noHighlightedOptionsLabel,
    ...rest
  } = props;

  const noOptionsActionHandler = () => {
    inputRef.current?.blur();
    noOptionsAction && noOptionsAction(inputValue);
  };

  const optionsWithHighlightedOptionsOnTop = useMemo(() => {
    //sort options. First options with isHighlighted = true, then options with isHighlighted = false
    const sortedOptions = options.sort((a, b) => {
      if (a.isHighlighted && !b.isHighlighted) {
        return -1;
      }
      if (!a.isHighlighted && b.isHighlighted) {
        return 1;
      }
      return 0;
    });
    return sortedOptions;
  }, [options]);

  const hasHighlightedOptions = useMemo(() => {
    return options.some((option) => option.isHighlighted);
  }, [options]);

  const areAllOptionsHighlighted = useMemo(() => {
    return !options.some((option) => !option.isHighlighted);
  }, [options]);

  return (
    <StyleContainer>
      <Autocomplete
        clearIcon={!hideClearIcon ? <ClearIcon /> : null}
        className={className}
        autoHighlight
        groupBy={
          hasHighlightedOptions
            ? (o) =>
                o.isHighlighted ? highlightedOptionsLabel ?? '' : noHighlightedOptionsLabel ?? ''
            : undefined
        }
        onInputChange={(_, value) => setInputValue(value)}
        noOptionsText={
          noOptionsAction && (options.length || inputValue) ? (
            <Button
              variant='text'
              onClick={noOptionsActionHandler}
              startIcon={<PlusIcon />}
              sx={{ '.MuiButton-startIcon': { marginRight: '0px' } }}
            >
              <Typography
                variant='subtitle1'
                color={colors.textStatus.active}
                style={{ marginBottom: '-2px' }}
              >
                {noOptionsText}
              </Typography>
            </Button>
          ) : (
            <Typography
              variant='subtitle1'
              color={colors.text.caption}
              style={{ marginBottom: '-2px' }}
            >
              {'No options'}
            </Typography>
          )
        }
        disabled={disabled}
        ListboxProps={{
          style: { height: '200px', border: `1px solid ${colors.border.default}`, ...listboxStyle },
        }}
        getOptionLabel={function (option: SelectItem): string {
          return String(option?.value) ?? '';
        }}
        openOnFocus={openOnfocus}
        blurOnSelect={blurOnSelect}
        renderInput={(params) => (
          <TextField
            {...params}
            autoComplete='off'
            inputRef={inputRef}
            fullWidth
            name={fieldName}
            variant={inputVariant}
            placeholder={fieldPlaceholder}
            autoFocus={autofocus}
          />
        )}
        renderGroup={({ children, group, key }) => (
          <div key={key}>
            {group && (
              <GroupWrapper>
                <Typography variant='srOnly' color={colors.text.caption}>
                  {group}
                </Typography>
              </GroupWrapper>
            )}
            <GroupOptionsWrapper
              style={{
                borderBottom:
                  group === highlightedOptionsLabel && !areAllOptionsHighlighted
                    ? `1px solid ${colors.border.default}`
                    : 'none',
              }}
            >
              {children}
            </GroupOptionsWrapper>
          </div>
        )}
        renderOption={(props, option) => {
          return (
            <OptionWrapper {...props} key={option.id}>
              <Typography variant='body' color={colors.text.secondary}>
                {option?.value ?? ''}
              </Typography>
            </OptionWrapper>
          );
        }}
        disablePortal={disablePortal}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        sx={
          sx ?? {
            "& button[title='Clear'], & button[title='Open'], & button[title='Close']": {
              visibility: 'visible',
              color: colors.icon.default,
            },
          }
        }
        options={optionsWithHighlightedOptionsOnTop}
        {...rest}
      />
    </StyleContainer>
  );
}
