import { Box, Chip, Icon, IconButton, styled, useTheme } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import { createColumnHelper } from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';
import { TableHeaderCell } from '../../../components/Table/CellRenderers/TableHeaderCell';
import { Typography } from '../../../components/Typography/Typography';
import { CheckboxComponent } from '../components/CheckboxComponent';
import { ReactComponent as USAFlag } from '../../../assets/icons/usa-flag.svg';
import { ReactComponent as UKFlag } from '../../../assets/icons/uk-flag.svg';
import { ReactComponent as MoreIcon } from '../../../assets/icons/more-vertical.svg';
import ActionsMenu from '../components/ActionsMenu';
import { Holiday, SelectItem } from '../../../types';
import { formatLongDateRange } from '../../../utils/formatters';
import {
  addHolidayModalOpen,
  addHolidayModalSelected,
  removeHolidayModalOpen,
  removeHolidayModalSelected,
} from '../../../state/UIHolidayScheduleState';

const columnHelper = createColumnHelper<Holiday>();

export const useHolidayTableColumns = () => {
  const { colors } = useTheme();

  const formatHolidayDate = useCallback((holiday: Holiday) => {
    return holiday.isMultiday
      ? formatLongDateRange(holiday.startDate, holiday.endDate)
      : formatLongDateRange(holiday.startDate);
  }, []);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'select',
        header: ({ table }) => (
          <CheckboxComponent
            inHeader
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <CheckboxComponent
              row={row}
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
        meta: {
          thStyles: { paddingLeft: '0', borderRight: 'none' },
          tdStyles: { paddingLeft: '0', borderRight: 'none' },
          width: '70px',
          maxWidth: '70px',
          minWidth: '70px',
        },
      }),
      columnHelper.accessor('name', {
        cell: (info) => (
          <Typography variant='h5' color={colors.text.main}>
            {info.getValue()}
          </Typography>
        ),
        header: () => <TableHeaderCell text='Event' />,
        meta: {
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
        },
        filterFn: (row, id, filterValue) => {
          return row.original.name.toLowerCase().includes(filterValue.toLowerCase());
        },
      }),
      columnHelper.accessor('startDate', {
        cell: (info) => (
          <Typography variant='h5' color={colors.text.main}>
            {formatHolidayDate(info.row.original)}
          </Typography>
        ),
        header: () => <TableHeaderCell text='Date' />,
        meta: {
          width: '25%',
          maxWidth: '25%',
          minWidth: '25%',
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
        },
      }),
      columnHelper.accessor('country', {
        cell: (info) => (
          <>
            <Chip
              style={{
                backgroundColor: info.getValue().includes('US')
                  ? colors.iconStatus.noAction
                  : 'transparent',
                height: '28px',
                marginRight: '12px',
              }}
              label={
                <Box display='flex' alignItems='center' gap='4px'>
                  <Icon
                    sx={{
                      svg: {
                        marginTop: '4px',
                        g: { path: { opacity: info.getValue().includes('US') ? 1 : 0.5 } },
                      },
                    }}
                  >
                    <USAFlag />
                  </Icon>{' '}
                  <Typography
                    variant='body'
                    color={
                      info.getValue().includes('US') ? colors.text.main : colors.textAccent.disabled
                    }
                  >
                    US
                  </Typography>
                </Box>
              }
            />
            <Chip
              style={{
                backgroundColor: info.getValue().includes('UK')
                  ? colors.iconStatus.noAction
                  : 'transparent',
                height: '28px',
              }}
              label={
                <Box display='flex' alignItems='center' gap='4'>
                  <Icon
                    sx={{
                      svg: {
                        marginTop: '4px',
                        g: { path: { opacity: info.getValue().includes('UK') ? 1 : 0.5 } },
                      },
                    }}
                  >
                    <UKFlag />
                  </Icon>{' '}
                  <Typography
                    variant='body'
                    color={
                      info.getValue().includes('UK') ? colors.text.main : colors.textAccent.disabled
                    }
                  >
                    UK
                  </Typography>
                </Box>
              }
            />
          </>
        ),
        header: () => <TableHeaderCell text='Country' />,
        meta: {
          width: '25%',
          maxWidth: '25%',
          minWidth: '25%',
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
        },
        filterFn: (row, id, filterValue: SelectItem[]) => {
          if (!filterValue.length) return true;
          const holidayCountries = row.original.country.toLowerCase().split(',');
          return holidayCountries.some((country) =>
            filterValue.map((search) => search.value.toLowerCase()).includes(country)
          );
        },
      }),
      columnHelper.accessor('type', {
        cell: (info) => (
          <Chip
            style={{
              backgroundColor: colors.surfaceStatus.noAction,
              minWidth: '66px',
              height: '28px',
            }}
            label={
              <Typography variant='body' color={colors.textAccent.default}>
                {info.getValue()}
              </Typography>
            }
          />
        ),
        header: () => <TableHeaderCell text='Type' />,
        meta: {
          width: '25%',
          maxWidth: '25%',
          minWidth: '25%',
          thStyles: { paddingLeft: '16px', borderRight: 'none' },
          tdStyles: { paddingLeft: '16px', borderRight: 'none' },
        },
        filterFn: (row, id, filterValue: SelectItem[]) => {
          if (!filterValue.length) return true;
          return filterValue
            .map((type) => type.value.toLowerCase())
            .includes(row.original.type.toLowerCase());
        },
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) => (
          <ActionWrapper>
            <ActionCell holiday={info.row.original} />
          </ActionWrapper>
        ),
        header: () => <TableHeaderCell text='' />,
        meta: {
          width: '50px',
          minWidth: '50px',
          maxWidth: '50px',
          tdStyles: { paddingLeft: '0', borderLeft: `none` },
          thStyles: { borderLeft: `none` },
        },
      }),
    ],
    [colors, formatHolidayDate]
  );

  return columns;
};

interface ActionCellProps {
  holiday: Holiday;
}

export const ActionCell = ({ holiday }: ActionCellProps) => {
  const { colors } = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [holidaySelected, setHolidaySelected] = useAtom(addHolidayModalSelected);
  const [editHolidayOpen, setEditHolidayOpen] = useAtom(addHolidayModalOpen);
  const [deleteHolidayOpen, setDeleteHolidayOpen] = useAtom(removeHolidayModalOpen);
  const setDeleteHolidaySelected = useSetAtom(removeHolidayModalSelected);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleEditHolidayOpen = useCallback(() => {
    if (!holidaySelected) {
      setHolidaySelected(holiday);
    }
    setEditHolidayOpen(!editHolidayOpen);
  }, [editHolidayOpen, holiday, setEditHolidayOpen, holidaySelected, setHolidaySelected]);

  const toggleRemoveHoliday = useCallback(() => {
    setDeleteHolidaySelected([holiday]);
    setDeleteHolidayOpen(!deleteHolidayOpen);
  }, [deleteHolidayOpen, holiday, setDeleteHolidayOpen, setDeleteHolidaySelected]);

  return (
    <CellWrapper style={{ justifyContent: 'end' }}>
      <IconButton
        onClick={handleClick}
        size='small'
        sx={{ svg: { g: { path: { fill: colors.icon.accent } } } }}
      >
        <MoreIcon />
      </IconButton>
      <ActionsMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        toggleEditHolidayOpen={toggleEditHolidayOpen}
        toggleRemoveHoliday={toggleRemoveHoliday}
      />
    </CellWrapper>
  );
};

const CellWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const ActionWrapper = styled('div')`
  margin-right: 12px;
`;
