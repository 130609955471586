import styled from '@emotion/styled';
import { Button, useTheme } from '@mui/material';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';
import { ReactComponent as AddIcon } from '../../../assets/icons/add-blue.svg';
import { AvatarCell } from '../../../components/Table/CellRenderers/AvatarCell';
import { TableHeaderCell } from '../../../components/Table/CellRenderers/TableHeaderCell';
import { Typography } from '../../../components/Typography/Typography';
import { SelectItem, User } from '../../../types';
import { AssignProxyModal } from '../components/AssignProxyModal/AssignProxyModal';
import { UnassignProxy } from '../components/UnassignProxy';

const CellWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const columnHelper = createColumnHelper<User>();

export const ActionCell = (props: CellContext<User, unknown>) => {
  const { colors } = useTheme();
  const user = props.row.original;

  const hasProxy = !!user?.proxy;

  const [assignProxyModalOpen, setAssignProxyModalOpen] = useState(false);

  const handleAssignProxyModalOpen = useCallback(() => {
    setAssignProxyModalOpen(true);
  }, []);

  const handleAssignProxyModalClose = useCallback(() => {
    setAssignProxyModalOpen(false);
  }, []);

  return (
    <CellWrapper style={{ justifyContent: 'end', paddingRight: '12px', gap: '8px' }}>
      {hasProxy && <UnassignProxy user={user} />}
      <Button
        onClick={handleAssignProxyModalOpen}
        variant='text'
        style={{
          height: '28px',
          backgroundColor: colors.surfaceBackground.bg1,
          border: `1px solid ${colors.border.default}`,
        }}
        startIcon={<AddIcon />}
        sx={{
          '.MuiButton-startIcon': { marginRight: '2px' },
          '&.Mui-disabled': { opacity: 0.4, cursor: 'not-allowed', pointerEvents: 'auto' },
          svg: { g: { path: { fill: colors.icon.accent } } },
        }}
      >
        <Typography variant='h4' color={colors.textAccent.default} style={{ marginBottom: '-2px' }}>
          Assign Proxy
        </Typography>
      </Button>
      {assignProxyModalOpen && (
        <AssignProxyModal
          assignProxyModalOpen={assignProxyModalOpen}
          handleAssignProxyModalClose={handleAssignProxyModalClose}
          user={user}
        />
      )}
    </CellWrapper>
  );
};

export const useProxyAssignmentsTableColumns = () => {
  const { colors } = useTheme();

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        cell: (info) => (
          <Typography variant='body' color={colors.text.caption}>
            {info.getValue()}
          </Typography>
        ),
        header: () => <TableHeaderCell text='ID' />,
        meta: {
          width: '50px',
          minWidth: '50px',
          maxWidth: '50px',
          tdStyles: { paddingLeft: '16px', borderRight: `none` },
          thStyles: { borderRight: `none` },
        },
      }),
      columnHelper.accessor('name', {
        cell: (info) => (
          <AvatarCell
            avatarBoxStyle={{
              background: colors.surfaceIndicator.needsReview,
            }}
            name={info.getValue()}
          />
        ),
        header: () => <TableHeaderCell text='Operating Partner' />,
        filterFn: (row, id, filterValue: SelectItem[]) => {
          if (!filterValue.length) return true;
          return filterValue.some((user) => user.id === row.original.id);
        },
        meta: {
          width: '30%',
          maxWidth: '30%',
          minWidth: '30%',
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
        },
      }),
      columnHelper.accessor('proxy', {
        cell: (info) =>
          info.getValue() ? (
            <AvatarCell
              avatarBoxStyle={{
                background: colors.surfaceIndicator.needsReview,
              }}
              name={info.getValue()?.name as string}
            />
          ) : (
            <Typography variant='h5' color={colors.text.main}>
              No Proxy
            </Typography>
          ),
        header: () => <TableHeaderCell text='Proxy' />,
        filterFn: (row, id, filterValue) => {
          const isUserNameMatched = (row.original.name ?? '')
            .toLowerCase()
            .includes(filterValue.toLowerCase());
          const isProxyMatched = (row.original?.proxy?.name ?? '')
            .toLowerCase()
            .includes(filterValue.toLowerCase());
          return isUserNameMatched || isProxyMatched;
        },
        meta: {
          width: '60%',
          maxWidth: '60%',
          minWidth: '60%',
          tdStyles: { borderRight: `none` },
          thStyles: { borderRight: `none` },
        },
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) => <ActionCell {...info} />,
        header: () => <TableHeaderCell text='' />,
        meta: {
          width: '400px',
          minWidth: '400px',
          maxWidth: '400px',
          tdStyles: { paddingLeft: '0', borderLeft: `none` },
          thStyles: { borderLeft: `none` },
        },
      }),
    ],
    [colors]
  );

  return columns;
};
