import { useMutation } from 'react-query';
import { Http } from '../../services/Http';
import { Deal } from '../../types';
import { MUTATION_KEYS } from '../constants';
import { useInvalidateDeals } from './useDeals';
import { useInvalidateUsers } from './useUsers';

type AddDealPayload = {
  name: string;
  operatingPartners: number[];
  dealOwners: number[];
};

async function addDeal(payload: AddDealPayload): Promise<Deal[]> {
  const { data } = await Http.axios.post<AddDealPayload, Deal[]>(`/deal`, payload);
  return data;
}

export const useAddDeal = () => {
  const invalidateDeals = useInvalidateDeals();
  const invalidateUsers = useInvalidateUsers();
  return useMutation(addDeal, {
    mutationKey: MUTATION_KEYS.ADD_DEAL_KEY,
    onSuccess: () => {
      invalidateDeals();
      invalidateUsers();
    },
  });
};
