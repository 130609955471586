import React from 'react';
import { PageHeader } from '../layout/PageHeader';
import { ContentWrapper } from '../layout/styled';
import { AccontantOpOverviewTable } from './components/AccontantOpOverviewTable';

export function AccountantOpOverview() {
  return (
    <>
      <PageHeader
        title='Operating partners overview'
        description=''
        breadcrumbs={[{ title: 'Operating partners overview' }]}
      />
      <ContentWrapper>
        <AccontantOpOverviewTable />
      </ContentWrapper>
    </>
  );
}
