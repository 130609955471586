import { IconButton, TextField, styled } from '@mui/material';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { ReactComponent as ClearIcon } from '../../../../assets/icons/close-grey.svg';
import { FormField } from '../../../../components/FormField/FormField';
import { FormValidationMessage } from '../../../../components/FormValidationMessage/FormValidationMessage';
import { Multiselect } from '../../../../components/Multiselect/Multiselect';
import { DealSkeletonLoading } from '../../../../components/SkeletonLoader/DealSkeletonLoading';
import { UserRole } from '../../../../types';
import { IAddModalFields } from './DealFormModal';
import { useUsers } from '../../../../queries/hooks/useUsers';

export function DealFormContent() {
  const { handleChange, handleBlur, values, setFieldValue, setFieldTouched, errors, touched } =
    useFormikContext<IAddModalFields>();

  const { isLoading: areUsersLoading, data: users } = useUsers();

  const handleError = (field) => (touched[field] ? errors[field] : '');

  const operatingPartnerOptions = useMemo(() => {
    if (!users) return [];
    return users
      .filter((item) => item?.role === UserRole.OPERATING_PARTNER || item.isOp)
      .map((user) => ({
        id: user.id,
        value: user.name,
      }));
  }, [users]);

  const dealOwnerOptions = useMemo(() => {
    if (!users) return [];
    return users
      .filter((item) => item?.role === UserRole.DEAL_OWNER)
      .map((user) => ({
        id: user.id,
        value: user.name,
      }));
  }, [users]);

  const hasValidationErrors = !!Object.keys(errors)?.length;

  return (
    <Content>
      {areUsersLoading ? (
        <DealSkeletonLoading />
      ) : (
        <>
          <FormField label={'Deal Name'} error={handleError('name')}>
            <StyledTextField
              autoComplete='off'
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setFieldValue('name', null);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
              placeholder='Enter Deal Name'
              name={'name'}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.name || ''}
            />
          </FormField>

          <FormField label={'Operating Partners'} error={handleError('operatingPartner')}>
            <Multiselect
              listboxStyle={{ height: '195px' }}
              options={operatingPartnerOptions}
              value={values?.operatingPartners}
              onChange={(_, value) => {
                setFieldValue('operatingPartners', value);
              }}
              onBlur={() => {
                setFieldTouched('operatingPartners');
              }}
              disablePortal
              fieldPlaceholder='Select Operating Partners'
            />
          </FormField>
          <FormField label={'Deal Owners'} error={handleError('dealOwners')}>
            <Multiselect
              listboxStyle={{ height: '195px' }}
              options={dealOwnerOptions}
              value={values?.dealOwners}
              onChange={(_, deals) => {
                setFieldValue('dealOwners', deals);
              }}
              onBlur={() => {
                setFieldTouched('dealOwners');
              }}
              disablePortal
              fieldPlaceholder='Select Deal Owners'
            />
          </FormField>
          {hasValidationErrors && (
            <FormValidationMessage text='Please, enter the missing details to add a deal.' />
          )}
        </>
      )}
    </Content>
  );
}

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    padding-right: 0;
  }
  svg {
    width: 20px;
    height: 20px;
  }
`;
