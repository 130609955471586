import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Provider as JotaiStateProvider } from 'jotai';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { Http } from './services/Http';

const isLocalHost = !!process.env.REACT_APP_SHOW_REACT_QUERY_DEV_TOOL;
export const queryClient = new QueryClient();

Http.init();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <JotaiStateProvider>
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <App />
          </LocalizationProvider>
        </BrowserRouter>
      </JotaiStateProvider>
      {isLocalHost && <ReactQueryDevtools />}
    </QueryClientProvider>
  </StrictMode>
);

reportWebVitals();
