import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { SelectItem, User } from '../../../types';
import styled from '@emotion/styled';
import { Box, Chip, Icon, IconButton, useTheme } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { Typography } from '../../../components/Typography/Typography';
import { TableHeaderCell } from '../../../components/Table/CellRenderers/TableHeaderCell';
import { AvatarCell } from '../../../components/Table/CellRenderers/AvatarCell';
import { ReactComponent as MoreIcon } from '../../../assets/icons/more-vertical.svg';
import { UserModal } from '../components/UserModal/UserModal';
import ActionsMenu from '../components/ActionsMenu';
import { formatUSDate } from '../../../utils/formatters';
import { ReactComponent as UKFlag } from '../../../assets/icons/uk-flag.svg';
import { ReactComponent as USAFlag } from '../../../assets/icons/usa-flag.svg';
import { DeleteUserModal } from '../components/DeleteUserModal';

const CellWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const columnHelper = createColumnHelper<User>();

export const ActionCell = (props: CellContext<User, unknown>) => {
  const { colors } = useTheme();
  const user = props.row.original;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [editUserProfileOpen, setEditUserProfileOpen] = useState(false);
  const [deleteUserProfileOpen, setDeleteUserProfileOpen] = useState(false);

  const handleEditUserProfileOpen = useCallback(() => {
    setEditUserProfileOpen(true);
  }, []);

  const handleEditUserProfilClose = useCallback(() => {
    setEditUserProfileOpen(false);
  }, []);

  const handleDeleteUserProfileOpen = useCallback(() => {
    setDeleteUserProfileOpen(true);
  }, []);

  const handleDeleteUserProfilClose = useCallback(() => {
    setDeleteUserProfileOpen(false);
  }, []);

  return (
    <CellWrapper style={{ justifyContent: 'end', paddingRight: '12px' }}>
      <IconButton
        onClick={handleClick}
        size='small'
        sx={{ svg: { g: { path: { fill: colors.icon.accent } } } }}
      >
        <MoreIcon />
      </IconButton>
      <ActionsMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleEditUserProfileOpen={handleEditUserProfileOpen}
        handleDeleteUserProfileOpen={handleDeleteUserProfileOpen}
      />
      {editUserProfileOpen && (
        <UserModal
          userProfileOpen={editUserProfileOpen}
          handleUserProfilClose={handleEditUserProfilClose}
          user={user}
        />
      )}
      {deleteUserProfileOpen && (
        <DeleteUserModal
          user={user}
          toggleDeleteUser={handleDeleteUserProfilClose}
          deleteUserOpen={deleteUserProfileOpen}
        />
      )}
    </CellWrapper>
  );
};

export const useOPsTableColumns = () => {
  const { colors } = useTheme();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => (
          <AvatarCell
            name={info.getValue()}
            avatarBoxStyle={{
              background: colors.surfaceIndicator.needsReview,
            }}
          />
        ),
        header: () => <TableHeaderCell text='User' />,
        filterFn: (row, id, filterValue) => {
          const isUserNameMatched = (row.original.name ?? '')
            .toLowerCase()
            .includes(filterValue.toLowerCase());
          const isEmailMatched = (row.original.email ?? '')
            .toLowerCase()
            .includes(filterValue.toLowerCase());
          return isUserNameMatched || isEmailMatched;
        },
        meta: {
          width: '15%',
          maxWidth: '15%',
          minWidth: '15%',
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
        },
      }),
      columnHelper.accessor('startDate', {
        cell: (info) => (
          <Typography variant='h5' color={colors.text.main}>
            {formatUSDate(info.getValue() ?? '') || 'N/A'}
          </Typography>
        ),
        header: () => <TableHeaderCell text='Start Work Date' />,
        meta: {
          width: '25%',
          maxWidth: '25%',
          minWidth: '25%',
        },
      }),
      columnHelper.accessor('endDate', {
        cell: (info) => (
          <Typography variant='h5' color={colors.text.main}>
            {formatUSDate(info.getValue() ?? '') || 'N/A'}
          </Typography>
        ),
        header: () => <TableHeaderCell text='End Work Date' />,
        meta: {
          width: '25%',
          maxWidth: '25%',
          minWidth: '25%',
        },
      }),
      columnHelper.accessor('country', {
        cell: (info) => (
          <>
            <Chip
              style={{
                backgroundColor:
                  info.getValue() === 'US' ? colors.iconStatus.noAction : 'transparent',
                height: '28px',
                marginRight: '12px',
              }}
              label={
                <Box display='flex' alignItems='center' gap='4px'>
                  <Icon
                    sx={{
                      svg: {
                        marginTop: '4px',
                        g: { path: { opacity: info.getValue() === 'US' ? 1 : 0.5 } },
                      },
                    }}
                  >
                    <USAFlag />
                  </Icon>{' '}
                  <Typography
                    variant='body'
                    color={info.getValue() === 'US' ? colors.text.main : colors.textAccent.disabled}
                  >
                    US
                  </Typography>
                </Box>
              }
            />
            <Chip
              style={{
                backgroundColor:
                  info.getValue() === 'UK' ? colors.iconStatus.noAction : 'transparent',
                height: '28px',
              }}
              label={
                <Box display='flex' alignItems='center' gap='4'>
                  <Icon
                    sx={{
                      svg: {
                        marginTop: '4px',
                        g: { path: { opacity: info.getValue() === 'UK' ? 1 : 0.5 } },
                      },
                    }}
                  >
                    <UKFlag />
                  </Icon>{' '}
                  <Typography
                    variant='body'
                    color={info.getValue() === 'UK' ? colors.text.main : colors.textAccent.disabled}
                  >
                    UK
                  </Typography>
                </Box>
              }
            />
          </>
        ),
        header: () => <TableHeaderCell text='Country' />,
        meta: {
          width: '25%',
          maxWidth: '25%',
          minWidth: '25%',
        },
        filterFn: (row, id, filterValue: SelectItem[]) => {
          if (!filterValue.length) return true;
          const holidayCountries = row.original.country?.toLowerCase().split(',');
          return (holidayCountries || []).some((country) =>
            filterValue.map((search) => search.value.toLowerCase()).includes(country)
          );
        },
      }),
      columnHelper.accessor('email', {
        cell: (info) => (
          <Typography variant='h5' color={colors.text.main}>
            {info.getValue()}
          </Typography>
        ),
        header: () => <TableHeaderCell text='Contact' />,
        meta: {
          tdStyles: { borderRight: `none` },
          thStyles: { borderRight: `none` },
        },
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) => <ActionCell {...info} />,
        header: () => <TableHeaderCell text='' />,
        meta: {
          width: '50px',
          minWidth: '50px',
          maxWidth: '50px',
          tdStyles: { paddingLeft: '0', borderLeft: `none` },
          thStyles: { borderLeft: `none` },
        },
      }),
    ],
    [colors]
  );

  return columns;
};
