import { styled, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { ModalComponent } from '../../../components/ModalComponent/ModalComponent';
import { Typography } from '../../../components/Typography/Typography';
import { useDeleteHoliday } from '../../../queries/hooks/useDeleteHoliday';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { useBulkDeleteHoliday } from '../../../queries/hooks/useBulkDeleteHolidays';
import { useAtom, useAtomValue } from 'jotai';
import {
  removeHolidayModalOpen,
  removeHolidayModalSelected,
} from '../../../state/UIHolidayScheduleState';
import { formatLongDateRange } from '../../../utils/formatters';

export function RemoveHolidaysModal() {
  const { colors } = useTheme();
  const [isOpen, toggleOpen] = useAtom(removeHolidayModalOpen);
  const holidays = useAtomValue(removeHolidayModalSelected);
  const { pushSuccessToast, pushErrorToast } = useToastMessage();

  const { mutate: onDeleteHoliday, isLoading: deleteInProgress } = useDeleteHoliday();
  const { mutate: onBulkDeleteHoliday, isLoading: bulkDeleteInProgress } = useBulkDeleteHoliday();

  const title = useMemo(() => {
    if ((holidays || []).length > 1) return `Remove ${holidays?.length} Events`;
    return 'Remove Event';
  }, [holidays]);

  const holidayList = useMemo(() => {
    let message = '';
    holidays?.forEach((holiday, index) => {
      const { name, startDate, endDate, isMultiday } = holiday;
      const hasAnd = holidays[index] !== holidays[holidays.length - 1];
      if (isMultiday) {
        message += `${name} (${formatLongDateRange(startDate, endDate)})`;
      } else {
        message += `${name} (${formatLongDateRange(startDate)})`;
      }
      message += hasAnd ? ` and ` : '';
    });

    return message;
  }, [holidays]);

  const handleDeleteHolidays = () => {
    if (holidays) {
      if (holidays.length === 1) {
        onDeleteHoliday(holidays[0].multidayId, {
          onSuccess: () => {
            pushSuccessToast({
              title: 'Holiday Deleted',
              message: `${holidays[0].name} holiday has been deleted.`,
            });
            handleToggleModal();
          },
          onError: () => {
            pushErrorToast({ message: 'Failed to delete holiday.' });
          },
        });
      } else {
        onBulkDeleteHoliday(
          holidays.map((holiday) => holiday.multidayId),
          {
            onSuccess: () => {
              const holidayTitle = holidays.map((holiday) => holiday.name).join(', ');
              pushSuccessToast({
                title: 'Holidays Deleted',
                message: `${holidayTitle} holidays has been deleted.`,
              });
              handleToggleModal();
            },
            onError: () => {
              pushErrorToast({ message: 'Failed to delete holidays.' });
            },
          }
        );
      }
    }
  };

  const handleToggleModal = () => {
    toggleOpen(!isOpen);
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={handleToggleModal}
      actionButtonsProps={{
        onSubmit: handleDeleteHolidays,
        isSubmitDisabled: deleteInProgress || bulkDeleteInProgress,
        onCancel: handleToggleModal,
        submitText: 'Remove',
        submitBtnStyle: {},
      }}
      title={title}
      top='80px'
    >
      <InfoWrapper>
        <Typography variant='body' color={colors.text.main}>
          {`Are you sure you want to remove ${holidayList}?`}
        </Typography>
      </InfoWrapper>
    </ModalComponent>
  );
}

const InfoWrapper = styled('div')`
  border-radius: 2px;
  display: flex;
  gap: 8px;
  margin-bottom: 14px;
`;
