import { useTheme } from '@mui/material';
import Joyride, { Step, Styles } from 'react-joyride';

interface OnboardingTooltipProps {
  steps: Step[];
  run: boolean;
  handleJoyrideCallback: (data) => void;
}

function OnboardingTooltip({ steps, handleJoyrideCallback, run }: OnboardingTooltipProps) {
  const { colors } = useTheme();

  const customStepStyle: Partial<Styles> = {
    tooltipContent: {
      textAlign: 'left',
      fontSize: '14px',
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingBottom: '20px'
    },
    tooltipTitle: {
      textAlign: 'left',
      paddingLeft: '10px',
      paddingRight: '20px',
    },
    options: {
      backgroundColor: colors.surfaceBackground.bg1,
      textColor: colors.text.main,
      arrowColor: 'transparent',
    },
    buttonNext: {
      color: colors.textAccent.default,
      backgroundColor: 'transparent',
    },
    buttonSkip: {
      color: colors.text.secondary,
    },
    buttonBack: {
      color: colors.text.secondary,
    },
    tooltip: {
      width: '300px',
    },
    tooltipFooter: {
      marginTop: '5px',
      flexDirection: 'row-reverse'
    },
  };

  const customSteps = steps.map((e) => {
    return {
      ...e,
      disableBeacon: true,
      styles: {
        ...customStepStyle,
        ...e.styles,
      },
    };
  });

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      run={run}
      scrollToFirstStep
      disableOverlayClose
      hideBackButton
      steps={customSteps}
      styles={{
        options: {
          zIndex: 10000,
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    />
  );
}

export default OnboardingTooltip;
