import { Box, Button, Chip, styled, useTheme } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

import { useParams } from 'react-router';
import { AvatarCell } from '../../../components/Table/CellRenderers/AvatarCell';
import { Typography } from '../../../components/Typography/Typography';
import { useTimesheetStatusColor } from '../../../hooks/useTimesheetStatusColor';
import { TimesheetStatus, TimesheetStatusLabel } from '../../../types';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check-icon-default.svg';
import { ReactComponent as BackIcon } from '../../../assets/icons/undo.svg';
import { useMonthlySingleTimesheet } from '../../../queries/hooks/useMonthlySingleTimesheet';
import { ApproveTimesheetModal } from './ApproveTimesheetModal';
import { SendBackTimesheetModal } from './SendBackTimesheetModal';
import { TimesheetNavigation } from './TimesheetNavigation';
import { TableSkeletonLoader } from '../../../components/SkeletonLoader/Table.SkeletonLoader';
import { getAccountantTsStatusLabel } from '../../../utils/tsMappers';

export function SingleTimesheetHeader() {
  const { colors } = useTheme();
  const { id } = useParams();

  const [approveTimesheetOpen, setApproveTimesheetOpen] = useState(false);
  const [sendBackTsOpen, setSendBackTsOpen] = useState(false);

  const { data: singleTimesheet, isLoading: dataIsLoading } = useMonthlySingleTimesheet({
    id: Number(id)
  });

  const statusLabel = useMemo(() => {
    if(!singleTimesheet) return;
    return getAccountantTsStatusLabel(singleTimesheet?.status as TimesheetStatus);
  }, [singleTimesheet])

  const toggleApproveTsOpen = useCallback(() => {
    setApproveTimesheetOpen((prev) => !prev);
  }, []);

  const toggleSendBackTsOpen = useCallback(() => {
    setSendBackTsOpen((prev) => !prev);
  }, []);

  const hideActions = singleTimesheet?.status !== TimesheetStatus.PENDING_ACCOUNTANT;

  const statusColor = useTimesheetStatusColor(singleTimesheet?.status as unknown as TimesheetStatusLabel);

  if (dataIsLoading || !singleTimesheet) return <TableSkeletonLoader />;

  return (
    <Container>
      <UserInfoWrap>
        <AvatarCell
          name={singleTimesheet?.user?.name || ''}
          avatarBoxStyle={{
            width: 34,
            height: 34,
            minWidth: 34,
            maxWidth: 34,
            background: colors.surfaceIndicator.needsReview,
          }}
          avatarLetterStyle={{
            color: colors.text.main,
            fontSize: 14,
          }}
        />
        <Box display={'flex'} gap={'8px'} flex={1}>
          <Chip
            style={{
              backgroundColor: colors.surfaceStatus.noAction,
              minWidth: '66px',
              height: '28px',
            }}
            label={
              <Typography variant='body' color={colors.text.main}>
                {singleTimesheet?.user?.role}
              </Typography>
            }
          />
          <Chip
            style={{
              backgroundColor: colors.surfaceStatus.noAction,
              minWidth: '66px',
              height: '28px',
            }}
            label={
              <Typography variant='body' color={statusColor}>
                {statusLabel}
              </Typography>
            }
          />
        </Box>
      </UserInfoWrap>
      <TimesheetNavigation timesheet={singleTimesheet} />
      <ActionBtnWrap
        sx={{
          visibility: hideActions ? 'hidden' : 'visible',
        }}
      >
        <Button
          variant='contained'
          startIcon={<StyledCheckIcon disabled={false} />}
          onClick={toggleApproveTsOpen}
        >
          <Typography color={colors.textAccent.inverse} variant='subtitle1'>
            Approve
          </Typography>
        </Button>
        <Button
          startIcon={<BackIcon />}
          variant='outlined'
          disabled={false}
          onClick={toggleSendBackTsOpen}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography color={colors.textAccent.default} variant='subtitle1'>
            Send Back
          </Typography>
        </Button>
      </ActionBtnWrap>
      {approveTimesheetOpen && (
        <ApproveTimesheetModal
          timesheet={singleTimesheet}
          isOpen={approveTimesheetOpen}
          toggleOpen={toggleApproveTsOpen}
        />
      )}
      {sendBackTsOpen && (
        <SendBackTimesheetModal
          timesheet={singleTimesheet}
          isOpen={sendBackTsOpen}
          toggleOpen={toggleSendBackTsOpen}
        />
      )}
    </Container>
  );
}

const Container = styled('div')`
  display: flex;
  padding-bottom: 28px;
  justify-content: center;
  padding-bottom: 28px;
`;

const UserInfoWrap = styled('div')`
  display: flex;
  align-items: center;
  width: 35%;
  gap: 20px;
`;

const ActionBtnWrap = styled('div')`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  width: 35%;
`;

const StyledCheckIcon = styled(CheckIcon)<{ disabled: boolean }>`
  width: 20px;
  height: 20px;
  path {
    fill: ${({ disabled, theme }) =>
      disabled ? theme.colors.icon.disabled : theme.colors.icon.inverse};
  }
`;
