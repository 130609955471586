import { useMutation } from 'react-query';
import { Http } from '../../services/Http';
import { MonthlyTimesheet, TimesheetStatus } from '../../types';
import { MUTATION_KEYS } from '../constants';
import { useInvalidateMonthlyTimesheets } from './useMonthlyTimesheets';
import { useInvalidateMonthlySingleTimesheet } from './useMonthlySingleTimesheet';

type SubmitPayload = {
  status: TimesheetStatus;
  ids: number[];
};

async function approveMontlyTimesheets({ ids, status }: SubmitPayload): Promise<MonthlyTimesheet> {
  const { data } = await Http.axios.patch<SubmitPayload, MonthlyTimesheet>(
    `/monthly-timesheet/bulk-update`,
    {
      status,
      ids,
    }
  );
  return data;
}
export const useBulkApproveMontlyTimesheet = () => {
  const invalidateMontlyTimesheets = useInvalidateMonthlyTimesheets();
  const invalidateMontlySingleTimesheet = useInvalidateMonthlySingleTimesheet();

  return useMutation(approveMontlyTimesheets, {
    mutationKey: MUTATION_KEYS.APPROVE_MONTLY_TIMESHEET,
    onSuccess: () => {
      invalidateMontlyTimesheets();
      invalidateMontlySingleTimesheet();
    },
  });
};
