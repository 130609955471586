import { useQuery, useQueryClient } from 'react-query';
import { Http } from '../../services/Http';
import { QUERY_KEYS } from '../constants';
import { Timesheet } from '../../types';

async function fetchTimesheets(id: number): Promise<Timesheet[]> {
  const { data } = await Http.axios.get<Timesheet[]>(`/timesheet/user/${id}`);
  return data;
}

export const useTimesheets = ({ userId }) => {
  return useQuery([QUERY_KEYS.TIMESHEETS, userId], () => fetchTimesheets(userId), {
    staleTime: Infinity,
    enabled: !!userId,
    refetchInterval: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
};

export const useInvalidateTimesheets = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.TIMESHEETS,
    });
  };
};
