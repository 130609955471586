import { ThemeProvider } from '@mui/material';
import { OktaAuth } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { Route, Routes, useNavigate } from 'react-router';
import { GuardedRoute } from './components/GuardedRoute/GuardedRoute';
import { ROUTES } from './constants/routes';
import { AllTimesheets } from './pages/AllTimesheets/AllTimesheets';
import { Deals } from './pages/Deals/Deals';
import { DefaultPageRedirect } from './pages/DefaultPageRedirect/DefaultPageRedirect';
import { IssuedTimesheets } from './pages/IssuedTimesheets/IssuedTimesheets';
import { ProxyAssignment } from './pages/ProxyAssignment/ProxyAssignment';
import SingleTimesheet from './pages/SingleTimesheet/SingleTimesheet';
import { TimesheetAssignment } from './pages/TimesheetAssignment/TimesheetAssignment';
import { UserAdministration } from './pages/UserAdministration/UserAdministration';
import { Layout } from './pages/layout/Layout';
import { selectedThemeState } from './state/UIState';
import { darkTheme } from './themes/dark-theme/dark-theme';
import { lightTheme } from './themes/light-theme/light-theme';
import { THEME } from './types';
import { OpManagerSubmittedTimesheets } from './pages/OpManagerSubmittedTimesheets/OpManagerSubmittedTimesheets';
import { OpManagerAllTimesheets } from './pages/OpManagerAllTimesheets/OpManagerAllTimesheets';
import { OpManagerSingleTimesheet } from './pages/OpManagerSingleTimesheet/OpManagerSingleTimesheet';
import DealOwnerDeals from './pages/DealOwnerDeals/DealOwnerDeals';
import DealOwnerAllTimesheets from './pages/DealOwnerAllTimesheets/DealOwnerAllTimesheets';
import DealOwnerPendingTimesheets from './pages/DealOwnerPendingTimesheets/DealOwnerPendingTimesheets';
import { DealOwnerSingleTimesheet } from './pages/DealOwnerSingleTimesheet/DealOwnerSingleTimesheet';
import { OpManagerOperatingPartners } from './pages/OpManagerOperatingPartners/OpManagerOperatingPartners';
import { AccountantTimesheetStatuses } from './pages/AccountantTimesheetStatuses/AccountantTimesheetStatuses';
import { AccountantOpOverview } from './pages/AccountantOpOverview/AccountantOpOverview';
import { AccountantSingleTimesheet } from './pages/AccountantSingleTimesheet/AccountantSingleTimesheet';
import { HolidaySchedule } from './pages/HolidaySchedule/HolidaySchedule';

const oktaAuth = new OktaAuth({
  issuer: `https://${process.env.REACT_APP_OKTA_BASE_URL}/oauth2/default`,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
});

function App() {
  const [selectedTheme, setSelectedTheme] = useAtom(selectedThemeState);
  const navigate = useNavigate();

  const restoreOriginalUri = () => {
    navigate(`/`);
  };

  const theme = useMemo(() => {
    if (selectedTheme) {
      return selectedTheme;
    }
    const localStorageTheme = localStorage.getItem('theme') as THEME | undefined;
    if (!localStorageTheme) {
      localStorage.setItem('theme', THEME.DARK);
      setSelectedTheme(THEME.DARK);
      return THEME.DARK;
    }
    setSelectedTheme(localStorageTheme);
    return localStorageTheme;
  }, [selectedTheme, setSelectedTheme]);

  const themeObj = useMemo(() => {
    if (theme === THEME.DARK) {
      return darkTheme;
    }
    return lightTheme;
  }, [theme]);

  return (
    <ThemeProvider theme={themeObj}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Routes>
          <Route path={'/login/callback'} element={<LoginCallback />} />
          <Route path={'/'} element={<Layout />}>
            <Route index element={<DefaultPageRedirect />} />
            <Route
              path={`/${ROUTES.TIMESHEETS_ASSIGNMENT}`}
              element={
                <GuardedRoute
                  path={ROUTES.TIMESHEETS_ASSIGNMENT}
                  component={<TimesheetAssignment />}
                />
              }
            />
            <Route
              path={`/${ROUTES.DEALS}`}
              element={<GuardedRoute path={ROUTES.DEALS} component={<Deals />} />}
            />
            <Route
              path={`/${ROUTES.PROXY_ASSIGNMENT}`}
              element={
                <GuardedRoute path={ROUTES.PROXY_ASSIGNMENT} component={<ProxyAssignment />} />
              }
            />
            <Route
              path={`/${ROUTES.USER_ADMINISTRATION}`}
              element={
                <GuardedRoute
                  path={ROUTES.USER_ADMINISTRATION}
                  component={<UserAdministration />}
                />
              }
            />
            <Route
              path={`/${ROUTES.EVENT_SCHEDULE}`}
              element={
                <GuardedRoute path={ROUTES.EVENT_SCHEDULE} component={<HolidaySchedule />} />
              }
            />
            <Route
              path={`/${ROUTES.PENDING_TIMESHEETS}`}
              element={
                <GuardedRoute path={ROUTES.PENDING_TIMESHEETS} component={<IssuedTimesheets />} />
              }
            />
            <Route
              path={`/${ROUTES.ISSUED_TIMESHEETS}`}
              element={
                <GuardedRoute path={ROUTES.ISSUED_TIMESHEETS} component={<IssuedTimesheets />} />
              }
            />
            <Route
              path={`/${ROUTES.ALL_TIMESHEETS}`}
              element={<GuardedRoute path={ROUTES.ALL_TIMESHEETS} component={<AllTimesheets />} />}
            />
            <Route
              path={`/${ROUTES.PENDING_TIMESHEETS}/:id`}
              element={
                <GuardedRoute path={ROUTES.PENDING_TIMESHEETS} component={<SingleTimesheet />} />
              }
            />
            <Route
              path={`/${ROUTES.ISSUED_TIMESHEETS}/:id`}
              element={
                <GuardedRoute path={ROUTES.ISSUED_TIMESHEETS} component={<SingleTimesheet />} />
              }
            />
            <Route
              path={`/${ROUTES.ALL_TIMESHEETS}/:id`}
              element={
                <GuardedRoute path={ROUTES.ALL_TIMESHEETS} component={<SingleTimesheet />} />
              }
            />
            {/* //OP Manager */}
            <Route
              path={`/${ROUTES.OP_MANAGER_ALL_TIMESHEETS}`}
              element={
                <GuardedRoute
                  path={ROUTES.OP_MANAGER_ALL_TIMESHEETS}
                  component={<OpManagerAllTimesheets />}
                />
              }
            />
            <Route
              path={`/${ROUTES.OP_MANAGER_SUBMITTED_TIMESHEET}`}
              element={
                <GuardedRoute
                  path={ROUTES.OP_MANAGER_SUBMITTED_TIMESHEET}
                  component={<OpManagerSubmittedTimesheets />}
                />
              }
            />
            <Route
              path={`/${ROUTES.OP_MANAGER_ALL_OPERATING_PARTNERS}`}
              element={
                <GuardedRoute
                  path={ROUTES.OP_MANAGER_ALL_OPERATING_PARTNERS}
                  component={<OpManagerOperatingPartners />}
                />
              }
            />
            <Route
              path={`/${ROUTES.OP_MANAGER_ALL_TIMESHEETS}/:id`}
              element={
                <GuardedRoute
                  path={ROUTES.OP_MANAGER_ALL_TIMESHEETS}
                  component={<OpManagerSingleTimesheet />}
                />
              }
            />
            <Route
              path={`/${ROUTES.OP_MANAGER_SUBMITTED_TIMESHEET}/:id`}
              element={
                <GuardedRoute
                  path={ROUTES.OP_MANAGER_SUBMITTED_TIMESHEET}
                  component={<OpManagerSingleTimesheet />}
                />
              }
            />

            {/*  */}
            {/* //Deal owner*/}
            <Route
              path={`/${ROUTES.DEAL_OWNER_DEALS}`}
              element={
                <GuardedRoute path={ROUTES.DEAL_OWNER_DEALS} component={<DealOwnerDeals />} />
              }
            />
            <Route
              path={`/${ROUTES.DEAL_OWNER_ALL_TIMESHEETS}`}
              element={
                <GuardedRoute
                  path={ROUTES.DEAL_OWNER_ALL_TIMESHEETS}
                  component={<DealOwnerAllTimesheets />}
                />
              }
            />
            <Route
              path={`/${ROUTES.DEAL_OWNER_PENDING_TIMESHEETS}`}
              element={
                <GuardedRoute
                  path={ROUTES.DEAL_OWNER_PENDING_TIMESHEETS}
                  component={<DealOwnerPendingTimesheets />}
                />
              }
            />
            <Route
              path={`/${ROUTES.DEAL_OWNER_ALL_TIMESHEETS}/:id`}
              element={
                <GuardedRoute
                  path={ROUTES.DEAL_OWNER_ALL_TIMESHEETS}
                  component={<DealOwnerSingleTimesheet />}
                />
              }
            />
            {/* //Accountant*/}
            <Route
              path={`/${ROUTES.ACCOUNTANT_TIMESHEETS_STATUSES}`}
              element={
                <GuardedRoute
                  path={ROUTES.ACCOUNTANT_TIMESHEETS_STATUSES}
                  component={<AccountantTimesheetStatuses />}
                />
              }
            />
            <Route
              path={`/${ROUTES.ACCOUNTANT_OP_OVERVIEW}`}
              element={
                <GuardedRoute
                  path={ROUTES.ACCOUNTANT_OP_OVERVIEW}
                  component={<AccountantOpOverview />}
                />
              }
            />
            <Route
              path={`/${ROUTES.ACCOUNTANT_TIMESHEETS_STATUSES}/:id`}
              element={
                <GuardedRoute
                  path={ROUTES.ACCOUNTANT_TIMESHEETS_STATUSES}
                  component={<AccountantSingleTimesheet />}
                />
              }
            />
          </Route>
        </Routes>
      </Security>
    </ThemeProvider>
  );
}

export default App;
