import { useMutation } from 'react-query';
import { MUTATION_KEYS } from '../constants';
import { Timesheet, TimesheetStatus } from '../../types';
import { Http } from '../../services/Http';
import { useInvalidateTimesheets } from './useTimesheets';
import { useInvalidateSingleTimesheet } from './useSingleTimesheet';

type SubmitPayload = {
  status: string;
};

type Props = {
  id?: number;
};

const payload = {
  status: TimesheetStatus.PENDING_ACCOUNTANT,
};

async function submitTimesheet({ id }: Props): Promise<Timesheet> {
  const { data } = await Http.axios.patch<SubmitPayload, Timesheet>(`/timesheet/${id}`, payload);
  return data;
}
export const useSubmitTimesheet = () => {
  const invalidateAllTimesheets = useInvalidateTimesheets();
  const invalidateSingleTimesheet = useInvalidateSingleTimesheet();
  return useMutation(submitTimesheet, {
    mutationKey: MUTATION_KEYS.SUBMIT_TIMESHEET,
    onSuccess: () => {
      invalidateAllTimesheets();
      invalidateSingleTimesheet();
    },
  });
};
