import { useTheme } from '@mui/material';
import { useMemo } from 'react';

import { createColumnHelper } from '@tanstack/react-table';

import { AvatarCell } from '../../../components/Table/CellRenderers/AvatarCell';
import { TableHeaderCell } from '../../../components/Table/CellRenderers/TableHeaderCell';
import { Typography } from '../../../components/Typography/Typography';
import { MonthlyTimesheetRow } from '../../../types';
import TotalCell from '../components/TotalCell';

const columnHelper = createColumnHelper<MonthlyTimesheetRow>();

export const useOpManagerSingleTableColumns = () => {
  const { colors } = useTheme();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => {
          const isFixedDeal = info.row.original.dealId === 0;
          return (
            <AvatarCell
              name={info.row.original.name}
              avatarBoxStyle={{
                background: isFixedDeal
                  ? colors.surfaceBackground.highlighted
                  : colors.surfaceIndicator.needsReview,
              }}
              avatarLetterStyle={{
                color: colors.text.main,
              }}
            />
          );
        },
        enableSorting: false,

        header: () => <TableHeaderCell text='Deal' />,
        footer: () => {
          return (
            <Typography
              variant='body'
              color={colors.text.main}
              style={{
                paddingLeft: '30px',
                fontSize: '17px',
              }}
            >
              Total per Month
            </Typography>
          );
        },
        meta: {
          tdStyles: { paddingLeft: '16px', borderRight: `none` },
          thStyles: { borderRight: `none` },
        },
      }),
      columnHelper.accessor('avg', {
        cell: (info) => {
          const totalPerDeal = info.row.original.avg?.toFixed() ?? 0;
          return (
            <Typography
              variant='body'
              color={colors.text.main}
              style={{
                textWrap: 'wrap',
              }}
            >
              {`${totalPerDeal}%`}
            </Typography>
          );
        },
        header: () => <TableHeaderCell text='Total per deal' />,
        footer: TotalCell,

        enableSorting: false,

        meta: {
          width: '20%',
          maxWidth: '20%',
          minWidth: '20%',
          tdStyles: { paddingLeft: '16px', paddingRight: '16px', borderLeft: `none` },
          thStyles: { borderRight: `none`, paddingRight: '16px', borderLeft: `none` },
        },
      }),
    ],
    [colors]
  );

  return columns;
};
