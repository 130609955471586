import styled from '@emotion/styled';
import { IconButton, useTheme } from '@mui/material';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';
import { ReactComponent as MoreIcon } from '../../../assets/icons/more-vertical.svg';
import { TableHeaderCell } from '../../../components/Table/CellRenderers/TableHeaderCell';
import { Typography } from '../../../components/Typography/Typography';
import { Deal, SelectItem } from '../../../types';
import ActionsMenu from '../components/ActionsMenu';
import { DealFormModal } from '../components/DealFormModal/DealFormModal';
import { DealOwnersCell } from '../components/DealOwnersCell';
import { DeleteDealModal } from '../components/DeleteDealModal';
import { OperatingPartnersCell } from '../components/OperatingPartnersCell';

const CellWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const columnHelper = createColumnHelper<Deal>();

export const ActionCell = (props: CellContext<Deal, unknown>) => {
  const { colors } = useTheme();
  const deal = props.row.original;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [editDealOpen, setEditDealOpen] = useState(false);
  const [deleteDealOpen, setDeleteDealOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleEditDealOpen = useCallback(() => {
    setEditDealOpen((prev) => !prev);
  }, []);

  const toggleDeleteDeal = useCallback(() => {
    setDeleteDealOpen((prev) => !prev);
  }, []);

  return (
    <CellWrapper style={{ justifyContent: 'end', paddingRight: '12px' }}>
      <IconButton
        onClick={handleClick}
        size='small'
        sx={{ svg: { g: { path: { fill: colors.icon.accent } } } }}
      >
        <MoreIcon />
      </IconButton>
      <ActionsMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        toggleEditDealOpen={toggleEditDealOpen}
        toggleDeleteDeal={toggleDeleteDeal}
      />
      {editDealOpen && (
        <DealFormModal
          addDealOpen={editDealOpen}
          handleAddDealClose={toggleEditDealOpen}
          deal={deal}
        />
      )}
      {deleteDealOpen && (
        <DeleteDealModal
          deal={deal}
          deleteDealOpen={deleteDealOpen}
          toggleDeleteDeal={toggleDeleteDeal}
        />
      )}
    </CellWrapper>
  );
};

export const useDealsTableColumns = () => {
  const { colors } = useTheme();

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        cell: (info) => (
          <Typography variant='body' color={colors.text.caption}>
            {info.getValue()}
          </Typography>
        ),
        header: () => <TableHeaderCell text='ID' />,
        meta: {
          width: '45px',
          maxWidth: '50px',
          minWidth: '45px',
          tdStyles: { paddingLeft: '16px', borderRight: `none` },
          thStyles: { borderRight: `none` },
        },
      }),
      columnHelper.accessor('name', {
        cell: (info) => (
          <Typography variant='h4' color={colors.text.main}>
            {info.getValue()}
          </Typography>
        ),
        header: () => <TableHeaderCell text='Deal' />,
        filterFn: (row, id, filterValue) => {
          const isIssuerNameMatched = (row.original.name ?? '')
            .toLowerCase()
            .includes(filterValue.toLowerCase());

          const isOwnerMatched = !!row.original.dealOwners?.some((owner) => {
            return owner.name.toLowerCase().includes(filterValue.toLowerCase());
          });

          const isOPMatched = !!row.original.operatingPartners?.some((partner) => {
            return partner.name.toLowerCase().includes(filterValue.toLowerCase());
          });

          return isIssuerNameMatched || isOwnerMatched || isOPMatched;
        },
        meta: {
          width: '200px',
          maxWidth: '200px',
          minWidth: '200px',
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
        },
      }),
      columnHelper.accessor('dealOwners', {
        cell: (info) => <DealOwnersCell deals={info.row.original.dealOwners} />,
        header: () => <TableHeaderCell text='Deal Owners' />,
        enableSorting: false,
        meta: {
          width: '400px',
          maxWidth: '400px',
          minWidth: '400px',
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
        },
      }),
      columnHelper.accessor('operatingPartners', {
        cell: (info) => (
          <OperatingPartnersCell operatingPartners={info.row.original.operatingPartners} />
        ),
        header: () => <TableHeaderCell text='Operating Partners' />,
        enableSorting: false,
        filterFn: (row, id, filterValue: SelectItem[]) => {
          if (!filterValue.length) return true;
          return filterValue.some(
            (user) =>
              user.id === row.original.operatingPartners?.find((op) => op.id === user.id)?.id
          );
        },
        meta: {
          width: '600px',
          maxWidth: '600px',
          minWidth: '600px',
          tdStyles: { borderRight: `none` },
          thStyles: { borderRight: `none` },
        },
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) => <ActionCell {...info} />,
        header: () => <TableHeaderCell text='' />,
        meta: {
          width: '50px',
          minWidth: '50px',
          maxWidth: '50px',
          tdStyles: { paddingLeft: '0', borderLeft: `none` },
          thStyles: { borderLeft: `none` },
        },
      }),
    ],
    [colors]
  );

  return columns;
};
