import { PageHeader } from '../layout/PageHeader';
import { ContentWrapper } from '../layout/styled';
import { ProxyAssignmentsTable } from './components/ProxyAssignmentsTable';

export const ProxyAssignment = () => {
  return (
    <>
      <PageHeader
        title='Proxy Assignment'
        description=''
        breadcrumbs={[{ title: 'Administration' }, { title: 'Proxy Assignment' }]}
      />
      <ContentWrapper>
        <ProxyAssignmentsTable />
      </ContentWrapper>
    </>
  );
};
