import { useMutation } from 'react-query';
import { Http } from '../../services/Http';
import { TimesheetCell } from '../../types';
import { MUTATION_KEYS } from '../constants';

type Props = {
  payload: UpdateCellPayload;
  id: number;
};

type UpdateCellPayload = {
  [day: string]: number;
};

async function updateTsCell({ payload, id }: Props): Promise<TimesheetCell> {
  const { data } = await Http.axios.patch<UpdateCellPayload, TimesheetCell>(
    `/timesheet-row/${id}`,
    payload
  );
  return data;
}

export const useUpdateTimesheetCell = () => {
  return useMutation(updateTsCell, {
    mutationKey: MUTATION_KEYS.UPDATE_TIMESHEET_CELL,
  });
};
