import { FC, ReactNode } from 'react';
import { styled } from '@mui/material';
import { HTMLMotionProps, motion } from 'framer-motion';

const Tr = styled('tr')<
  HTMLMotionProps<'tr'> & {
    selectable?: string;
    disabled?: boolean;
    disablehover?: string;
  }
>`
  height: 48px;
  position: relative;
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
  &:not(:only-child):hover td {
    ${({ theme, disablehover }) =>
      disablehover === 'true'
        ? ''
        : `background-color: ${theme.colors.surfaceBackground.highlighted};`}
  }
  ${({ selectable }) => (selectable === 'true' ? 'cursor: pointer;' : '')}

  &.selected-row td {
    background-color: ${({ theme }) => theme.colors.surfaceStatus.active};
    border-top: 1px solid ${({ theme }) => theme.colors.surfaceAccent.default};
    border-bottom: 1px solid ${({ theme }) => theme.colors.surfaceAccent.default};
  }
  &.selected-row td:first-of-type {
    border-left: 1px solid ${({ theme }) => theme.colors.surfaceAccent.default};
  }
  &.selected-row td:last-of-type {
    border-right: 1px solid ${({ theme }) => theme.colors.surfaceAccent.default};
  }
  &.no-data {
    background-color: ${({ theme }) => theme.colors.surfaceStatus.active};
    td:first-of-type {
      border-left: none;
    }
  }
`;

interface IProps {
  children?: ReactNode;
  selectable?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  style?: React.CSSProperties;
  disableAnimation?: boolean;
  disableHover?: boolean;
  disableLayoutAnimation?: boolean;
  disableCellHover?: boolean;
}

export const TableRow: FC<IProps> = ({
  children,
  selectable = false,
  disabled,
  onClick,
  className,
  style,
  disableAnimation = false,
  disableHover = false,
  disableLayoutAnimation = false,
}) => {
  return (
    <Tr
      as={motion.tr}
      initial={{ opacity: disableAnimation ? 1 : 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 0 }}
      exit={{ opacity: disableAnimation ? 1 : 0 }}
      layout={disableLayoutAnimation ? false : true}
      selectable={selectable.toString()}
      disabled={disabled}
      onClick={onClick}
      className={className}
      style={style}
      disablehover={disableHover.toString()}
    >
      {children}
    </Tr>
  );
};
