import { Button, styled, useTheme } from '@mui/material';
import { CellContext } from '@tanstack/react-table';
import { useCallback, useState } from 'react';
import { Typography } from '../../../components/Typography/Typography';
import { User } from '../../../types';

import { ReactComponent as AddIcon } from '../../../assets/icons/add-white.svg';
import { AssignDealsModal } from './AssignDealsModal/AssignDealsModal';

const CellWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const ActionsCell = (props: CellContext<User, unknown>) => {
  const { colors } = useTheme();
  const [assignDealsModalOpen, setAssignDealsModalOpen] = useState(false);

  const handleAssignDealsModalOpen = useCallback(() => {
    setAssignDealsModalOpen(true);
  }, []);

  const handleAssignDealsModalClose = useCallback(() => {
    setAssignDealsModalOpen(false);
  }, []);

  const user = props.row.original;
  return (
    <CellWrapper style={{ justifyContent: 'end', paddingRight: '12px' }}>
      <Button
        onClick={handleAssignDealsModalOpen}
        variant='text'
        style={{
          height: '28px',
          backgroundColor: colors.surfaceBackground.bg1,
          border: `1px solid ${colors.border.default}`,
        }}
        startIcon={<AddIcon />}
        sx={{
          '.MuiButton-startIcon': { marginRight: '2px' },
          '&.Mui-disabled': { opacity: 0.4, cursor: 'not-allowed', pointerEvents: 'auto' },
          svg: { g: { path: { fill: colors.icon.accent } } },
        }}
      >
        <Typography variant='h4' color={colors.textAccent.default} style={{ marginBottom: '-2px' }}>
          Assign Deals
        </Typography>
      </Button>
      {assignDealsModalOpen && (
        <AssignDealsModal
          assignDealsModalOpen={assignDealsModalOpen}
          handleAssignDealsModalClose={handleAssignDealsModalClose}
          user={user}
        />
      )}
    </CellWrapper>
  );
};
