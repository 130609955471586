import ContentLoader from 'react-content-loader';
import { styled, useTheme } from '@mui/material/styles';
import { ReactElement } from 'react';

const SkeletonWrapper = styled('div')`
  display: flex;
  flex: 1;
  padding-bottom: 24px;
`;

export function AssignProxySkeletonLoader(): ReactElement {
  const { colors } = useTheme();
  const bgColor = '#EEEFF1';
  return (
    <SkeletonWrapper>
      <ContentLoader
        height={45}
        width={'100%'}
        backgroundColor={colors.skeletonLoader.background}
        foregroundColor={colors.skeletonLoader.foreground}
        speed={2}
      >
        <rect width='602' height='45' y='5' rx='4' fill={bgColor} />
      </ContentLoader>
    </SkeletonWrapper>
  );
}
