import {
  Autocomplete,
  Checkbox,
  TextField,
  PopperProps,
  AutocompleteRenderOptionState,
  ChipProps,
  SxProps,
  FilterOptionsState,
  useTheme,
  Button,
} from '@mui/material';
import {
  FC,
  SyntheticEvent,
  ReactNode,
  JSXElementConstructor,
  HTMLAttributes,
  CSSProperties,
  useCallback,
  useMemo,
  FocusEvent,
  useState,
  useRef,
  KeyboardEvent,
} from 'react';
import { ReactComponent as ClearIcon } from '../../assets/icons/close-blue.svg';
import styled from '@mui/material/styles/styled';
import { SelectItem } from '../../types';
import { Typography } from '../Typography/Typography';
import { useIcons } from '../../hooks/useIcons';

export interface MultiselectProps {
  options: SelectItem[];
  selectedTextMaxWidth?: string;
  value: SelectItem[];
  hideClearIcon?: boolean;
  onChange: (e: SyntheticEvent, value: SelectItem[] | undefined, reason: string) => void;
  listboxStyle?: React.CSSProperties;
  width?: string;
  fullWidth?: boolean;
  limitTags?: number;
  style?: CSSProperties;
  generateLabel?: (number: number) => string;
  renderTags?: (values: SelectItem[]) => JSX.Element;
  fieldPlaceholder?: string;
  openOnfocus?: boolean;
  fieldName?: string; // for Formik
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  disablePortal?: boolean;
  open?: boolean;
  optionName?: string;
  optionsName?: string;
  onOpen?: () => void;
  onClose?: () => void;
  inputVariant?: 'outlined' | 'filled' | 'standard';
  getLimitTagsText?: (more: number) => ReactNode;
  PopperComponent?: JSXElementConstructor<PopperProps>;
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: SelectItem,
    state: AutocompleteRenderOptionState
  ) => ReactNode;
  openOnFocus?: boolean;
  autofocus?: boolean;
  autocomplete?: boolean;
  componentsProps?: {
    clearIndicator?: object;
    paper?: object;
    popper?: object;
    popupIndicator?: object;
  };
  ChipProps?: ChipProps;
  sx?: SxProps;
  isOptionEqualToValue?: (option: SelectItem, value: SelectItem) => boolean;
  ListboxProps?: HTMLAttributes<HTMLUListElement>;
  inputValue?: string;
  onInputChange?: (event: SyntheticEvent, value: string, reason: string) => void;
  autoSelect?: boolean;
  disableClearable?: boolean;
  filterOptions?: (options: SelectItem[], state: FilterOptionsState<SelectItem>) => SelectItem[];
  onKeyDown?:
    | ((
        event: KeyboardEvent<HTMLDivElement> & { defaultMuiPrevented?: boolean | undefined }
      ) => void)
    | undefined;
  alwaysShowPlaceholder?: boolean;
  showAllSelectedLabel?: boolean;
  noOptionsAction?: (value: string) => void;
  noOptionsText?: string;
  highlightedOptionsLabel?: string;
  noHighlightedOptionsLabel?: string;
}

const StyleContainer = styled('div')`
  & .MuiAutocomplete-popup {
    z-index: 98 !important;
  }

  & .MuiAutocomplete-popper {
    z-index: 98 !important;
  }
  
  .MuiInputBase-input {
    color: ${({ theme }) => theme.colors.text.main};
  }

  &&& .MuiInputBase-root.Mui-focused {
    z-index: 98 !important;
    color: ${({ theme }) => theme.colors.text.main};
  }
  .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0 0 0 9px;
  }
  .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 6px 4px 6px 6px;
    min-width: auto;
  }
  .MuiAutocomplete-listbox {
    padding: 4px 8px;
  }
  .MuiFormControl-root.MuiTextField-root:hover .MuiButtonBase-root.MuiAutocomplete-clearIndicator {
    visibility: visible;
  }
  .MuiButtonBase-root.MuiAutocomplete-clearIndicator {
    visibility: hidden;
  }
`;

export const StyledTag = styled('span')`
  display: flex;
  align-items: baseline;
  padding: 0;
  padding-left: 0.5rem;
  font-size: 0.875rem;
`;

const SelectedText = styled('div')<{ maxWidth?: string }>`
  display: inline-block;
  max-width: ${({ maxWidth }) => maxWidth || '6rem'};
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const OptionWrapper = styled('li')<{ isSelectAllOption?: boolean }>`
  &&&.MuiAutocomplete-option {
    padding: ${({ isSelectAllOption }) =>
        isSelectAllOption ? '6px 6px 6px 8px;' : '6px 6px 6px 0px;'}
      6px 6px 6px 0px;
    height: 32px;
    margin: 4px 0;
    border-radius: 4px;
    &.Mui-focused {
      background-color: ${({ theme }) => theme.colors.surfaceInteraction.selectedLight};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.surfaceInteraction.selectedLight};
    }
    &[aria-selected='true'] {
      background-color: ${({ theme }) => theme.colors.surfaceInteraction.selectedLight};
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
export const TitleOptionWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const GroupWrapper = styled('div')`
  padding: 12px 8px;
  margin: 0 8px;
`;

const GroupOptionsWrapper = styled('div')``;

const TOGGLE_ALL_NONE_ID = -1;

export const Multiselect: FC<MultiselectProps> = (props) => {
  const {
    fieldPlaceholder,
    alwaysShowPlaceholder: showPlaceholder,
    fieldName,
    sx,
    selectedTextMaxWidth,
    generateLabel,
    inputVariant = 'outlined',
    autofocus = false,
    openOnfocus = true,
    optionName,
    optionsName,
    renderTags,
    options,
    listboxStyle,
    onChange,
    hideClearIcon = false,
    showAllSelectedLabel = true,
    noOptionsAction,
    noOptionsText,
    highlightedOptionsLabel,
    noHighlightedOptionsLabel,
    ...rest
  } = props;
  const { colors } = useTheme();
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const allSelected = props.value?.length === props.options.length;
  const { PlusIcon } = useIcons();

  const toggleAllNoneOption = useMemo(
    () => ({
      id: TOGGLE_ALL_NONE_ID,
      value: allSelected ? 'Deselect All' : 'Select All',
    }),
    [allSelected]
  );

  const renderDefaultTags = useCallback(
    (options: SelectItem[]) => {
      if (!options?.length) return <></>;

      let text;
      if (allSelected && showAllSelectedLabel)
        text = optionsName
          ? `All ${optionsName}`
          : optionName
          ? `All ${optionName}s`
          : 'All Selected';
      else if (options.length === 1) {
        text = options[0].value;
      } else {
        text = `${options.length} ${
          options.length > 1
            ? optionsName
              ? optionsName
              : optionName
              ? `${optionName}s`
              : 'options'
            : optionName
            ? optionName
            : 'option'
        }`;
      }

      return (
        <StyledTag>
          <SelectedText maxWidth={selectedTextMaxWidth}>
            <Typography variant='body' color={colors.text.main}>
              {generateLabel ? generateLabel(options.length) : text}
            </Typography>
          </SelectedText>
        </StyledTag>
      );
    },
    [
      allSelected,
      colors.text.main,
      generateLabel,
      optionName,
      optionsName,
      selectedTextMaxWidth,
      showAllSelectedLabel,
    ]
  );

  const handleChange = useCallback(
    (e: SyntheticEvent, onChangeOptions: SelectItem[] | undefined, reason: string) => {
      if (onChangeOptions?.find((opt) => opt.id === TOGGLE_ALL_NONE_ID)) {
        if (allSelected) onChange(e, [], reason);
        else onChange(e, options, reason);
      } else {
        onChange(e, onChangeOptions, reason);
      }
    },
    [allSelected, onChange, options]
  );

  const noOptionsActionHandler = () => {
    inputRef.current?.blur();
    noOptionsAction && noOptionsAction(inputValue);
  };

  const optionsWithHighlightedOptionsOnTop = useMemo(() => {
    //sort options. First options with isHighlighted = true, then options with isHighlighted = false
    const sortedOptions = options.sort((a, b) => {
      if (a.isHighlighted && !b.isHighlighted) {
        return -1;
      }
      if (!a.isHighlighted && b.isHighlighted) {
        return 1;
      }
      return 0;
    });
    return sortedOptions;
  }, [options]);

  const optionsPlusToggleOption = [toggleAllNoneOption, ...optionsWithHighlightedOptionsOnTop];

  const hasHighlightedOptions = useMemo(() => {
    return options.some((option) => option.isHighlighted);
  }, [options]);

  const areAllOptionsHighlighted = useMemo(() => {
    return !options.some((option) => !option.isHighlighted);
  }, [options]);

  return (
    <StyleContainer>
      <Autocomplete
        clearIcon={!hideClearIcon ? <ClearIcon /> : null}
        autoHighlight
        renderTags={renderTags ? renderTags : renderDefaultTags}
        getOptionLabel={function (option): string {
          return option?.value ?? '';
        }}
        groupBy={
          hasHighlightedOptions
            ? (o) =>
                o.isHighlighted ? highlightedOptionsLabel ?? '' : noHighlightedOptionsLabel ?? ''
            : undefined
        }
        disableCloseOnSelect
        openOnFocus={openOnfocus}
        options={optionsPlusToggleOption}
        onInputChange={(_, value) => setInputValue(value)}
        noOptionsText={
          noOptionsAction && (options.length || inputValue) ? (
            <Button
              variant='text'
              onClick={noOptionsActionHandler}
              startIcon={<PlusIcon />}
              sx={{ '.MuiButton-startIcon': { marginRight: '0px' } }}
            >
              <Typography
                variant='subtitle1'
                color={colors.textStatus.active}
                style={{ marginBottom: '-2px' }}
              >
                {`${noOptionsText} "${inputValue}"`}
              </Typography>
            </Button>
          ) : (
            <Typography
              variant='subtitle1'
              color={colors.text.caption}
              style={{ marginBottom: '-2px' }}
            >
              {'No options'}
            </Typography>
          )
        }
        ListboxProps={{
          style: { height: '200px', border: `1px solid ${colors.border.default}`, ...listboxStyle },
        }}
        // @ts-ignore
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            autoComplete='off'
            autoFocus={autofocus}
            {...params}
            inputRef={inputRef}
            fullWidth
            variant={inputVariant}
            name={fieldName}
            placeholder={props.value?.length && !showPlaceholder ? '' : fieldPlaceholder}
            // @ts-ignore
            onKeyDown={props.onKeyDown}
          />
        )}
        renderGroup={({ children, group, key }) => (
          <div key={key}>
            {group && (
              <GroupWrapper>
                <Typography variant='srOnly' color={colors.text.caption}>
                  {group}
                </Typography>
              </GroupWrapper>
            )}
            <GroupOptionsWrapper
              style={{
                borderBottom:
                  group === highlightedOptionsLabel && !areAllOptionsHighlighted
                    ? `1px solid ${colors.border.default}`
                    : 'none',
              }}
            >
              {children}
            </GroupOptionsWrapper>
          </div>
        )}
        renderOption={(props, option, { selected }) => {
          return (
            <OptionWrapper
              {...props}
              key={option.id}
              isSelectAllOption={(option.id as number) === -1}
            >
              {(option.id as number) !== -1 && (
                <Checkbox
                  checked={selected}
                  sx={{
                    padding: '0 4px 0 8px',
                    '& .MuiSvgIcon-root': {
                      fontSize: 20,
                    },
                  }}
                />
              )}
              <TitleOptionWrapper style={{ marginBottom: '-1px', overflow: 'hidden' }}>
                {option?.icon && option?.icon}
                <Typography variant='body' color={colors.text.secondary}>
                {option?.value ?? ''}
                </Typography>
              </TitleOptionWrapper>
            </OptionWrapper>
          );
        }}
        disablePortal
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        multiple
        ChipProps={props.ChipProps ?? { size: 'small', sx: { margin: 0 } }}
        sx={{
          "& button[title='Clear'], & button[title='Open'], & button[title='Close']": {
            visibility: 'visible',
            color: colors.icon.default,
          },
          '& .MuiAutocomplete-tag': {
            maxHeight: '21px',
          },
          ...sx,
        }}
        {...rest}
      />
    </StyleContainer>
  );
};
