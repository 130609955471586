import { Box, IconButton, styled, useTheme, Button } from '@mui/material';
import { Typography } from '../Typography/Typography';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-gray.svg';

const CardWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border-radius: 4px;
  width: 50%;
  max-width: 500px;
  background-color: ${({ theme }) => theme.colors.surfaceBackground.highlighted};
  color: ${({ theme }) => theme.colors.text.main};
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  margin-bottom: 2rem;
`;

const CardHeader = styled('div')`
  position: relative;
  button {
    position: absolute;
    top: 0px;
    right: 0px;
  }
`;

interface StartOnboardingCardProps {
  title: string;
  description: string;
  startBtn: string;
  onCloseCard: () => void;
  onStartOnboarding: () => void;
}

function StartOnboardingCard({
  title,
  description,
  startBtn,
  onCloseCard,
  onStartOnboarding,
}: StartOnboardingCardProps) {
  const { colors } = useTheme();
  return (
      <CardWrapper>
        <CardHeader>
          <Typography variant='h2' color={colors.text.main}>
            {title}
          </Typography>
          <IconButton onClick={onCloseCard}>
            <CloseIcon />
          </IconButton>
        </CardHeader>
        <Typography variant='body' color={colors.text.secondary}>
          {description}
        </Typography>
        <Box display='flex' justifyContent='space-between'>
          <Button
            variant='contained'
            onClick={onStartOnboarding}
          >
            {startBtn}
          </Button>
          <Button
            onClick={onCloseCard}
            sx={{
              color: colors.text.secondary,
            }}
          >
            Skip
          </Button>
        </Box>
      </CardWrapper>
  );
}

export default StartOnboardingCard;
