import { Button, styled, useTheme } from '@mui/material';
import { ColumnFiltersState, Table } from '@tanstack/react-table';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import { DatePicker } from '../../../components/DatePicker/DatePicker';
import { Multiselect } from '../../../components/Multiselect/Multiselect';
import { SearchInput } from '../../../components/SearchInput/SearchInput';
import { SingleSelect } from '../../../components/SingleSelect/SingleSelect';
import { Typography } from '../../../components/Typography/Typography';
import { useTimesheets } from '../../../queries/hooks/useTimesheets';
import { userState } from '../../../state/UIState';
import {
  allTableSearch,
  allTableSelectedDate,
  allTableSelectedStatus,
  allTableSelectedOPs,
} from '../../../state/UITimesheetsState';
import { SelectItem, Timesheet, UserRole } from '../../../types';

type Props = {
  columnFilters: ColumnFiltersState;
  table: Table<Timesheet>;
};

export function FilterSection({ table, columnFilters }: Props) {
  const [dateFilterValue, setDateFilterValue] = useAtom(allTableSelectedDate);
  const [searchValue, setSearchValue] = useAtom(allTableSearch);
  const [selectedStatus, setSelectedStatus] = useAtom(allTableSelectedStatus);
  const [selectedOPs, setSelectedOPs] = useAtom(allTableSelectedOPs);

  const { colors } = useTheme();
  const user = useAtomValue(userState);
  const isProxy = user?.role === UserRole.PROXY;

  const { data: allTimesheets } = useTimesheets({
    userId: user?.id,
  });

  const onChangeStatusFilter = useCallback(
    (status: SelectItem | null) => {
      setSelectedStatus(status);
      table.getColumn('status')?.setFilterValue(status?.id ?? '');
    },
    [setSelectedStatus, table]
  );

  const onChangeDateFilter = useCallback(
    (date: Date | null) => {
      //add two hours to date to match the date format
      date?.setHours(date.getHours() + 2);
      const filterValue = date ? date.toISOString() : null;
      setDateFilterValue(filterValue);
    },
    [setDateFilterValue]
  );

  const onChangeOPFilter = useCallback(
    (OPs: SelectItem[] | undefined) => {
      if (OPs) {
        setSelectedOPs(OPs);
        table.getColumn('user')?.setFilterValue(user?.id ?? '');
      }
    },
    [setSelectedOPs, table, user?.id]
  );

  const sortedStatusList = useMemo(
    () =>
      Array.from(table.getColumn('status')?.getFacetedUniqueValues().keys() ?? [])
        .sort()
        .map((value) => ({ id: value, value })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table, allTimesheets]
  );

  const sortedOPList = useMemo(
    () => {
      if (user?.role !== UserRole.PROXY) return [];
      const allOPs = Array.from(table.getColumn('user')?.getFacetedUniqueValues().keys() ?? [])
        .sort()
        .map((user) => ({ id: user.id, value: user.name }));

      const uniqueOPs = Array.from(new Map(allOPs.map((item) => [item.id, item])).values());
      return uniqueOPs;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table, allTimesheets]
  );

  const isResetDisabled = useMemo(() => {
    const filterValues = columnFilters.map((filter) => filter.value);
    if (filterValues.every((val: any) => !val || val.length === 0)) return true;
    return false;
  }, [columnFilters]);

  const onFiltersReset = useCallback(() => {
    setSearchValue('');
    setDateFilterValue(null);
    setSelectedStatus(null);
    setSelectedOPs([]);
  }, [setDateFilterValue, setSearchValue, setSelectedStatus, setSelectedOPs]);

  useEffect(() => {
    table.getColumn('timesheetRows')?.setFilterValue(searchValue);
  }, [searchValue, table]);

  useEffect(() => {
    table.getColumn('weekStart')?.setFilterValue(dateFilterValue);
  }, [dateFilterValue, table]);

  useEffect(() => {
    table.getColumn('status')?.setFilterValue(selectedStatus?.id ?? '');
  }, [selectedStatus, table]);

  useEffect(() => {
    if (!isProxy) return;
    table.getColumn('user')?.setFilterValue(selectedOPs);
  }, [isProxy, selectedOPs, table]);

  return (
    <Container>
      <SearchInput
        placeholder='Search Deals'
        style={{ width: '300px', height: '36px' }}
        onClear={() => setSearchValue('')}
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue ?? ''}
      />
      {isProxy && (
        <Multiselect
          style={{ width: '200px', marginRight: '14px' }}
          fieldPlaceholder='Select OP'
          options={sortedOPList as SelectItem[]}
          value={selectedOPs}
          onChange={(_, OPs) => onChangeOPFilter(OPs)}
        />
      )}
      <SingleSelect
        style={{ width: '200px', marginRight: '14px' }}
        listboxStyle={{ height: '195px' }}
        options={sortedStatusList as SelectItem[]}
        value={selectedStatus}
        onChange={(_, status) => onChangeStatusFilter(status)}
        disablePortal
        fieldPlaceholder='Timesheet Status'
      />
      <DatePicker
        clearable
        selectedDate={dateFilterValue}
        setSelectedDate={onChangeDateFilter}
        disableFuture
        views={['year', 'month']}
        inputFormat='MM/yyyy'
        placeholder='Select Month and Year'
      />
      <Typography variant='caption' color={colors.text.secondary}>
        {`${table.getRowCount()} timesheets`}
      </Typography>
      <Button
        onClick={onFiltersReset}
        variant='text'
        style={{ height: '28px', marginTop: '2px' }}
        disabled={isResetDisabled}
        sx={{
          '.MuiButton-startIcon': { marginRight: '2px' },
          '&.Mui-disabled': { opacity: 0.4, cursor: 'not-allowed', pointerEvents: 'auto' },
        }}
      >
        <Typography variant='subtitle1' color={colors.textAccent.default}>
          Reset Filters
        </Typography>
      </Button>
    </Container>
  );
}

const Container = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 12px;
`;
