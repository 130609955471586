import { PageHeader } from '../layout/PageHeader';
import { ContentWrapper } from '../layout/styled';
import { AllTimesheeetsTable } from './components/AllTimesheeetsTable';

export const OpManagerAllTimesheets = () => {
  return (
    <>
      <PageHeader
        title='All Timesheets'
        description=''
        breadcrumbs={[{ title: 'All Timesheets' }]}
      />
      <ContentWrapper>
        <AllTimesheeetsTable />
      </ContentWrapper>
    </>
  );
};
