import { Calendar, dateFnsLocalizer, EventProps } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { useTheme } from '@mui/material';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import { NavigationSection } from './NavigationSection';
import { styled } from '@mui/system';
import { ColorTokens } from '../../../themes/structure';
import { ReactComponent as USAFlag } from '../../../assets/icons/usa-flag.svg';
import { ReactComponent as UKFlag } from '../../../assets/icons/uk-flag.svg';
import { Typography } from '../../../components/Typography/Typography';
import { ActionCell } from '../hooks/useHolidayTableColumns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { formatLongDateRange } from '../../../utils/formatters';
import { useHolidays } from '../../../queries/hooks/useHolidays';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  addHolidayModalOpen,
  addHolidayModalSelectedStart,
  holidaySearch,
  holidaySelectedCountries,
  holidaySelectedYear,
  holidaySelectedTypes,
  holidaySelectedMonth,
} from '../../../state/UIHolidayScheduleState';
import { CalendarSkeletonLoader } from '../../../components/SkeletonLoader/Calendar.SkeletonLoader';

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export const CalendarViewTab = () => {
  const { colors } = useTheme();
  const searchValue = useAtomValue(holidaySearch);
  const searchCountries = useAtomValue(holidaySelectedCountries);
  const searchTypes = useAtomValue(holidaySelectedTypes);
  const setHolidayModalOpen = useSetAtom(addHolidayModalOpen);
  const setHolidayModalStart = useSetAtom(addHolidayModalSelectedStart);
  const [selectedYear, setSelectedYear] = useAtom(holidaySelectedYear);
  const setSelectedMonth = useSetAtom(holidaySelectedMonth);
  const [date, setDate] = useState(new Date());
  const currentYear = new Date().getFullYear().toString();

  const { data: holidays, isLoading: isHolidayLoading } = useHolidays(selectedYear);

  useEffect(() => {
    const currentMonth = date.getMonth();
    const newDate = new Date(Number(selectedYear || currentYear), currentMonth);
    setDate(newDate);
  }, [selectedYear, currentYear]);

  const calendarEvents = useMemo(() => {
    return (holidays || [])
      .filter((holiday) => holiday.name.toLowerCase().includes(searchValue.toLowerCase()))
      .filter((holiday) => {
        if (!searchCountries.length) return true;
        const holidayCountries = holiday.country.toLowerCase().split(',');
        return holidayCountries.some((country) =>
          searchCountries.map((search) => search.value.toLowerCase()).includes(country)
        );
      })
      .filter((holiday) => {
        if (!searchTypes.length) return true;
        return searchTypes.map((type) => type.value).includes(holiday.type);
      })
      .map((holiday) => {
        return {
          title: holiday.name,
          start: new Date(holiday.startDate),
          end: holiday.endDate ? new Date(holiday.endDate) : new Date(holiday.startDate),
          allDay: true,
          resource: {
            holiday: {
              ...holiday,
              date: holiday.isMultiday
                ? formatLongDateRange(holiday.startDate, holiday.endDate)
                : formatLongDateRange(holiday.startDate),
            },
          },
        };
      });
  }, [holidays, searchValue, searchCountries, searchTypes]);

  const handleNavigate = useCallback(
    (date: Date) => {
      const newYear = date.getFullYear().toString();
      setDate(new Date(date));
      setSelectedMonth(date.getMonth() + 1);
      if (newYear !== selectedYear) {
        setSelectedYear(newYear);
      }
    },
    [selectedYear, setSelectedYear, setSelectedMonth]
  );

  if (isHolidayLoading) return <CalendarSkeletonLoader />;

  return (
    <StyledCalendar
      localizer={localizer}
      style={{
        height: 800,
        color: colors.text.main,
      }}
      date={date}
      events={calendarEvents}
      view='month'
      components={{
        toolbar: NavigationSection,
        event: CustomEvent,
      }}
      selectable
      onSelectSlot={(slot) => {
        setHolidayModalOpen(true);
        setHolidayModalStart(slot.start.toISOString());
      }}
      onNavigate={handleNavigate}
      colors={colors}
    />
  );
};

const CustomEvent = ({ event }: EventProps) => {
  const { colors } = useTheme();
  const [isHovered, setIsHovered] = useState(false);
  const { holiday } = event.resource;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <EventWrapper onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
      <Typography
        variant='caption'
        color={colors.text.secondary}
        style={{
          overflow: 'hidden',
        }}
      >
        {holiday.country.includes('US') && (
          <USAFlag style={{ marginRight: '5px', verticalAlign: 'middle' }} />
        )}
        {holiday.country.includes('UK') && (
          <UKFlag style={{ marginRight: '5px', verticalAlign: 'middle' }} />
        )}
        {holiday.name}
      </Typography>
      {isHovered && <ActionCell holiday={holiday} />}
    </EventWrapper>
  );
};

interface Props {
  colors: ColorTokens;
}

const StyledCalendar = styled(Calendar)`
  .rbc-month-view {
    border: 1px solid ${({ colors }: Props) => colors.border.default};
  }
  .rbc-header {
    border-bottom: 1px solid ${({ colors }: Props) => colors.border.default};
  }
  .rbc-header + .rbc-header {
    border-left: 1px solid ${({ colors }: Props) => colors.border.default};
  }
  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid ${({ colors }: Props) => colors.border.default};
    cursor: pointer;
  }
  .rbc-month-row + .rbc-month-row {
    border-top: 1px solid ${({ colors }: Props) => colors.border.default};
    cursor: pointer;
  }
  .rbc-off-range-bg {
    background-color: ${({ colors }: Props) => colors.surfaceBackground.bg2};
  }
  .rbc-off-range {
    color: ${({ colors }: Props) => colors.textAccent.disabled};
  }
  .rbc-now > button {
    background-color: ${({ colors }: Props) => colors.iconStatus.active};
    color: ${({ colors }: Props) => colors.textAccent.inverse};
  }
  .rbc-event {
    position: relative;
    background-color: ${({ colors }: Props) => colors.surfaceBackground.highlighted};
    margin: 4px 8px;
    width: 90%;
    display: flex;
    align-items: center;
    jusify-content: space-between;
  }
  .rbc-event-content {
    color: ${({ colors }: Props) => colors.text.secondary};
    font-size: 12px;
  }
`;

const EventWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
