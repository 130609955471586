import ContentLoader from 'react-content-loader';
import { styled, useTheme } from '@mui/material/styles';
import { ReactElement } from 'react';

const SkeletonWrapper = styled('div')`
  display: flex;
  flex: 1;
`;

export function TableContentSkeletonLoader(): ReactElement {
  const { colors } = useTheme();
  return (
    <SkeletonWrapper>
      <ContentLoader
        height={700}
        width={'100%'}
        backgroundColor={colors.skeletonLoader.background}
        foregroundColor={colors.skeletonLoader.foreground}
        speed={2}
      >
        <rect y='0' width='100%' height='48' fill='#EEEFF1' />
        <rect y='51' width='100%' height='48' fill='#EEEFF1' />
        <rect y='102' width='100%' height='48' fill='#EEEFF1' />
        <rect y='153' width='100%' height='48' fill='#EEEFF1' />
        <rect y='204' width='100%' height='48' fill='#EEEFF1' />
        <rect y='255' width='100%' height='48' fill='#EEEFF1' />
        <rect y='306' width='100%' height='48' fill='#EEEFF1' />
        <rect y='357' width='100%' height='48' fill='#EEEFF1' />
        <rect y='408' width='100%' height='48' fill='#EEEFF1' />
        <rect y='459' width='100%' height='48' fill='#EEEFF1' />
        <rect y='510' width='100%' height='48' fill='#EEEFF1' />
        <rect y='561' width='100%' height='48' fill='#EEEFF1' />
      </ContentLoader>
    </SkeletonWrapper>
  );
}
