import { Box, Chip, useTheme } from '@mui/material';
import { AvatarCell } from '../../../components/Table/CellRenderers/AvatarCell';
import { Typography } from '../../../components/Typography/Typography';
import { MonthlyTimesheet } from '../../../types';
import { getMonthStatus } from '../../../utils/formatters';

type Props = {
  timesheet: MonthlyTimesheet;
};

export function OperatingPartnerCell({ timesheet }: Props) {
  const { colors } = useTheme();

  const month = timesheet.month;
  const year = timesheet.year;
  const statusValue = getMonthStatus(new Date(year, month - 1, 1));
  const isNewWeek = statusValue === 'New';

  const statusColor = isNewWeek ? colors.textStatus.active : colors.textStatus.warning;

  return (
    <Box
      maxHeight={48}
      minHeight={48}
      display={'flex'}
      alignItems={'center'}
      paddingLeft={'16px'}
      gap={'8px'}
    >
      <AvatarCell
        avatarBoxStyle={{
          background: colors.surfaceIndicator.needsReview,
        }}
        avatarLetterStyle={{
          color: colors.text.main,
        }}
        name={timesheet.user?.name || ''}
      />
      <Chip
        style={{
          backgroundColor: colors.surfaceStatus.noAction,
          minWidth: 'auto',
          height: '28px',
        }}
        label={
          <Typography variant='body' color={statusColor}>
            {statusValue}
          </Typography>
        }
      />
    </Box>
  );
}
