import { Box, styled, useTheme } from '@mui/material';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import * as yup from 'yup';
import { FormValidationMessage } from '../../../../components/FormValidationMessage/FormValidationMessage';
import { ModalComponent } from '../../../../components/ModalComponent/ModalComponent';
import { useLoadingBar } from '../../../../hooks/useLoadingBar';
import { useToastMessage } from '../../../../hooks/useToastMessage';
import { useEditUser } from '../../../../queries/hooks/useEditUser';
import { LoadingId, SelectItem, USER_COUNTRY, User, UserRole } from '../../../../types';
import { DealOwnerFields } from './DealOwnerFields';
import { OPManagerFields } from './OPManagerFields';
import { OperatingPartnerFields } from './OperatingPartnerFields';
import { ProxyFields } from './ProxyFields';
import { RolesSection } from './RolesSection';
import { createAddUserPayload, createEditUserPayload } from './helpers';
import { BasicUserFields } from './BasicUserFields';
import { useAddUser } from '../../../../queries/hooks/useAddUser';
import { useAtomValue } from 'jotai';
import { userState } from '../../../../state/UIState';
import { useOktaAuth } from '@okta/okta-react';
import { FormField } from '../../../../components/FormField/FormField';
import { Typography } from '../../../../components/Typography/Typography';
import { Switch } from '../../../../components/SwitchButton/Switch';

type Props = {
  userProfileOpen: boolean;
  handleUserProfilClose: () => void;
  user?: User;
};

export interface IUserFileds {
  name: string;
  email: string;
  role: string;
  isAdmin: boolean;
  isOp: boolean;
  isSelfApprovingEnabled?: boolean;
  managers?: SelectItem[];
  dealOwnerDeals?: SelectItem[];
  operatingPartnerDeals?: SelectItem[];
  operatingPartners?: SelectItem[];
  proxyOperatingPartners?: SelectItem[];
  proxy?: SelectItem | null;
  startDate?: string;
  endDate?: string;
  country?: USER_COUNTRY;
}

export function UserModal({ userProfileOpen, handleUserProfilClose, user }: Props) {
  const loggedUser = useAtomValue(userState);
  const { oktaAuth } = useOktaAuth();
  const { colors } = useTheme();

  const { pushSuccessToast, pushErrorToast } = useToastMessage();

  const { startLoading, stopLoading } = useLoadingBar();

  const { mutate: onEditUser, isLoading: editInProgress } = useEditUser();
  const { mutate: onAddUser, isLoading: addInProgress } = useAddUser();

  const initialValues = useMemo(() => {
    const proxyDefaultValue = user?.proxy
      ? ({ id: user.proxy.id, value: user?.proxy.name } as SelectItem)
      : null;
    return {
      name: user?.name || '',
      email: user?.email || '',
      role: user?.role || '',
      isAdmin: Boolean(user?.isAdmin),
      isOp: Boolean(user?.isOp),
      isSelfApprovingEnabled: Boolean(user?.isSelfApprovingEnabled),
      managers: (user?.operatingPartnerManagers || [])
        .filter((manager) => manager.email !== user?.email)
        .map((manager) => ({
          id: manager?.id,
          value: manager?.name,
        })),
      dealOwnerDeals: (user?.dealOwnerDeals || []).map((deal) => ({
        id: deal?.id,
        value: deal?.name,
      })),
      operatingPartnerDeals: (user?.operatingPartnerDeals || []).map((deal) => ({
        id: deal?.id,
        value: deal?.name,
      })),
      proxyOperatingPartners: (user?.proxyOperatingPartners || []).map((partner) => ({
        id: partner?.id,
        value: partner?.name,
      })),
      operatingPartners: (user?.operatingPartners || [])
        .filter((partner) => partner.email !== user?.email)
        .map((partner) => ({
          id: partner?.id,
          value: partner?.name,
        })),
      proxy: proxyDefaultValue,
      startDate: user?.startDate,
      endDate: user?.endDate,
      country: user?.country,
    };
  }, [user]);

  const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    email: yup.string().email().required('Email is required'),
    role: yup.string().required('Role is required'),
    managers: yup
      .mixed()
      .when(['role', 'isOp', 'isSelfApprovingEnabled'], ([role, isOp, isSelfApprovingEnabled]) => {
        if (
          role === UserRole.OPERATING_PARTNER ||
          (role === UserRole.OP_MANAGER && isOp && !isSelfApprovingEnabled)
        ) {
          return yup.array().min(1, 'Manager is required');
        }
        return yup.mixed().nullable();
      }),
    dealOwnerDeals: yup.mixed().nullable(),
    operatingPartnerDeals: yup.mixed().when('isOp', ([isOp]) => {
      if (isOp) return yup.array().min(1, 'Deal is required');
      return yup.mixed().nullable();
    }),
    startDate: yup.mixed().when('isOp', ([isOp]) => {
      if (isOp) return yup.mixed().required('Start Date is required');
      return yup.mixed().nullable();
    }),
    country: yup.mixed().when('isOp', ([isOp]) => {
      if (isOp) return yup.mixed().required('Country is required');
      return yup.mixed().nullable();
    }),
    proxy: yup.mixed().nullable(),
    operatingPartners: yup.mixed().nullable(),
    proxyOperatingPartners: yup.mixed().nullable(),
  });

  const labelStyle = {
    color: colors.text.caption,
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      validateOnBlur
      initialValues={initialValues}
      validateOnMount
      validateOnChange
      onSubmit={(values) => {
        if (user) {
          startLoading(LoadingId.editUser);
          const payload = createEditUserPayload(values);
          onEditUser(
            {
              payload,
              id: user.id,
            },
            {
              onSuccess: () => {
                pushSuccessToast({
                  title: 'Profile Updated',
                  message: `${user?.name} ${values.role} Profile has been successfully updated.`,
                });
                if (loggedUser?.email === user.email && initialValues.isAdmin !== values.isAdmin) {
                  oktaAuth.signOut();
                }
                handleUserProfilClose();
              },
              onError: () => {
                pushErrorToast({ message: 'Failed to update user profile' });
              },
              onSettled: () => {
                stopLoading(LoadingId.editUser);
              },
            }
          );
        } else {
          startLoading(LoadingId.addUser);
          const payload = createAddUserPayload(values);
          onAddUser(
            {
              payload,
            },
            {
              onSuccess: () => {
                pushSuccessToast({
                  title: 'User Added',
                  message: `${values.name} ${values.role} User has been successfully added.`,
                });
                handleUserProfilClose();
              },
              onError: (error: any) => {
                if (error.response.status === 409) {
                  pushErrorToast({ message: error.response.data.message, title: 'Error' });
                  return;
                }
                pushErrorToast({ message: 'Failed to add a user.', title: 'Error' });
              },
              onSettled: () => {
                stopLoading(LoadingId.addUser);
              },
            }
          );
        }
      }}
    >
      {({ handleSubmit, errors, values, setFieldValue }) => {
        const hasValidationErrors = !!Object.keys(errors)?.length;
        return (
          <Form>
            <ModalComponent
              isOpen={userProfileOpen}
              onClose={handleUserProfilClose}
              actionButtonsProps={{
                isSubmitDisabled: editInProgress || addInProgress || hasValidationErrors,
                onSubmit: handleSubmit,
                onCancel: handleUserProfilClose,
                submitText: user ? 'Update Profile' : 'Add User',
              }}
              title={user ? `Edit ${user?.name} Profile` : 'Add a User'}
              top='80px'
              width='640px'
            >
              <Content>
                <BasicUserFields disableFields={!!user} />
                <RolesSection user={user} />
                {values?.role === UserRole.OPERATING_PARTNER && <OperatingPartnerFields />}
                {values?.role === UserRole.PROXY && <ProxyFields />}
                {values?.role === UserRole.OP_MANAGER && <OPManagerFields />}
                {values?.role === UserRole.DEAL_OWNER && <DealOwnerFields />}
                {values?.role && (
                  <FormField label='Permissions' style={labelStyle}>
                    <Box display='flex' gap={'10px'} alignItems='center'>
                      <Switch
                        checked={values.isAdmin}
                        onChange={() => setFieldValue('isAdmin', !values.isAdmin)}
                      />
                      <Typography variant='body' color={colors.text.main}>
                        Administration
                      </Typography>
                    </Box>
                  </FormField>
                )}
                {hasValidationErrors && (
                  <FormValidationMessage text='Please, enter the missing details to add a Team Member.' />
                )}
              </Content>
            </ModalComponent>
          </Form>
        );
      }}
    </Formik>
  );
}

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 65vh;
  overflow-y: scroll;
`;
