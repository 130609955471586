import { useAtomValue } from 'jotai';
import { userState } from '../state/UIState';
import { MonthlyTimesheet, TimesheetStatusLabel } from '../types';

export function useDealOwnerTimesheetStatus(timesheet?: MonthlyTimesheet) {
  const user = useAtomValue(userState);

  if (!timesheet) return;

  const userIncluded = !!timesheet?.approvedByDealOwners?.find((item) => item.id === user?.id);

  const status =
    userIncluded && timesheet.status === TimesheetStatusLabel.SUBMITTED
      ? TimesheetStatusLabel.CLOSED
      : timesheet?.status;

  return status;
}
