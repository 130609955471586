import { styled, useTheme } from '@mui/material';
import { Typography } from '../Typography/Typography';

type Props = {
  text: string;
  textColor?: string;
  Icon?: JSX.Element;
};

export function FormValidationMessage({ text, textColor, Icon }: Props) {
  const { colors } = useTheme();
  return (
    <Container>
      {Icon && Icon}
      <Typography variant='body' color={textColor || colors.text.main}>
        {text}
      </Typography>
    </Container>
  );
}

const Container = styled('div')`
  display: flex;
  align-items: center;
  gap: 5px;
  background: ${({ theme }) => theme.colors.surfaceStatus.noAction};
  padding: 12px;
  font-size: 14px;
`;
