import { Box, Chip, useTheme } from '@mui/material';
import { useCallback, useState } from 'react';
import TooltipComponent from '../../../components/TooltipComponent/TooltipComponent';
import { Typography } from '../../../components/Typography/Typography';
import { User } from '../../../types';

type Props = {
  deals: User['operatingPartnerDeals'];
};

const maxDealsToShow = 4;
function DealCell({ deals }: Props) {
  const { colors } = useTheme();
  const [restDealsTooltipOpen, setRestDealsTooltipOpen] = useState(false);

  const handleRestDealsTooltipOpen = useCallback(() => {
    setRestDealsTooltipOpen(true);
  }, []);

  const handleRestDealsTooltipClose = useCallback(() => {
    setRestDealsTooltipOpen(false);
  }, []);

  const dealsWithId = (deals || []).filter((deal) => deal.id !== 0);

  const slicedDeals = dealsWithId?.slice(0, maxDealsToShow) || [];

  const restDeals = dealsWithId?.slice(maxDealsToShow, dealsWithId.length);

  const restDealsNumber = restDeals?.length;

  return (
    <Box display={'flex'} gap={1}>
      {slicedDeals.map((deal) => {
        return (
          <Chip
            key={deal.id}
            style={{
              backgroundColor: colors.surfaceStatus.noAction,
              minWidth: '66px',
              height: '28px',
            }}
            label={
              <Typography variant='body' color={colors.text.main}>
                {deal?.name}
              </Typography>
            }
          />
        );
      })}
      {restDealsNumber ? (
        <TooltipComponent
          open={!!restDealsNumber && restDealsTooltipOpen}
          onOpen={handleRestDealsTooltipOpen}
          onClose={handleRestDealsTooltipClose}
          content={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',

                gap: 1,
              }}
            >
              {restDeals?.map((item) => {
                return (
                  <Typography variant='body' color={colors.text.main} key={item.id}>
                    {item.name}
                  </Typography>
                );
              })}
            </Box>
          }
        >
          <Chip
            style={{
              backgroundColor: colors.surfaceStatus.noAction,
              minWidth: '66px',
              height: '28px',
            }}
            onClick={handleRestDealsTooltipOpen}
            label={
              <Typography variant='body' color={colors.text.main}>
                + {restDealsNumber} Deal
              </Typography>
            }
          />
        </TooltipComponent>
      ) : null}
    </Box>
  );
}

export default DealCell;
