import { Chip, styled, useTheme } from '@mui/material';
import { Typography } from '../../../../components/Typography/Typography';
import { UserAvatar } from '../../../../components/UserAvatar/UserAvatar';
import { User } from '../../../../types';

type Props = {
  user: User;
  totalDeals: number;
};
export function UserInfo({ user, totalDeals }: Props) {
  const { colors } = useTheme();

  return (
    <Container>
      <AvatarWrap>
        <UserAvatar
          style={{
            width: 28,
            height: 28,
          }}
          name={user?.name}
          size='large'
          lettersStyle={{
            color: colors.textAccent.inverse,
            fontSize: 14,
          }}
        />
        {user.name}
      </AvatarWrap>
      <Chip
        style={{
          backgroundColor: colors.surfaceStatus.noAction,
          minWidth: '66px',
          height: '30px',
        }}
        label={
          <Typography variant='body' color={colors.text.main}>
            {user?.role}
          </Typography>
        }
      />
      <div>{totalDeals} Deals</div>
    </Container>
  );
}

export default UserInfo;

const Container = styled('div')`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const AvatarWrap = styled('div')`
  display: flex;
  gap: 10px;
  align-items: center;
`;
