import { Box, Chip, useTheme } from '@mui/material';
import { AvatarCell } from '../../../components/Table/CellRenderers/AvatarCell';
import { Timesheet, TimesheetStatus } from '../../../types';
import { getWeekStatus } from '../../../utils/formatters';
import { Typography } from '../../../components/Typography/Typography';

type Props = {
  timesheet: Timesheet;
};

export function ProxyOperatingPartnerCell({ timesheet }: Props) {
  const { colors } = useTheme();

  const weekStart = timesheet.weekStart;
  const statusValue = getWeekStatus(weekStart);
  const isNewWeek = statusValue === 'New';

  const statusColor = isNewWeek ? colors.textStatus.active : colors.textStatus.warning;

  const notIssuedStatus = timesheet.status !== TimesheetStatus.ISSUED;
  const borderColor = isNewWeek
    ? colors.textStatus.active
    : notIssuedStatus
    ? colors.surfaceBackground.highlighted
    : colors.textStatus.warning;

  return (
    <Box
      borderLeft={'4px solid'}
      borderColor={borderColor}
      maxHeight={48}
      minHeight={48}
      display={'flex'}
      alignItems={'center'}
      paddingLeft={'16px'}
      gap={'8px'}
    >
      <AvatarCell
        avatarBoxStyle={{
          background: colors.surfaceIndicator.needsReview,
        }}
        avatarLetterStyle={{
          color: colors.text.main,
        }}
        name={timesheet.user?.name || ''}
      />
      <Chip
        style={{
          backgroundColor: colors.surfaceStatus.noAction,
          minWidth: 'auto',
          height: '28px',
        }}
        label={
          <Typography variant='body' color={statusColor}>
            {statusValue}
          </Typography>
        }
      />
    </Box>
  );
}
