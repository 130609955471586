import { PageHeader } from '../layout/PageHeader';
import { ContentWrapper } from '../layout/styled';
import { AssigmentUserTable } from './components/AssigmentUserTable';

export const TimesheetAssignment = () => {
  return (
    <>
      <PageHeader
        title='Timesheet Assignment'
        description=''
        breadcrumbs={[{ title: 'Administration' }, { title: 'Timesheet Assignment' }]}
      />
      <ContentWrapper>
        <AssigmentUserTable />
      </ContentWrapper>
    </>
  );
};
