import { Chip, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { useTimesheetStatusColor } from '../../../hooks/useTimesheetStatusColor';
import { TimesheetStatusLabel } from '../../../types';

type Props = {
  status: TimesheetStatusLabel;
};
export function StatusCell({ status }: Props) {
  const { colors } = useTheme();

  const statusColor = useTimesheetStatusColor(status);

  return (
    <Chip
      style={{
        backgroundColor: colors.surfaceStatus.noAction,
        minWidth: '66px',
        height: '28px',
      }}
      label={
        <Typography variant='body' color={statusColor}>
          {/* //TODO change text color based on status */}
          {status}
        </Typography>
      }
    />
  );
}
