import { Box, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { FormField } from '../../../../components/FormField/FormField';
import { Multiselect } from '../../../../components/Multiselect/Multiselect';
import { SelectItem, UserRole } from '../../../../types';
import { IUserFileds } from './UserModal';
import { UpdateUserSkeletonLoader } from '../../../../components/SkeletonLoader/UpdateUser.SkeletonLoader';
import { useUsers } from '../../../../queries/hooks/useUsers';
import { Switch } from '../../../../components/SwitchButton/Switch';
import { Typography } from '../../../../components/Typography/Typography';
import { OperatingPartnerFields } from './OperatingPartnerFields';

export function OPManagerFields() {
  const { colors } = useTheme();
  const { isLoading: areUsersLoading, data: users } = useUsers();

  const { setFieldValue, values, touched, errors, setFieldTouched, validateForm } =
    useFormikContext<IUserFileds>();
  const handleError = (field) => (touched[field] ? errors[field] : '');

  useEffect(() => {
    validateForm();
  }, [validateForm, values.isOp]);

  const operatingPartnersOptions = useMemo(() => {
    if (!users) return [];
    return users
      .filter(
        (user) =>
          user.email !== values.email && (user.role === UserRole.OPERATING_PARTNER || user.isOp)
      )
      .map((user) => ({
        id: user.id,
        value: user.name,
      }));
  }, [users, values.email]);

  const labelStyle = {
    color: colors.text.caption,
  };

  return areUsersLoading ? (
    <UpdateUserSkeletonLoader />
  ) : (
    <>
      <FormField
        label={'Operating Partners (Optional)'}
        error={handleError('operatingPartners')}
        labelStyle={labelStyle}
      >
        <Multiselect
          listboxStyle={{ height: '195px' }}
          options={operatingPartnersOptions}
          value={values?.operatingPartners as SelectItem[]}
          onChange={(_, values) => {
            setFieldValue('operatingPartners', values);
          }}
          onBlur={() => {
            setFieldTouched('operatingPartners');
          }}
          disablePortal
          fieldPlaceholder='Type to Search Operating Partners'
        />
      </FormField>

      <FormField label='Timesheets' style={labelStyle}>
        <Box display='flex' gap={'10px'} alignItems='center'>
          <Switch
            checked={values.isOp}
            onChange={() => {
              setFieldValue('isOp', !values.isOp);
              setFieldValue('isSelfApprovingEnabled', !values.isOp);
            }}
          />
          <Typography variant='body' color={colors.text.main}>
            Enable Timesheet submission
          </Typography>
        </Box>
      </FormField>

      {values.isOp && (
        <>
          <FormField label='Self-Approval for Timesheet' style={labelStyle}>
            <Box display='flex' gap={'10px'} alignItems='center'>
              <Switch
                checked={values.isSelfApprovingEnabled}
                onChange={() =>
                  setFieldValue('isSelfApprovingEnabled', !values.isSelfApprovingEnabled)
                }
              />
              <Typography variant='body' color={colors.text.main}>
                Enable Self-Approval
              </Typography>
            </Box>
          </FormField>
          <OperatingPartnerFields isOpManager={true} />
        </>
      )}
    </>
  );
}
