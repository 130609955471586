import { atom } from 'jotai';
import { SelectItem } from '../types';

// Op manager all timesheets filters
export const allTableSelectedDate = atom<string | null>(null);
export const allTableSelectedStatus = atom<SelectItem | null>(null);
export const allTableSelectedOPs = atom<SelectItem[]>([])

//Op manager operating partners filters
export const opPartnersTableSearch = atom<string>('');
export const opPartnersSelectedOPs = atom<SelectItem[]>([])

//Op manager submitted filters
export const submittedTableSelectedDate = atom<string | null>(null);
export const submittedTableSelectedOPs = atom<SelectItem[]>([])
