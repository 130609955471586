import { WeekDay, WeeklyTimesheet } from '../../../types';

const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'] as WeekDay[];

export function calcTotalPerWeekday(weekday: WeekDay, weeklyTimesheets: WeeklyTimesheet[]) {
  const numOfWeeksForWeekday = weeklyTimesheets.filter(
    (weeklyTS) =>
      weeklyTS.timesheetRows[0][weekday] !== null ||
      weeklyTS.timesheetRows.find((row) => row.name === 'Company Holiday' && row.dealId === 0)![
        weekday
      ] !== null
  ).length;
  if (!numOfWeeksForWeekday) return 0;
  return (
    weeklyTimesheets.reduce((acc, week) => {
      return (
        acc +
        week.timesheetRows.reduce((acc, row) => {
          return acc + row[`${weekday}`];
        }, 0)
      );
    }, 0) / numOfWeeksForWeekday
  );
}

export function calcWeekdayTotal(weeklyTimesheets: WeeklyTimesheet[]) {
  return (
    weekdays.reduce((acc, weekday) => {
      return acc + calcTotalPerWeekday(weekday, weeklyTimesheets);
    }, 0) / weekdays.length
  );
}

export function calcTotalWeekdayPerDeal(
  weekday: WeekDay,
  weeklyTimesheets: WeeklyTimesheet[],
  dealId: number,
  dealName: string
) {
  const numOfWeeksForWeekday = weeklyTimesheets.filter(
    (weeklyTS) =>
      weeklyTS.timesheetRows[0][weekday] !== null ||
      weeklyTS.timesheetRows.find((row) => row.name === 'Company Holiday' && row.dealId === 0)![
        weekday
      ] !== null
  ).length;
  if (!numOfWeeksForWeekday) return 0;
  return (
    weeklyTimesheets.reduce((acc, week) => {
      const timesheetRow = week.timesheetRows.find(
        (row) => row.dealId === dealId && row.name === dealName
      );
      if (timesheetRow) {
        return acc + timesheetRow[weekday];
      }
      return acc;
    }, 0) / numOfWeeksForWeekday
  );
}

export function calcTotalPerDay(weeklyTimesheet: WeeklyTimesheet) {
  return weekdays.map((day) => {
    // if (!weeklyTimesheet.timesheetRows[0][day.toLowerCase()]) return 'N/A';
    return (
      weeklyTimesheet.timesheetRows.reduce((sum, row) => sum + row[day.toLowerCase()], 0) + '%'
    );
  });
}
