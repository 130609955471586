import { MonthlyTimesheet, TimesheetStatusLabel, User } from '../types';

export function getIsPendingDealOwnerStatus(timesheet?: MonthlyTimesheet, user?: User | null) {
  if (!timesheet || !user) return null;

  return (
    timesheet.status === TimesheetStatusLabel.SUBMITTED &&
    !timesheet?.approvedByDealOwners?.find((item) => item.id === user?.id)
  );
}
