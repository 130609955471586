import { Timesheet } from '../types';

export const getWeeklyTimesheetWeekName = (timesheet: Timesheet) => {
  let weekStart = '';
  if (timesheet.weekDates.length && timesheet.holidays.length) {
    const firstWeekStartDate = new Date(timesheet.weekDates[0]);
    const year = timesheet.holidays[0].year || timesheet.weekStart?.split('-')[0];
    const holidayString = `${year}-${timesheet.holidays[0].month}-${timesheet.holidays[0].day}`;
    const firstHolidayDate = new Date(holidayString);
    return firstWeekStartDate < firstHolidayDate ? timesheet.weekDates[0] : holidayString;
  }
  if (timesheet.weekDates.length) {
    weekStart = timesheet.weekDates[0];
  }
  if (timesheet.holidays.length) {
    const year = timesheet.holidays[0].year || timesheet.weekStart?.split('-')[0];
    weekStart = `${year}-${timesheet.holidays[0].month}-${timesheet.holidays[0].day}`;
  }
  return weekStart;
};
