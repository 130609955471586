import { useMutation } from 'react-query';
import { Http } from '../../services/Http';
import { EditUserPayload, User } from '../../types';
import { MUTATION_KEYS } from '../constants';
import { useInvalidateUsers } from './useUsers';
import { useInvalidateDeals } from './useDeals';

type Props = {
  payload: EditUserPayload;
  id: number;
};

async function editUser({ payload, id }: Props): Promise<User[]> {
  const { data } = await Http.axios.patch<EditUserPayload, User[]>(`user/${id}`, payload);
  return data;
}

export const useEditUser = () => {
  const invalidateUsers = useInvalidateUsers();
  const invalidateDeals = useInvalidateDeals();

  return useMutation(editUser, {
    mutationKey: MUTATION_KEYS.EDIT_USER_KEY,
    onSuccess: () => {
      invalidateUsers();
      invalidateDeals();
    },
  });
};
