export enum ROUTES {
  ISSUED_TIMESHEETS = 'issued-timesheets',
  PENDING_TIMESHEETS = 'pending-timesheets',
  OP_MANAGER_SUBMITTED_TIMESHEET = 'op-manager-submitted-timesheets',
  OP_MANAGER_ALL_TIMESHEETS = 'op-manager-all-timesheets',
  OP_MANAGER_ALL_OPERATING_PARTNERS = 'op-manager-all-operating-partners',
  ALL_TIMESHEETS = 'all-timesheets',
  TIMESHEETS_ASSIGNMENT = 'timesheets-assignment',
  PROXY_ASSIGNMENT = 'proxy-assignment',
  USER_ADMINISTRATION = 'user-administration',
  DEALS = 'deals',
  DEAL_OWNER_PENDING_TIMESHEETS = 'deal-owner-pending-timesheets',
  DEAL_OWNER_ALL_TIMESHEETS = 'deal-owner-all-timesheets',
  DEAL_OWNER_DEALS = 'deal-owner-deals',
  ACCOUNTANT_TIMESHEETS_STATUSES = 'accountant-timesheets-statuses',
  ACCOUNTANT_OP_OVERVIEW = 'accountant-op-overview',
  EVENT_SCHEDULE = 'event-schedule'
}
