import { useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { FormField } from '../../../../components/FormField/FormField';
import { Multiselect } from '../../../../components/Multiselect/Multiselect';

import { SelectItem, UserRole } from '../../../../types';
import { IUserFileds } from './UserModal';
import { UpdateUserSkeletonLoader } from '../../../../components/SkeletonLoader/UpdateUser.SkeletonLoader';
import { useUsers } from '../../../../queries/hooks/useUsers';

export function ProxyFields() {
  const { colors } = useTheme();
  const { isLoading: areUsersLoading, data: users } = useUsers();

  const { setFieldValue, values, touched, errors, setFieldTouched } =
    useFormikContext<IUserFileds>();
  const handleError = (field) => (touched[field] ? errors[field] : '');

  const operatingPartnersOptions = useMemo(() => {
    if (!users) return [];
    return users
      .filter((user) => user.role === UserRole.OPERATING_PARTNER || user.isOp)
      .map((user) => ({
        id: user.id,
        value: user.name,
      }));
  }, [users]);

  const labelStyle = {
    color: colors.text.caption,
  };

  return areUsersLoading ? (
    <UpdateUserSkeletonLoader />
  ) : (
    <>
      <FormField
        label={'Operating Partners (Optional)'}
        error={handleError('proxyOperatingPartners')}
        labelStyle={labelStyle}
      >
        <Multiselect
          listboxStyle={{ height: '195px' }}
          options={operatingPartnersOptions}
          value={values?.proxyOperatingPartners as SelectItem[]}
          onChange={(_, values) => {
            setFieldValue('proxyOperatingPartners', values);
          }}
          onBlur={() => {
            setFieldTouched('proxyOperatingPartners');
          }}
          disablePortal
          fieldPlaceholder='Type to Search Operating Partners'
        />
      </FormField>
    </>
  );
}
