import { useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { TimesheetRow } from '../../../types';
import { AvatarCell } from '../../../components/Table/CellRenderers/AvatarCell';
import { TableHeaderCell } from '../../../components/Table/CellRenderers/TableHeaderCell';
import { Typography } from '../../../components/Typography/Typography';
import { EditableNumberCell } from '../components/SingleTimesheetTable/EditableNumberCell';
import TotalCell from '../components/SingleTimesheetTable/TotalCell';
import { TableHeaderCellWithClear } from '../components/SingleTimesheetTable/TableHeaderCellWithClear';
import { addDaysToDate, formatUSDate, getDayLabel } from '../../../utils/formatters';

const columnHelper = createColumnHelper<TimesheetRow>();

export const useSingleTableColumns = (weekStart: string, weekDates: string[]) => {
  const { colors } = useTheme();

  const getDateLabel = useCallback(
    (dayOrder: number) => {
      if (weekStart) {
        const date = addDaysToDate(weekStart, dayOrder - 1);
        const formattedDate = formatUSDate(date.toISOString());
        return `${getDayLabel(dayOrder).slice(0, 3)}, ${formattedDate}`;
      }
      return '';
    },
    [weekStart]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => {
          const isFixedDeal = info.row.original.dealId === 0;
          return (
            <AvatarCell
              name={info.row.original.name}
              avatarBoxStyle={{
                background: isFixedDeal
                  ? colors.surfaceBackground.highlighted
                  : colors.surfaceIndicator.needsReview,
              }}
              avatarLetterStyle={{
                color: colors.text.main,
              }}
            />
          );
        },
        enableSorting: false,

        header: () => <TableHeaderCell text='Deal' />,
        footer: () => {
          return (
            <Typography
              variant='body'
              color={colors.text.main}
              style={{
                paddingLeft: '30px',
                fontSize: '17px',
              }}
            >
              Total per day
            </Typography>
          );
        },
        meta: {
          tdStyles: { paddingLeft: '16px', borderRight: `none` },
          thStyles: { borderRight: `none` },
        },
      }),
      columnHelper.accessor('monday', {
        cell: (info) => {
          return (
            <EditableNumberCell
              getValue={info.getValue}
              row={info.row}
              column={info.column}
              table={info.table}
              headerDate={getDateLabel(1)}
              weekDates={weekDates}
            />
          );
        },
        header: (row) => <TableHeaderCellWithClear text={getDateLabel(1)} row={row} />,
        footer: TotalCell,
        enableSorting: false,
        meta: {
          width: '16%',
          maxWidth: '16%',
          minWidth: '16%',
          tdStyles: { paddingLeft: '16px', borderRight: `none` },
          thStyles: {
            borderRight: `none`,
          },
        },
      }),
      columnHelper.accessor('tuesday', {
        cell: (info) => {
          return (
            <EditableNumberCell
              getValue={info.getValue}
              row={info.row}
              column={info.column}
              table={info.table}
              headerDate={getDateLabel(2)}
              weekDates={weekDates}
            />
          );
        },

        header: (row) => <TableHeaderCellWithClear text={getDateLabel(2)} row={row} />,
        footer: TotalCell,
        enableSorting: false,

        meta: {
          width: '16%',
          maxWidth: '16%',
          minWidth: '16%',
          tdStyles: { paddingLeft: '16px', borderRight: `none` },
          thStyles: { borderRight: `none` },
        },
      }),
      columnHelper.accessor('wednesday', {
        cell: (info) => {
          return (
            <EditableNumberCell
              getValue={info.getValue}
              row={info.row}
              column={info.column}
              table={info.table}
              headerDate={getDateLabel(3)}
              weekDates={weekDates}
            />
          );
        },
        header: (row) => <TableHeaderCellWithClear text={getDateLabel(3)} row={row} />,
        footer: TotalCell,
        enableSorting: false,

        meta: {
          width: '16%',
          maxWidth: '16%',
          minWidth: '16%',
          tdStyles: { paddingLeft: '16px', borderRight: `none` },
          thStyles: { borderRight: `none` },
        },
      }),
      columnHelper.accessor('thursday', {
        cell: (info) => {
          return (
            <EditableNumberCell
              getValue={info.getValue}
              row={info.row}
              column={info.column}
              table={info.table}
              headerDate={getDateLabel(4)}
              weekDates={weekDates}
            />
          );
        },
        header: (row) => <TableHeaderCellWithClear text={getDateLabel(4)} row={row} />,
        footer: TotalCell,
        enableSorting: false,

        meta: {
          width: '16%',
          maxWidth: '16%',
          minWidth: '16%',
          tdStyles: { paddingLeft: '16px', borderRight: `none` },
          thStyles: { borderRight: `none` },
        },
      }),
      columnHelper.accessor('friday', {
        cell: (info) => {
          return (
            <EditableNumberCell
              getValue={info.getValue}
              row={info.row}
              column={info.column}
              table={info.table}
              headerDate={getDateLabel(5)}
              weekDates={weekDates}
            />
          );
        },
        header: (row) => <TableHeaderCellWithClear text={getDateLabel(5)} row={row} />,
        footer: TotalCell,
        enableSorting: false,

        meta: {
          width: '16%',
          maxWidth: '16%',
          minWidth: '16%',
          tdStyles: { paddingLeft: '16px', borderRight: `none`, paddingRight: '16px' },
          thStyles: { borderRight: `none`, paddingRight: '16px' },
        },
      }),
    ],
    [colors, getDateLabel, weekDates]
  );

  return columns;
};
