import { Button, IconButton, styled, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { ToolbarProps } from 'react-big-calendar';
import { ReactComponent as ChevronRightIcon } from '../../../assets/icons/chevron-right.svg';
import { Typography } from '../../../components/Typography/Typography';
import { useAtomValue } from 'jotai';
import { holidaySelectedYear } from '../../../state/UIHolidayScheduleState';

export const NavigationSection = (toolbar: ToolbarProps) => {
  const { colors } = useTheme();
  const year = useAtomValue(holidaySelectedYear);

  const label = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const { date } = toolbar;
    const month = date.toLocaleString('en-US', { month: 'long' });
    return `${month} ${year || currentYear}`;
  }, [toolbar, year]);

  return (
    <NavigationWrapper>
      <IconButton
        onClick={() => toolbar.onNavigate('PREV')}
        sx={{
          svg: { path: { fill: colors.icon.accent, opacity: '1' } },
          backgroundColor: colors.surfaceBackground.highlighted,
          borderRadius: '4px',
          ':hover': {
            backgroundColor: colors.surfaceBackground.highlighted,
          },
        }}
      >
        <StyledPrevIcon />
      </IconButton>
      <IconButton
        onClick={() => toolbar.onNavigate('NEXT')}
        sx={{
          svg: { path: { fill: colors.icon.accent, opacity: '1' } },
          backgroundColor: colors.surfaceBackground.highlighted,
          borderRadius: '4px',
          ':hover': {
            backgroundColor: colors.surfaceBackground.highlighted,
          },
        }}
      >
        <StyledNextIcon />
      </IconButton>
      <Typography
        variant='body'
        color={colors.text.main}
        style={{
          lineHeight: 'inherit',
          fontSize: '1rem',
          margin: '0 1rem',
        }}
      >
        {label}
      </Typography>
      <Button
        onClick={() => toolbar.onNavigate('TODAY')}
        sx={{
          '&.Mui-disabled': {
            color: colors.textAccent.disabled,
          },
        }}
      >
        Go to Current Month
      </Button>
    </NavigationWrapper>
  );
};

const NavigationWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1rem;
`;

const StyledPrevIcon = styled(ChevronRightIcon)`
  transform: rotate(180deg);
`;

const StyledNextIcon = styled(ChevronRightIcon)``;
