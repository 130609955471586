import { useQuery, useQueryClient } from 'react-query';
import { Http } from '../../services/Http';
import { Deal } from '../../types';
import { QUERY_KEYS } from '../constants';

async function fetchDeals(): Promise<Deal[]> {
  const { data } = await Http.axios.get<Deal[]>(`/deal`);
  return data;
}

export const useDeals = () => {
  return useQuery(QUERY_KEYS.DEALS, () => fetchDeals(), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
};

export const useInvalidateDeals = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.DEALS,
    });
  };
};
