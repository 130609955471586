import { TextField, useTheme } from '@mui/material';
import { FormField } from '../../../../components/FormField/FormField';
import { IUserFileds } from './UserModal';
import { useFormikContext } from 'formik';

interface BasicUserFieldsProps {
  disableFields: boolean;
}

export const BasicUserFields = ({ disableFields }: BasicUserFieldsProps) => {
  const { colors } = useTheme();

  const { values, handleChange, handleBlur, touched, errors } = useFormikContext<IUserFileds>();

  const handleError = (field) => (touched[field] ? errors[field] : '');

  const labelStyle = {
    color: colors.text.caption,
  };

  return (
    <>
      <FormField label={'Name'} labelStyle={labelStyle} error={handleError('name')}>
        <TextField
          disabled={disableFields}
          autoComplete='off'
          placeholder='Enter User Name'
          name={'name'}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.name || ''}
          sx={{
            '.Mui-disabled': {
              WebkitTextFillColor: colors.text.main,
            },
          }}
        />
      </FormField>
      <FormField label={'Email'} labelStyle={labelStyle} error={handleError('email')}>
        <TextField
          disabled={disableFields}
          autoComplete='off'
          placeholder='Enter User Email'
          name={'email'}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.email || ''}
          sx={{
            '.Mui-disabled': {
              WebkitTextFillColor: colors.text.main,
            },
          }}
        />
      </FormField>
    </>
  );
};
