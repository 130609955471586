import { Button, IconButton, Typography, styled, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import { ReactComponent as AddIcon } from '../../../../assets/icons/add-white.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-grey.svg';
import { Deal, SelectItem } from '../../../../types';

import { Multiselect } from '../../../../components/Multiselect/Multiselect';
import { UserAvatar } from '../../../../components/UserAvatar/UserAvatar';
import { DealListSkeletonLoader } from '../../../../components/SkeletonLoader/DealList.SkeletonLoader';
import { useIsMutating } from 'react-query';
import { useDeals } from '../../../../queries/hooks/useDeals';
import { MUTATION_KEYS } from '../../../../queries/constants';

type Props = {
  localUserDeals: Partial<Deal>[];
  setLocalUserDeals: (value) => void;
};
export function DealList({ localUserDeals, setLocalUserDeals }: Props) {
  const { colors } = useTheme();
  const { data: dealOptionsRaw, isLoading: dealOptionsLoading } = useDeals();

  const assingDealInProgress = !!useIsMutating({
    mutationKey: MUTATION_KEYS.ASSIGN_DEALS_TO_OPERATING_PARTNER_KEY,
  });

  const [showSearchDeals, setShowSearchDeals] = useState(false);

  const dealsOptions: SelectItem[] = useMemo(() => {
    if (!dealOptionsRaw) return [];
    return dealOptionsRaw?.map((deal) => {
      return {
        ...deal,
        id: deal.id,
        value: deal.name || '',
      };
    });
  }, [dealOptionsRaw]);

  return (
    <Container>
      <ButtonsWrap>
        {showSearchDeals ? (
          <Multiselect
            style={{ width: '256px' }}
            listboxStyle={{ height: '195px' }}
            options={dealsOptions}
            value={localUserDeals as SelectItem[]}
            onChange={(_, deals) => {
              setLocalUserDeals(deals as Partial<Deal>[]);
            }}
            disablePortal
            fieldPlaceholder='Type to Search Deals'
            onBlur={() => {
              setShowSearchDeals(false);
            }}
          />
        ) : (
          <Button
            onClick={() => {
              setShowSearchDeals(true);
            }}
            disabled={dealOptionsLoading || assingDealInProgress}
            variant='text'
            style={{
              height: '28px',
            }}
            startIcon={<AddIcon />}
            sx={{
              '.MuiButton-startIcon': { marginRight: '2px' },
              '&.Mui-disabled': { opacity: 0.4, cursor: 'not-allowed', pointerEvents: 'auto' },
              svg: { g: { path: { fill: colors.icon.accent } } },
            }}
          >
            <Typography
              variant='body1'
              color={colors.textAccent.default}
              style={{ marginBottom: '-2px' }}
            >
              Add Deals
            </Typography>
          </Button>
        )}

        <Button
          onClick={() => {
            setLocalUserDeals([]);
          }}
          variant='text'
          disabled={dealOptionsLoading || assingDealInProgress}
        >
          <Typography
            variant='body1'
            color={colors.textAccent.disabled}
            style={{ marginBottom: '-2px' }}
          >
            Remove All
          </Typography>
        </Button>
      </ButtonsWrap>
      {dealOptionsLoading ? (
        <DealListSkeletonLoader />
      ) : (
        <DealListWrap>
          {localUserDeals?.map((deal) => {
            const issuerName = deal?.name || '';
            return (
              <DealItem key={deal.id}>
                <AvatarWrap>
                  <UserAvatar
                    name={issuerName}
                    size='large'
                    style={{
                      width: 28,
                      height: 28,
                    }}
                    lettersStyle={{
                      color: colors.textAccent.inverse,
                      fontSize: 14,
                    }}
                  />
                  {issuerName}
                </AvatarWrap>
                <IconButton
                  sx={{
                    svg: {
                      width: 20,
                      height: 20,
                    },
                  }}
                  onClick={() => {
                    const filteredLocalDeals = localUserDeals.filter((item) => item.id !== deal.id);
                    setLocalUserDeals(filteredLocalDeals);
                  }}
                  style={{ padding: '0px' }}
                >
                  <CloseIcon />
                </IconButton>
              </DealItem>
            );
          })}
        </DealListWrap>
      )}
    </Container>
  );
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 55vh;
  overflow-y: scroll;
`;
const ButtonsWrap = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DealListWrap = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DealItem = styled('div')`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.surfaceBackground.bg1};
  border: ${({ theme }) => `1px solid ${theme.colors.border.default}`};
  padding: 10px;
  border-radius: 5px;
`;

const AvatarWrap = styled('div')`
  display: flex;
  gap: 10px;
  align-items: center;
`;
