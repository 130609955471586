import { ClickAwayListener } from '@mui/base';
import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

type Props = {
  onOpen: () => void;
  onClose: () => void;
  open: boolean;
  content: JSX.Element;
  children: JSX.Element;
  placement?: TooltipProps['placement'];
};

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.surfaceBackground.bg3,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.surfaceBackground.bg3,
    padding: theme.spacing(1),
    color: theme.colors.textAccent.default,
  },
}));

function TooltipComponent({ open, onClose, content, placement = 'top-start', children }: Props) {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <div>
        <StyledTooltip
          PopperProps={{
            disablePortal: true,
          }}
          style={{
            zIndex: 9999,
          }}
          onClose={onClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={content}
          placement={placement}
        >
          {children}
        </StyledTooltip>
      </div>
    </ClickAwayListener>
  );
}

export default TooltipComponent;
