import { useTheme } from '@mui/material';
import { ROUTES } from '../../constants/routes';
import { PageHeader } from '../layout/PageHeader';
import { ContentWrapper } from '../layout/styled';
import { OperatingPartnerTable } from './components/OperatingPartnerTable';

export const OpManagerOperatingPartners = () => {
  const { colors } = useTheme();
  return (
    <>
      <PageHeader
        title='Operating Partners'
        description=''
        breadcrumbs={[
          {
            title: 'All timesheet',
            url: `/${ROUTES.OP_MANAGER_ALL_TIMESHEETS}`,
            color: colors.textAccent.default,
          },
          {
            title: 'Operating Partners',
          },
        ]}
      />
      <ContentWrapper>
        <OperatingPartnerTable />
      </ContentWrapper>
    </>
  );
};
