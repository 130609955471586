import ContentLoader from 'react-content-loader';
import { styled, useTheme } from '@mui/material/styles';
import { ReactElement } from 'react';

const SkeletonWrapper = styled('div')`
  display: flex;
  flex: 1;
`;

export function CalendarSkeletonLoader(): ReactElement {
  const { colors } = useTheme();
  return (
    <SkeletonWrapper>
      <ContentLoader
        height={700}
        width={'100%'}
        backgroundColor={colors.skeletonLoader.background}
        foregroundColor={colors.skeletonLoader.foreground}
        speed={2}
      >
        <rect width='250px' height='34' x='0' y='0' rx='4' fill='#EEEFF1' />

        <rect y='60' width='14%' height='48' fill='#EEEFF1' />
        <rect y='60' x='14.3%' width='14%' height='48' fill='#EEEFF1' />
        <rect y='60' x='28.6%' width='14%' height='48' fill='#EEEFF1' />
        <rect y='60' x='42.9%' width='14%' height='48' fill='#EEEFF1' />
        <rect y='60' x='57.2%' width='14%' height='48' fill='#EEEFF1' />
        <rect y='60' x='71.5%' width='14%' height='48' fill='#EEEFF1' />
        <rect y='60' x='85.8%' width='14%' height='48' fill='#EEEFF1' />

        <rect y='112' width='14%' height='140' fill='#EEEFF1' />
        <rect y='112' x='14.3%' width='14%' height='140' fill='#EEEFF1' />
        <rect y='112' x='28.6%' width='14%' height='140' fill='#EEEFF1' />
        <rect y='112' x='42.9%' width='14%' height='140' fill='#EEEFF1' />
        <rect y='112' x='57.2%' width='14%' height='140' fill='#EEEFF1' />
        <rect y='112' x='71.5%' width='14%' height='140' fill='#EEEFF1' />
        <rect y='112' x='85.8%' width='14%' height='140' fill='#EEEFF1' />

        <rect y='256' width='14%' height='140' fill='#EEEFF1' />
        <rect y='256' x='14.3%' width='14%' height='140' fill='#EEEFF1' />
        <rect y='256' x='28.6%' width='14%' height='140' fill='#EEEFF1' />
        <rect y='256' x='42.9%' width='14%' height='140' fill='#EEEFF1' />
        <rect y='256' x='57.2%' width='14%' height='140' fill='#EEEFF1' />
        <rect y='256' x='71.5%' width='14%' height='140' fill='#EEEFF1' />
        <rect y='256' x='85.8%' width='14%' height='140' fill='#EEEFF1' />

        <rect y='400' width='14%' height='140' fill='#EEEFF1' />
        <rect y='400' x='14.3%' width='14%' height='140' fill='#EEEFF1' />
        <rect y='400' x='28.6%' width='14%' height='140' fill='#EEEFF1' />
        <rect y='400' x='42.9%' width='14%' height='140' fill='#EEEFF1' />
        <rect y='400' x='57.2%' width='14%' height='140' fill='#EEEFF1' />
        <rect y='400' x='71.5%' width='14%' height='140' fill='#EEEFF1' />
        <rect y='400' x='85.8%' width='14%' height='140' fill='#EEEFF1' />

        <rect y='544' width='14%' height='140' fill='#EEEFF1' />
        <rect y='544' x='14.3%' width='14%' height='140' fill='#EEEFF1' />
        <rect y='544' x='28.6%' width='14%' height='140' fill='#EEEFF1' />
        <rect y='544' x='42.9%' width='14%' height='140' fill='#EEEFF1' />
        <rect y='544' x='57.2%' width='14%' height='140' fill='#EEEFF1' />
        <rect y='544' x='71.5%' width='14%' height='140' fill='#EEEFF1' />
        <rect y='544' x='85.8%' width='14%' height='140' fill='#EEEFF1' />
      </ContentLoader>
    </SkeletonWrapper>
  );
}
