import { useTheme } from '@mui/material';
import { CellContext } from '@tanstack/table-core';
import { Typography } from '../../../components/Typography/Typography';
import { MonthlyTimesheet } from '../../../types';
import { getMonthLabel } from '../../../utils/formatters';

type Props = {
  info: CellContext<MonthlyTimesheet, number>;
};
export function TsTimeframe({ info }: Props) {
  const { colors } = useTheme();
  const month = info.getValue();
  const monthLabel = getMonthLabel(month);
  const year = info.row.original.year;

  const cellValue = `${monthLabel} ${year}`;

  return (
    <Typography variant='body' color={colors.text.caption}>
      {cellValue}
    </Typography>
  );
}
