import { styled } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { ModalComponent } from '../../../../components/ModalComponent/ModalComponent';
import { LoadingId, SelectItem, User, UserRole } from '../../../../types';

import { FormField } from '../../../../components/FormField/FormField';
import { FormValidationMessage } from '../../../../components/FormValidationMessage/FormValidationMessage';
import { SingleSelect } from '../../../../components/SingleSelect/SingleSelect';
import { AssignProxySkeletonLoader } from '../../../../components/SkeletonLoader/AssignProxy.SkeletonLoader';
import { useLoadingBar } from '../../../../hooks/useLoadingBar';
import { useToastMessage } from '../../../../hooks/useToastMessage';
import { useAssignProxyToOperatingPartner } from '../../../../queries/hooks/useAssignProxyToOperatingPartner';
import { useUsers } from '../../../../queries/hooks/useUsers';
import UserInfo from './UserInfo';

type Props = {
  assignProxyModalOpen: boolean;
  handleAssignProxyModalClose: () => void;
  user: User;
};

const validationMessage = 'Proxy is required field';

export function AssignProxyModal({
  assignProxyModalOpen,
  handleAssignProxyModalClose,
  user,
}: Props) {
  const { mutate: onAssignProxyUser, isLoading: submitInProgress } =
    useAssignProxyToOperatingPartner();
  const { pushSuccessToast, pushErrorToast } = useToastMessage();
  const { startLoading, stopLoading } = useLoadingBar();
  const { isLoading: areUsersLoading, data: users } = useUsers();

  const [fieldToched, setFieldTouched] = useState(false);
  const [selectedProxy, setSelectedProxy] = useState<SelectItem | null>(null);

  const proxyOptions = useMemo(() => {
    if (!users) return [];
    return users
      .filter((user) => user.role === UserRole.PROXY)
      .map((item) => ({
        id: item.id,
        value: item.name,
      }));
  }, [users]);

  const handleAssignProxy = useCallback(() => {
    if (!selectedProxy) return;
    startLoading(LoadingId.assignProxyToUser);
    onAssignProxyUser(
      {
        proxy: Number(selectedProxy.id),
        id: user.id,
      },
      {
        onSuccess: () => {
          pushSuccessToast({
            title: 'Proxy Assigned',
            message: `Proxy for ${user.name} Operating Partner Profile have been successfully assigned`,
          });
          handleAssignProxyModalClose();
        },
        onError: () => {
          pushErrorToast({ message: 'Failed to assign proxy' });
        },
        onSettled: () => {
          stopLoading(LoadingId.assignProxyToUser);
        },
      }
    );
  }, [
    handleAssignProxyModalClose,
    onAssignProxyUser,
    pushErrorToast,
    pushSuccessToast,
    selectedProxy,
    startLoading,
    stopLoading,
    user.id,
    user.name,
  ]);

  return (
    <ModalComponent
      isOpen={assignProxyModalOpen}
      onClose={handleAssignProxyModalClose}
      actionButtonsProps={{
        isSubmitDisabled: submitInProgress || !selectedProxy,
        onSubmit: handleAssignProxy,
        onCancel: handleAssignProxyModalClose,
        submitText: 'Assign a Proxy',
      }}
      title='Assing a Proxy'
      top='80px'
    >
      <Content>
        <UserInfo user={user} />
        {areUsersLoading ? (
          <AssignProxySkeletonLoader />
        ) : (
          <FormField
            label={'Select a proxy'}
            error={fieldToched && !selectedProxy ? validationMessage : ''}
          >
            <SingleSelect
              listboxStyle={{ height: '195px' }}
              options={proxyOptions}
              value={selectedProxy}
              onChange={(_, option) => {
                setSelectedProxy(option);
              }}
              onBlur={() => {
                setFieldTouched(true);
              }}
              disablePortal
              fieldPlaceholder='Select a proxy'
            />
          </FormField>
        )}
        {!selectedProxy && (
          <FormValidationMessage text='Please, enter the missing details to assign a proxy.' />
        )}
      </Content>
    </ModalComponent>
  );
}

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
