import { useMutation } from 'react-query';
import { Http } from '../../services/Http';
import { MonthlyTimesheet, TimesheetStatus } from '../../types';
import { MUTATION_KEYS } from '../constants';
import { useInvalidateMonthlyTimesheets } from './useMonthlyTimesheets';
import { useInvalidateMonthlySingleTimesheet } from './useMonthlySingleTimesheet';

type Props = {
  id: number;
  rejectedWeeks: number[];
  rejectNote: string;
};

type SubmitPayload = {
  status: TimesheetStatus;
  rejectedWeeklyTimesheets: number[];
  rejectNote: string;
};

async function sendBackTimesheet({ rejectedWeeks, id, rejectNote }: Props): Promise<MonthlyTimesheet> {
  const { data } = await Http.axios.patch<SubmitPayload, MonthlyTimesheet>(
    `/monthly-timesheet/${id}`,
    {
      status: TimesheetStatus.REJECTED,
      rejectedWeeklyTimesheets: rejectedWeeks,
      rejectNote,
    }
  );
  return data;
}
export const useSendBackMontlyTimesheet = () => {
  const invalidateMontlyTimesheets = useInvalidateMonthlyTimesheets();
  const invalidateMontlySingleTimesheet = useInvalidateMonthlySingleTimesheet();

  return useMutation(sendBackTimesheet, {
    mutationKey: MUTATION_KEYS.SEND_BACK_MONTLY_TIMESHEET,
    onSuccess: () => {
      invalidateMontlyTimesheets();
      invalidateMontlySingleTimesheet();
    },
  });
};
