import { Box, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { MonthlyTimesheet } from '../../../types';

type Props = {
  timesheet?: MonthlyTimesheet;
};

export function AwerageWorkCell({ timesheet }: Props) {
  const { colors } = useTheme();

  const avgWorkValue = `${timesheet?.avgWork?.toFixed() ?? 0}%`;
  const avgNotWorkValue = `${timesheet?.avgNotWork?.toFixed() ?? 0}%`;
  return (
    <Box display={'flex'} justifyContent={'end'} gap={'2px'}>
      <Typography as={'label'} variant={'h5'} color={colors.text.main} className='Label'>
        {avgWorkValue}
      </Typography>
      <Typography as={'label'} variant={'h5'} color={colors.text.main} className='Label'>
        /
      </Typography>
      <Typography as={'label'} variant={'h5'} color={colors.text.caption} className='Label'>
        {avgNotWorkValue}
      </Typography>
    </Box>
  );
}
