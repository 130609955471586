import ContentLoader from 'react-content-loader';
import { styled, useTheme } from '@mui/material/styles';
import { ReactElement } from 'react';

const SkeletonWrapper = styled('div')`
  display: flex;
  flex: 1;
`;

interface Props {
  showFilterSkeleton?: boolean;
}

export function TableSkeletonLoader({ showFilterSkeleton = true }: Props): ReactElement {
  const { colors } = useTheme();
  const numOfRows = Array.from({ length: 12 }, (_, i) => i);
  const Y_HEIGHT = 51;

  return (
    <SkeletonWrapper>
      <ContentLoader
        height={700}
        width={'100%'}
        backgroundColor={colors.skeletonLoader.background}
        foregroundColor={colors.skeletonLoader.foreground}
        speed={2}
      >
        {showFilterSkeleton && (
          <>
            <rect width='323' height='34' y='0' rx='4' fill='#EEEFF1' />
            <rect width='240px' height='34' x='335' y='0' rx='4' fill='#EEEFF1' />
            <rect width='240px' height='34' x='587' y='0' rx='4' fill='#EEEFF1' />
          </>
        )}
        {numOfRows.map((e) => (
          <rect
            y={showFilterSkeleton ? (58 + e * Y_HEIGHT).toString() : (e * Y_HEIGHT).toString()}
            width='100%'
            height='48'
            fill='#EEEFF1'
            key={e}
          />
        ))}
      </ContentLoader>
    </SkeletonWrapper>
  );
}
