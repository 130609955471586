import { useMutation } from 'react-query';
import { Http } from '../../services/Http';
import { User } from '../../types';
import { MUTATION_KEYS } from '../constants';
import { useInvalidateUsers } from './useUsers';

type Payload = {
  proxy: number | null;
  id: number;
};

async function assignProxy({ proxy, id }: Payload): Promise<User[]> {
  const { data } = await Http.axios.patch<{ proxy: number | null }, User[]>(`/user/${id}`, {
    proxy,
  });
  return data;
}

export const useAssignProxyToOperatingPartner = () => {
  const invalidateUsers = useInvalidateUsers();
  return useMutation(assignProxy, {
    mutationKey: MUTATION_KEYS.ASSIGN_PROXY_TO_OPERATING_PARTNER_KEY,
    onSuccess: () => {
      invalidateUsers();
    },
  });
};
