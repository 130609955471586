import { Chip, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { useTimesheetStatusColor } from '../../../hooks/useTimesheetStatusColor';
import { MonthlyTimesheet, TimesheetStatusLabel } from '../../../types';
import { useDealOwnerTimesheetStatus } from '../../../hooks/useDealOwnerTimesheetStatus';

type Props = {
  timesheet: MonthlyTimesheet;
};

export function StatusCell({ timesheet }: Props) {
  const { colors } = useTheme();

  const status = useDealOwnerTimesheetStatus(timesheet);
  const statusColor = useTimesheetStatusColor(timesheet.status as TimesheetStatusLabel);

  return (
    <Chip
      style={{
        backgroundColor: colors.surfaceStatus.noAction,
        minWidth: '66px',
        height: '28px',
      }}
      label={
        <Typography variant='body' color={statusColor}>
          {status}
        </Typography>
      }
    />
  );
}
