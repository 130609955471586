import { Box, Chip, useTheme } from '@mui/material';
import { CellContext } from '@tanstack/table-core';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { Typography } from '../../../components/Typography/Typography';
import { userState } from '../../../state/UIState';
import { TimesheetStatus, UserRole, Timesheet } from '../../../types';
import { formatWeekName, getWeekStatus } from '../../../utils/formatters';
import { getWeeklyTimesheetWeekName } from '../../../utils/getWeeklyTimesheetWeekStart';

type Props = {
  info: CellContext<Timesheet, string>;
};
export function WeekCell({ info }: Props) {
  const { colors } = useTheme();
  const user = useAtomValue(userState);

  const weekStart = useMemo(() => {
    return getWeeklyTimesheetWeekName(info.row.original);
  }, [info.row.original]);

  const weekLength = info.row.original.weekDates.length + info.row.original.holidays.length;

  const statusValue = getWeekStatus(weekStart);

  const isNewWeek = statusValue === 'New';

  const statusColor = isNewWeek ? colors.textStatus.active : colors.textStatus.warning;

  const notIssuedStatus = info.row.original.status !== TimesheetStatus.ISSUED;

  const borderColor = useMemo(() => {
    if (user?.role === UserRole.OPERATING_PARTNER) return colors.surfaceBackground.highlighted;
    return isNewWeek
      ? colors.textStatus.active
      : notIssuedStatus
      ? colors.surfaceBackground.highlighted
      : colors.textStatus.warning;
  }, [isNewWeek, user?.role, notIssuedStatus, colors]);

  const cellValue = formatWeekName(weekStart, weekLength);

  return (
    <Box
      borderLeft={'4px solid'}
      borderColor={borderColor}
      maxHeight={48}
      minHeight={48}
      display={'flex'}
      alignItems={'center'}
      paddingLeft={'16px'}
      gap={'8px'}
    >
      <Typography variant='body' color={colors.text.caption}>
        {cellValue}
      </Typography>
      {!notIssuedStatus && (
        <Chip
          style={{
            backgroundColor: colors.surfaceStatus.noAction,
            minWidth: 'auto',
            height: '28px',
          }}
          label={
            <Typography variant='body' color={statusColor}>
              {statusValue}
            </Typography>
          }
        />
      )}
    </Box>
  );
}

export default WeekCell;
