import styled from '@emotion/styled';
import { ReactComponent as NoAccessIcon } from '../../assets/icons/emergency-home.svg';
import { Typography } from '../../components/Typography/Typography';
import { Button, useTheme } from '@mui/material';
import { text } from '../../themes/text';

const Wrapper = styled('div')`
  padding: 34px 62px;
`;

const TitleWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const Content = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const MainSection = styled('div')`
  height: 256px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 146px;
`;

export const Unauthorized = () => {
  const { colors } = useTheme();
  return (
    <Wrapper>
      <Content>
        <MainSection>
          <TitleWrapper>
            <NoAccessIcon />
            <Typography
              variant='h1'
              style={{ fontSize: `${text.size.extraExtraLarge}px` }}
              color={colors.text.main}
            >
              No Access
            </Typography>
          </TitleWrapper>
          <Typography
            variant='h3'
            style={{ marginTop: '22px', fontFamily: text.font.salvaTest }}
            color={colors.text.main}
          >
            Sorry, It seems that you don’t have the access to the Timesheet Management.
          </Typography>
          <Typography
            variant='h3'
            color={colors.text.main}
            style={{ marginBottom: '34px', fontFamily: text.font.salvaTest }}
          >
            To resolve access issues, please contact our support team.
          </Typography>
          <Button variant='contained' style={{ height: '40px' }}>
            <Typography
              variant='h4'
              color={colors.textAccent.inverse}
              style={{ marginBottom: '-1px' }}
            >
              Contact Support
            </Typography>
          </Button>
        </MainSection>
      </Content>
    </Wrapper>
  );
};
