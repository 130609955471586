import { styled, useTheme } from '@mui/material';
import { useLoadingBar } from '../../hooks/useLoadingBar';
import { useToastMessage } from '../../hooks/useToastMessage';
import { useBulkApproveMontlyTimesheet } from '../../queries/hooks/useBulkApproveMontlyTimesheet';
import { LoadingId, MonthlyTimesheet, TimesheetStatus } from '../../types';
import { getMonthLabel } from '../../utils/formatters';
import { ModalComponent } from '../ModalComponent/ModalComponent';
import { Typography } from '../Typography/Typography';

type Props = {
  timesheets: MonthlyTimesheet[];
  toggleOpen: () => void;
  resetSelection: () => void;
  isOpen: boolean;
  approveStatus: TimesheetStatus;
  sendToLabel?: string;
  onApprove: () => void;
};

export function BulkApproveTimesheetModal({
  timesheets,
  isOpen,
  toggleOpen,
  resetSelection,
  approveStatus,
  sendToLabel,
  onApprove,
}: Props) {
  const { colors } = useTheme();

  const { pushSuccessToast, pushErrorToast } = useToastMessage();
  const { startLoading, stopLoading } = useLoadingBar();
  const title = `Approve ${timesheets?.length} Timesheets`;

  const selectedTsIds = timesheets.map((ts) => Number(ts.id));

  const modalBodyMsg = (() => {
    let message = '';
    timesheets.forEach((ts, index) => {
      const month = getMonthLabel(ts.month);
      const hasAnd = timesheets[index] !== timesheets[timesheets.length - 1];
      message += `${ts.user?.name} ${month} ${ts.year}`;
      message += hasAnd ? ` and ` : '';
    });

    return message;
  })();

  const { mutate: onApproveTimesheet, isLoading: approveInProgress } =
    useBulkApproveMontlyTimesheet();

  const handleApproveTimesheet = async () => {
    startLoading(LoadingId.bulkApproveTimesheet);
    onApproveTimesheet(
      {
        ids: selectedTsIds,
        status: approveStatus,
      },
      {
        onSuccess: () => {
          resetSelection();
          pushSuccessToast({
            title: 'Timesheets Approved',
            message: `${modalBodyMsg} timesheets has been approved ${
              sendToLabel ? `and sent to ${sendToLabel}` : ''
            }.`,
          });
          toggleOpen();
          onApprove();
        },
        onError: () => {
          pushErrorToast({ message: 'Failed to approve timesheets' });
        },
        onSettled: () => {
          stopLoading(LoadingId.bulkApproveTimesheet);
        },
      }
    );
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={toggleOpen}
      actionButtonsProps={{
        isSubmitDisabled: approveInProgress,
        onSubmit: handleApproveTimesheet,
        onCancel: toggleOpen,
        submitText: `Approve`,
        submitBtnStyle: {},
      }}
      title={title}
      top='80px'
    >
      <InfoWrapper>
        <Typography variant='body' color={colors.text.main}>
          {`Are you sure you want to approve ${modalBodyMsg} timesheets?`}
        </Typography>
      </InfoWrapper>
    </ModalComponent>
  );
}

const InfoWrapper = styled('div')`
  border-radius: 2px;
  display: flex;
  gap: 8px;
  margin-bottom: 14px;
`;
