import { useMutation } from 'react-query';
import { Http } from '../../services/Http';
import { MUTATION_KEYS } from '../constants';
import { useInvalidateUsers } from './useUsers';
import { User } from '../../types';

async function deleteUser(id: number): Promise<User> {
  const { data } = await Http.axios.delete<User>(`/user/${id}`);
  return data;
}

export const useDeleteUser = () => {
  const invalidateUsers = useInvalidateUsers();

  return useMutation(deleteUser, {
    mutationKey: MUTATION_KEYS.DELETE_USER_KEY,
    onSuccess: () => {
      invalidateUsers();
    },
  });
};
