import { Box, Checkbox, CheckboxProps, useTheme } from '@mui/material';
import { Row } from '@tanstack/table-core';
import { useEffect, useRef } from 'react';
import { MonthlyTimesheet } from '../../../types';
import { getWeekStatus } from '../../../utils/formatters';

type Props = {
  row?: Row<MonthlyTimesheet>;
  inHeader?: boolean;
  indeterminate?: boolean;
} & CheckboxProps;

export function CheckboxComponent({ indeterminate, inHeader, row, ...rest }: Props) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const ref = useRef<HTMLInputElement>(null!);
  const { colors } = useTheme();

  const weekStart = row?.original.month;
  const statusValue = getWeekStatus(weekStart?.toString() || '');

  const isNewWeek = statusValue === 'New';

  const borderColor = isNewWeek ? colors.textStatus.active : colors.textStatus.warning;

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <Box
      {...(inHeader
        ? { paddingLeft: '4px' }
        : { borderLeft: `4px solid ${borderColor}`, paddingLeft: '16px' })}
      maxHeight={48}
      minHeight={48}
      display={'flex'}
      alignItems={'center'}
      gap={'8px'}
    >
      <Checkbox
        inputRef={ref}
        indeterminate={indeterminate}
        inputMode='text'
        sx={{
          color: colors.border.default,
          '&.Mui-checked': {
            color: colors.textAccent.default,
          },
        }}
        {...rest}
      />
    </Box>
  );
}
