export const QUERY_KEYS = {
  USERS: 'USERS',
  USER_BY_EMAIL: 'USER_BY_EMAIL',
  DEALS: 'DEALS',
  HOLIDAYS: 'HOLIDAYS',
  TIMESHEETS: 'TIMESHEETS',
  SINGLE_TIMESHEET: 'SINGLE_TIMESHEET',
  SINGLE_TIMESHEET_DEAL: 'SINGLE_TIMESHEET_DEAL',
  MONTHLY_TIMESHEET: 'MONTHLY_TIMESHEET',
  MONTHLY_SINGLE_TIMESHEET: 'MONTHLY_SINGLE_TIMESHEET',
  MONTHLY_TIMESHEET_TIME_RANGE: 'MONTHLY_TIMESHEET_TIME_RANGE',
  MONTHLY_TIMESHEET_TO_EXPORT: 'MONTHLY_TIMESHEET_TO_EXPORT',
  USER_OPERATING_PARTNERS: 'USER_OPERATING_PARTNERS',
};

export const MUTATION_KEYS = {
  ASSIGN_PROXY_USER_KEY: 'ASSIGN_PROXY_USER',
  ADD_DEAL_KEY: 'ADD_DEAL',
  ADD_HOLIDAY_KEY: 'ADD_HOLIDAY',
  EDIT_DEAL_KEY: 'EDIT_DEAL',
  EDIT_HOLIDAY_KEY: 'EDIT_HOLIDAY_KEY',
  DELETE_DEAL_KEY: 'DELETE_DEAL',
  DELETE_HOLIDAY_KEY: 'DELETE_HOLIDAY',
  BULK_DELETE_HOLIDAY_KEY: 'BULK_DELETE_HOLIDAY',
  ADD_USER_KEY: 'ADD_USER',
  EDIT_USER_KEY: 'EDIT_USER',
  DELETE_USER_KEY: 'DELETE_USER',
  ASSIGN_DEALS_TO_OPERATING_PARTNER_KEY: 'ASSIGN_DEALS_TO_OPERATING_PARTNER',
  ASSIGN_PROXY_TO_OPERATING_PARTNER_KEY: 'ASSIGN_PROXY_TO_OPERATING_PARTNER',
  SUBMIT_TIMESHEET: 'SUBMIT_TIMESHEET',
  UPDATE_TIMESHEET_CELL: 'UPDATE_TIMESHEET_CELL',
  CLEAR_TIMESHEET_COLUMN: 'CLEAR_TIMESHEET_COLUMN',
  CLEAR_TIMESHEET_TABLE: 'CLEAR_TIMESHEET_TABLE',
  APPROVE_MONTLY_TIMESHEET: 'APPROVE_MONTLY_TIMESHEET',
  SEND_BACK_MONTLY_TIMESHEET: 'SEND_BACK_MONTLY_TIMESHEET',
  BULK_APPROVE_MONTLY_TIMESHEET: 'BULK_APPROVE_MONTLY_TIMESHEET',
  BULK_SEND_BACK_MONTLY_TIMESHEET: 'BULK_SEND_BACK_MONTLY_TIMESHEET',
};
