import { useState } from 'react';
import { useTheme } from '@mui/material';
import { ACTIONS, STATUS, Step, CallBackProps } from 'react-joyride';
import { Typography } from '../../../components/Typography/Typography';
import { useNavigate } from 'react-router';
import OnboardingTooltip from '../../../components/Onboarding/OnboardingTooltip';
import StartOnboardingCard from '../../../components/Onboarding/StartOnboardingCard';
interface OnboardingProps {
  setShowOnboarding: (b: boolean) => void;
}

function OnboardingIssuedTimesheets({ setShowOnboarding }: OnboardingProps) {
  const [run, setRun] = useState(false);
  const { colors } = useTheme();
  const navigate = useNavigate();

  const steps: Step[] = [
    {
      target: '.timesheet-table',
      title: 'Review Timesheet',
      content:
        'Click on the new Timesheet and then click the Review button to enter the time spent on each Deal you worked on.',
      locale: {
        last: 'Next',
      },
      placement: 'top-end',
      styles: {
        tooltip: {
          top: '26px',
        },
      },
    },
  ];

  const onFinishOnboarding = () => {
    setRun(false);
    setShowOnboarding(false);
    window.localStorage.setItem('hasCompletedOnboarding', 'true');
    navigate('/pending-timesheets');
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, action, index } = data;

    if (index === 0 && action === ACTIONS.NEXT) {
      navigate('/pending-timesheets/demo');
    } else if (status === STATUS.SKIPPED || action === ACTIONS.CLOSE) {
      onFinishOnboarding();
    }
  };

  return (
    <>
      <Typography
        variant='body'
        color={colors.text.main}
        style={{
          marginBottom: '1rem',
        }}
      >
        Keep track and review your weekly timesheets
      </Typography>
      <StartOnboardingCard
        title='Timesheets Onboarding'
        description='Timesheet helps you track and manage your work hours on various deals. Easily enter your
          daily work percentages, ensure they total 100%, and submit your timesheets for review.'
        startBtn='Start Timesheets'
        onStartOnboarding={() => setRun(true)}
        onCloseCard={onFinishOnboarding}
      />
      <OnboardingTooltip steps={steps} run={run} handleJoyrideCallback={handleJoyrideCallback} />
    </>
  );
}

export default OnboardingIssuedTimesheets;
