import { Button, styled } from '@mui/material';
import { ActionButtonsProps } from '../../types';

export function ModalActionBtns({
  onSubmit,
  isSubmitDisabled,
  cancelText = 'Cancel',
  submitText,
  onCancel,
  cancelBtnStyle,
  submitBtnStyle,
}: ActionButtonsProps) {
  return (
    <div>
      <Wrapper>
        <Button variant='outlined' onClick={onCancel} style={{ height: '44px', ...cancelBtnStyle }}>
          {cancelText}
        </Button>
        <Button
          variant='contained'
          disabled={isSubmitDisabled}
          onClick={onSubmit}
          style={{ height: '44px', ...submitBtnStyle }}
        >
          {submitText}
        </Button>
      </Wrapper>
    </div>
  );
}

const Wrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;
