import { styled, useTheme } from '@mui/material';
import { useParams } from 'react-router';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { ROUTES } from '../../constants/routes';
import { useMonthlySingleTimesheet } from '../../queries/hooks/useMonthlySingleTimesheet';
import { getMonthLabel } from '../../utils/formatters';
import { ContentWrapper } from '../layout/styled';
import { AccountantSingleTimesheetTable } from './components/AccountantSingleTimesheetTable';

export function AccountantSingleTimesheet() {
  const { id } = useParams();

  const { colors } = useTheme();
  const { data: singleTimesheet } = useMonthlySingleTimesheet({
    id: Number(id),
  });

  const breadcrumbTitle = singleTimesheet
    ? `${getMonthLabel(singleTimesheet.month)}, ${singleTimesheet.year}`
    : '';

  return (
    <>
      <Wrapper>
        <Breadcrumbs
          breadcrumbs={[
            {
              title: 'Timesheet statuses',
              url: `/${ROUTES.ACCOUNTANT_TIMESHEETS_STATUSES}`,
              color: colors.textAccent.default,
            },
            { title: breadcrumbTitle || '' },
          ]}
        />
      </Wrapper>
      <ContentWrapper>
        <AccountantSingleTimesheetTable />
      </ContentWrapper>
    </>
  );
}
const Wrapper = styled('div')`
  padding: 28px 36px 0;
`;
