import styled from '@emotion/styled';
import { Button, useTheme } from '@mui/material';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { ReactComponent as ReviewIcon } from '../../../assets/icons/search_review.svg';
import { TableHeaderCell } from '../../../components/Table/CellRenderers/TableHeaderCell';
import { Typography } from '../../../components/Typography/Typography';
import { ROUTES } from '../../../constants/routes';
import { Timesheet, TimesheetStatus, TimesheetStatusLabel } from '../../../types';
import { formatDate } from '../../../utils/formatters';
import DealCell from '../components/DealsCell';
import { StatusCell } from '../components/StatusCell';
import WeekCell from '../components/WeekCell';
import { LastEditedCell } from '../../../components/Table/CellRenderers/LastEditedCell';

const CellWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const columnHelper = createColumnHelper<Timesheet>();

export const ActionCell = (props: CellContext<Timesheet, unknown>) => {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const timesheet = props.row.original;
  const notIssuedStatus =
    props.row.original.status !== TimesheetStatus.ISSUED &&
    props.row.original.status !== TimesheetStatus.REJECTED;

  return (
    <CellWrapper style={{ justifyContent: 'start', padding: '0 16px', width: '100%' }}>
      <Button
        onClick={() => {
          navigate(`/${ROUTES.ALL_TIMESHEETS}/${timesheet.id}`);
        }}
        variant='text'
        style={{
          width: '100%',
          height: '28px',
          backgroundColor: colors.surfaceBackground.bg1,
          border: `1px solid ${colors.border.default}`,
        }}
        startIcon={<ReviewIcon />}
        sx={{
          '.MuiButton-startIcon': { marginRight: '2px' },
          '&.Mui-disabled': { opacity: 0.4, cursor: 'not-allowed', pointerEvents: 'auto' },
          svg: { g: { path: { fill: colors.icon.accent } } },
        }}
      >
        <Typography variant='h4' color={colors.textAccent.default} style={{ marginBottom: '-2px' }}>
          {notIssuedStatus ? 'View' : 'Review'}
        </Typography>
      </Button>
    </CellWrapper>
  );
};

export const useOpAllTimesheetsTableColumns = () => {
  const columns = useMemo(
    () => [
      columnHelper.accessor('weekStart', {
        cell: (info) => <WeekCell info={info} />,
        header: () => <TableHeaderCell text='Week' />,
        filterFn: (row, id, filterValue) => {
          if (!filterValue) return true;

          const filterValueDate = new Date(filterValue).getMonth() + 1;
          const cellValueDate = row.original.weekDates.length
            ? new Date(row.original.weekDates[0]).getMonth() + 1
            : row.original.holidays[0].month;

          const filterValueYear = new Date(filterValue).getFullYear();
          const cellValueYear = row.original.weekDates.length
            ? new Date(row.original.weekDates[0]).getFullYear()
            : row.original.holidays[0].year;

          const isDateMatched =
            filterValueDate === cellValueDate && filterValueYear === cellValueYear;

          return isDateMatched;
        },
        meta: {
          thStyles: { paddingLeft: '16px' },
          tdStyles: { padding: '0' },
          width: '400px',
          minWidth: '400px',
          maxWidth: '400px',
        },
      }),
      columnHelper.accessor('status', {
        cell: (info) => <StatusCell status={info.getValue() as TimesheetStatusLabel} />,
        header: () => <TableHeaderCell text='TS Status' />,
        meta: {
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
          width: '150px',
          minWidth: '150px',
          maxWidth: '150px',
        },
      }),
      columnHelper.accessor('timesheetRows', {
        cell: (info) => <DealCell deals={info.row.original.timesheetRows} />,
        header: () => <TableHeaderCell text='Deals' />,
        enableSorting: false,

        filterFn: (row, id, filterValue) => {
          if (!filterValue) return true;
          const deals = row.original.timesheetRows;

          const hasMatch = deals.some((deal) =>
            deal.name.toLocaleLowerCase().includes(filterValue.toLowerCase())
          );
          return hasMatch;
        },
        meta: {
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
        },
      }),
      columnHelper.accessor('updated_at', {
        cell: (info) => (
          <LastEditedCell
            updatedBy={info.row.original.updatedBy}
            updated_at={info.row.original.updated_at}
          />
        ),
        header: () => <TableHeaderCell text='Last Edited' />,
        filterFn: (row, id, filterValue) => {
          if (!filterValue) return true;
          const isDateMatched =
            formatDate(filterValue ?? '') === formatDate(row.original.updated_at ?? '');
          return isDateMatched;
        },
        meta: {
          thStyles: { paddingLeft: '16px' },
          tdStyles: { paddingLeft: '16px' },
          width: '300px',
          minWidth: '300px',
          maxWidth: '300px',
        },
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) => <ActionCell {...info} />,
        header: () => <TableHeaderCell text='Action' />,
        meta: {
          width: '130px',
          minWidth: '130px',
          maxWidth: '130px',
          tdStyles: {
            paddingLeft: '0',
            borderLeft: `none`,
          },
          thStyles: { borderLeft: `none` },
        },
      }),
    ],
    []
  );

  return columns;
};
