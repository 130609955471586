import { useMutation } from 'react-query';
import { Http } from '../../services/Http';
import { Deal } from '../../types';
import { MUTATION_KEYS } from '../constants';
import { useInvalidateDeals } from './useDeals';
import { useInvalidateUsers } from './useUsers';

type Props = {
  payload: EditDealPayload;
  id: number;
};

type EditDealPayload = {
  name: string;
  operatingPartners: number[];
  dealOwners: number[];
};

async function editDeal({ payload, id }: Props): Promise<Deal[]> {
  const { data } = await Http.axios.patch<EditDealPayload, Deal[]>(`deal/${id}`, payload);
  return data;
}

export const useEditDeal = () => {
  const invalidateDeals = useInvalidateDeals();
  const invalidateUsers = useInvalidateUsers();

  return useMutation(editDeal, {
    mutationKey: MUTATION_KEYS.EDIT_DEAL_KEY,
    onSuccess: () => {
      invalidateDeals();
      invalidateUsers();
    },
  });
};
