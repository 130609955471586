import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { ModalComponent } from '../../../../components/ModalComponent/ModalComponent';
import { useMonthlyTimesheetsByTimeRange } from '../../../../queries/hooks/useMonthlyTimesheetsByTimeRange';
import { DealSkeletonLoading } from '../../../../components/SkeletonLoader/DealSkeletonLoading';
import { ExportTimesheetsForm } from './ExportTimesheetsForm';
import { useMonthlyTimesheetsToExport } from '../../../../queries/hooks/useMonthlyTimesheetsToExport';
import { AccountantTSView } from '../../../../types';
import {
  generateMonthTemplate,
  generateWeekTemplate,
  generateWeekdayTemplate,
} from '../../../../utils/excelMultiExportTimesheet';

type ModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

export interface IExportMultiTSForm {
  selectedPeriod: {
    year: number;
    months: {
      [month: number]: boolean;
    };
  }[];
  showDataBy: AccountantTSView | null;
}

const initialValues: IExportMultiTSForm = {
  selectedPeriod: [],
  showDataBy: null
};

export function ExportMultiTimesheetModal({ isOpen, onClose }: ModalProps) {
  const [timeRangeParams, setTimeRangeParams] = useState('');
  const [searchParams] = useSearchParams();
  const [triggerExport, setTriggerExport] = useState<AccountantTSView | null>(null); 
  const ids = searchParams.get('ids') ?? '';

  const {
    isLoading: isLoadingTSAvailability,
    data: availablePeriods,
    isSuccess,
  } = useMonthlyTimesheetsByTimeRange(ids);

  const { data: timesheets, isLoading: isLoadingTS } = useMonthlyTimesheetsToExport({
    userIds: ids,
    timeRange: timeRangeParams,
  });

  const handleExportClick = useCallback(
    (showDataBy: AccountantTSView) => {
      if (timesheets) {
        if (showDataBy === AccountantTSView.month) {
          generateMonthTemplate(timesheets);
        }
        if (showDataBy === AccountantTSView.week) {
          generateWeekTemplate(timesheets);
        }
        if (showDataBy === AccountantTSView.weekday) {
          generateWeekdayTemplate(timesheets);
        }
      }
    },
    [timesheets]
  );

  useEffect(() => {
    if (timesheets && triggerExport) {
      handleExportClick(triggerExport);
      setTriggerExport(null);
    }
  }, [timesheets, triggerExport, handleExportClick]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={async (values: IExportMultiTSForm) => {
        const queryParams = values.selectedPeriod
          .flatMap(({ year, months }) =>
            Object.entries(months)
              .filter(([_, value]) => value)
              .map(([month]) => `${month}-${year}`)
          )
          .join(',');
        setTimeRangeParams(queryParams);
        setTriggerExport(values.showDataBy);
      }}
    >
      {({ handleSubmit, values }) => {
        const isFormDisabled =
          values.selectedPeriod.every(({ months }) => Object.values(months).every((val) => !val)) ||
          !values.showDataBy;
        return (
          <Form>
            <ModalComponent
              isOpen={isOpen}
              onClose={onClose}
              actionButtonsProps={{
                isSubmitDisabled: isFormDisabled,
                onSubmit: handleSubmit,
                onCancel: onClose,
                submitText: isLoadingTS ? 'Exporting...' : 'Export',
              }}
              title={`Export Timesheet`}
              top='80px'
            >
              <>
                {isLoadingTSAvailability && <DealSkeletonLoading />}
                {isSuccess && <ExportTimesheetsForm availablePeriods={availablePeriods} />}
              </>
            </ModalComponent>
          </Form>
        );
      }}
    </Formik>
  );
}
