import { useTheme } from '@mui/material';
import { Column, Table } from '@tanstack/react-table';
import { Typography } from '../../../components/Typography/Typography';
import { MonthlyTimesheetRow } from '../../../types';
import { useMemo } from 'react';

function TotalCell(props: {
  table: Table<MonthlyTimesheetRow>;
  column: Column<MonthlyTimesheetRow>;
}) {
  const { colors } = useTheme();

  const total = useMemo(() => {
    const result = props.table
      .getFilteredRowModel()
      .rows.reduce((total, row) => total + Number(row.original.avg?.toFixed() ?? 0), 0);
    if (result === 99 || result === 101) return 100;
    return result;
  }, [props.table]);

  return (
    <Typography variant='body' color={colors.text.main}>
      {`${total}%`}
    </Typography>
  );
}

export default TotalCell;
