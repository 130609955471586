import { Navigate } from 'react-router';

import { useAtomValue } from 'jotai';
import { userState } from '../../state/UIState';
import { canSeeConfig } from '../../constants/constants';

export function DefaultPageRedirect() {
  const user = useAtomValue(userState);

  const defaultUrl = canSeeConfig[user?.role as keyof typeof canSeeConfig].defaultPageUrl;
  return <Navigate replace to={`/${defaultUrl}`} />;
}
