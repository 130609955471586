import { styled, useTheme } from '@mui/material/styles';
import { ReactElement, useMemo } from 'react';
import { Typography } from '../Typography/Typography';
import { ToastMessageType, ToastProps } from '../../types';
import { IconButton, Snackbar } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-toast.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success-circle.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning-circle.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error-circle.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info-circle.svg';

interface ExtendedToastProps extends ToastProps {
  type: ToastMessageType;
  isOpen: boolean;
  handleClose: () => void;
}

interface ContentProps {
  backgroundColor: string;
  borderColor: string;
}

const Wrapper = styled('div')<ContentProps>`
  width: 368px;
  z-index: 1300;
  border-radius: 4px;
  min-height: 56px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-left: 4px solid ${({ borderColor }) => borderColor};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px 12px;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.05);
`;

const Content = styled('div')`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  svg {
    overflow: visible;
  }
`;

const MessageWrapper = styled('div')``;

const CloseIconWrapper = styled('div')`
  margin-bottom: -2px;
`;

const ButtonWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ToastMessage = ({
  type,
  title,
  message,
  isOpen,
  handleClose,
}: ExtendedToastProps): ReactElement => {
  const theme = useTheme();
  const { colors } = theme;

  const MESSAGE_TYPES = useMemo(
    () => ({
      [ToastMessageType.success]: {
        background: theme.colors.surfaceStatus.successLight,
        borderColor: theme.colors.iconStatus.success,
        icon: <SuccessIcon />,
        defaultTitle: 'Success Message',
      },
      [ToastMessageType.warning]: {
        background: theme.colors.surfaceStatus.warningLight,
        borderColor: theme.colors.iconStatus.warning,
        icon: <WarningIcon />,
        defaultTitle: 'Warning Message',
      },
      [ToastMessageType.error]: {
        background: theme.colors.surfaceBackground.bg1,
        borderColor: theme.colors.iconStatus.error,
        icon: <ErrorIcon />,
        defaultTitle: 'Error Message',
      },
      [ToastMessageType.info]: {
        background: theme.colors.surfaceStatus.error,
        borderColor: theme.colors.icon.disabled,
        icon: <InfoIcon />,
        defaultTitle: 'Info Message',
      },
    }),
    [theme.colors]
  );
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      style={{ zIndex: 100005 }}
    >
      <Wrapper
        backgroundColor={MESSAGE_TYPES[type].background}
        borderColor={MESSAGE_TYPES[type].borderColor}
      >
        <Content>
          {MESSAGE_TYPES[type].icon}
          <MessageWrapper>
            <Typography variant='h4' color={colors.text.main}>
              {title ?? MESSAGE_TYPES[type].defaultTitle}
            </Typography>
            <Typography variant='body' color={colors.text.secondary}>
              {message}
            </Typography>
          </MessageWrapper>
        </Content>
        <ButtonWrapper>
          <CloseIconWrapper onClick={handleClose}>
            <IconButton style={{ padding: 0, marginTop: '-2px' }}>
              <CloseIcon />
            </IconButton>
          </CloseIconWrapper>
        </ButtonWrapper>
      </Wrapper>
    </Snackbar>
  );
};
