import { styled } from '@mui/material';
import { useCallback, useState } from 'react';
import { ModalComponent } from '../../../../components/ModalComponent/ModalComponent';
import { LoadingId, User } from '../../../../types';
import { DealList } from './DealList';
import UserInfo from './UserInfo';
import { useToastMessage } from '../../../../hooks/useToastMessage';
import { useLoadingBar } from '../../../../hooks/useLoadingBar';
import { useAssignDealsToOperatingPartner } from '../../../../queries/hooks/useAssignDealsToOperatingPartner';

type Props = {
  assignDealsModalOpen: boolean;
  handleAssignDealsModalClose: () => void;
  user: User;
};

export function AssignDealsModal({
  assignDealsModalOpen,
  handleAssignDealsModalClose,
  user,
}: Props) {
  const [localUserDeals, setLocalUserDeals] = useState(user?.operatingPartnerDeals || []);

  const { mutate: onAssignDeals, isLoading: submitInProgress } = useAssignDealsToOperatingPartner();
  const { pushSuccessToast, pushErrorToast } = useToastMessage();
  const { startLoading, stopLoading } = useLoadingBar();

  const handleSetLocalUserDeals = useCallback((value) => {
    setLocalUserDeals(value);
  }, []);

  const handleAssignDeals = useCallback(() => {
    startLoading(LoadingId.assignDealsToUser);
    const operatingPartnerDeals = localUserDeals?.map((deal) => deal.id);
    onAssignDeals(
      {
        operatingPartnerDeals,
        id: user?.id,
      },
      {
        onSuccess: () => {
          pushSuccessToast({
            title: 'Deals Assigned',
            message: `Deals for ${user.name} Operating Partner Profile have been successfully assigned`,
          });
          handleAssignDealsModalClose();
        },
        onError: () => {
          pushErrorToast({ message: 'Failed to assigne deals' });
        },
        onSettled: () => {
          stopLoading(LoadingId.assignDealsToUser);
        },
      }
    );
  }, [
    handleAssignDealsModalClose,
    localUserDeals,
    onAssignDeals,
    pushErrorToast,
    pushSuccessToast,
    startLoading,
    stopLoading,
    user?.id,
    user.name,
  ]);

  return (
    <ModalComponent
      isOpen={assignDealsModalOpen}
      onClose={handleAssignDealsModalClose}
      actionButtonsProps={{
        isSubmitDisabled: submitInProgress,
        onSubmit: handleAssignDeals,
        onCancel: handleAssignDealsModalClose,
        submitText: 'Assign Deals',
      }}
      title='Assing Deals'
      top='80px'
    >
      <Content>
        <UserInfo user={user} totalDeals={localUserDeals?.length || 0} />
        <DealList localUserDeals={localUserDeals} setLocalUserDeals={handleSetLocalUserDeals} />
      </Content>
    </ModalComponent>
  );
}

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
