import { Chip, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { useTimesheetStatusColor } from '../../../hooks/useTimesheetStatusColor';
import { TimesheetStatusLabel, User } from '../../../types';
import { useAtomValue } from 'jotai';
import { userState } from '../../../state/UIState';
import { useMemo } from 'react';

type Props = {
  status: TimesheetStatusLabel;
  approvedManagers: User[];
};
export function StatusCell({ status, approvedManagers }: Props) {
  const { colors } = useTheme();
  const user = useAtomValue(userState);
  const statusColor = useTimesheetStatusColor(status);

  const statusLabel = useMemo(() => {
    if (status === TimesheetStatusLabel.REJECTED) {
      return TimesheetStatusLabel.REJECTED;
    }
    if (status === TimesheetStatusLabel.CLOSED) {
      return TimesheetStatusLabel.CLOSED;
    }
    if (status === TimesheetStatusLabel.ISSUED) {
      return TimesheetStatusLabel.ISSUED;
    }
    if (approvedManagers.some((manager) => manager.id === user?.id)) {
      return TimesheetStatusLabel.SUBMITTED;
    }
    return TimesheetStatusLabel.PENDING;
  }, [approvedManagers, user?.id, status]);

  return (
    <Chip
      style={{
        backgroundColor: colors.surfaceStatus.noAction,
        minWidth: '66px',
        height: '28px',
      }}
      label={
        <Typography variant='body' color={statusColor}>
          {/* //TODO change text color based on status */}
          {statusLabel}
        </Typography>
      }
    />
  );
}
