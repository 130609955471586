import { Box, Icon, Link, useTheme } from '@mui/material';
import { Typography } from '../../../components/Typography/Typography';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info-circle.svg';

function ContactAdminBanner() {
  const { colors } = useTheme();

  return (
    <Box display='flex' alignItems='center' gap='5px' marginBottom='1rem'>
      <Icon sx={{ svg: { g: { path: { fill: colors.icon.accent } } } }}>
        <InfoIcon />
      </Icon>
      <Typography variant='body' color={colors.text.secondary}>
        If you don&apos;t see the deals you&apos;re working on, please{' '}
        <Link
          href="mailto:support@svpglobal.com?subject=Missing deal's in the timesheet"
          target='_blank'
          underline='none'
        >
          contact the admin of the platform
        </Link>
      </Typography>
    </Box>
  );
}

export default ContactAdminBanner;
