import { useAtom } from 'jotai';
import { selectedThemeState } from '../state/UIState';
import { useMemo } from 'react';
import { ReactComponent as OrderBookMenuItemLight } from '../assets/icons/order-book-light.svg';
import { ReactComponent as OrderBookMenuItemDark } from '../assets/icons/order-book-dark.svg';
import { ReactComponent as CurrentTradesMenuItemLight } from '../assets/icons/current-trades-light.svg';
import { ReactComponent as CurrentTradesMenuItemDark } from '../assets/icons/current-trades-dark.svg';
import { ReactComponent as SearchIconLight } from '../assets/icons/search-light.svg';
import { ReactComponent as SearchIconDark } from '../assets/icons/search-dark.svg';
import { ReactComponent as PlusIconDark } from '../assets/icons/plus-dark.svg';
import { ReactComponent as PlusIconLight } from '../assets/icons/plus.svg';
import { ReactComponent as MinusIconDark } from '../assets/icons/minus-dark.svg';
import { ReactComponent as MinusIconLight } from '../assets/icons/minus.svg';
import { ReactComponent as ArrowRightDisabledIcon } from '../assets/icons/chevron-right-disabled.svg';
import { ReactComponent as ArrowRightIcon } from '../assets/icons/chevron-right.svg';
import { ReactComponent as ArrowDownIcon } from '../assets/icons/chevron-down.svg';
import { ReactComponent as LogoutIconDark } from '../assets/icons/logout-dark.svg';
import { ReactComponent as LogoutIconLight } from '../assets/icons/logout-light.svg';
import { ReactComponent as SafetyIconDark } from '../assets/icons/safety.svg';
import { ReactComponent as SafetyIconLight } from '../assets/icons/safety-light.svg';
import { ReactComponent as DownLoadIcon} from '../assets/icons/download-icon.svg';
import { THEME } from '../types';

export type Icon = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

export interface Icons {
  OrderBookMenuItem: Icon;
  CurrentTradesMenuItem: Icon;
  SearchIcon: Icon;
  PlusIcon: Icon;
  MinusIcon: Icon;
  ArrowRightDisabledIcon: Icon;
  ArrowRightIcon: Icon;
  ArrowDownIcon: Icon;
  LogoutIcon: Icon;
  SafetyIcon: Icon;
  DownLoadIcon: Icon;
}

const iconsMap: Record<THEME, Icons> = {
  [THEME.LIGHT]: {
    OrderBookMenuItem: OrderBookMenuItemLight,
    CurrentTradesMenuItem: CurrentTradesMenuItemLight,
    SearchIcon: SearchIconLight,
    PlusIcon: PlusIconLight,
    MinusIcon: MinusIconLight,
    ArrowRightDisabledIcon,
    ArrowRightIcon,
    ArrowDownIcon,
    LogoutIcon: LogoutIconLight,
    SafetyIcon: SafetyIconLight,
    DownLoadIcon: DownLoadIcon,
  },
  [THEME.DARK]: {
    OrderBookMenuItem: OrderBookMenuItemDark,
    CurrentTradesMenuItem: CurrentTradesMenuItemDark,
    SearchIcon: SearchIconDark,
    PlusIcon: PlusIconDark,
    MinusIcon: MinusIconDark,
    ArrowRightDisabledIcon: ArrowRightIcon,
    ArrowRightIcon: ArrowRightDisabledIcon,
    ArrowDownIcon,
    LogoutIcon: LogoutIconDark,
    SafetyIcon: SafetyIconDark,
    DownLoadIcon: DownLoadIcon,
  },
};

export function useIcons(): Icons {
  const [selectedTheme] = useAtom(selectedThemeState);

  const value = useMemo(() => {
    const selectedIcons = iconsMap[selectedTheme as THEME] || iconsMap[THEME.DARK];
    return selectedIcons;
  }, [selectedTheme]);

  return value;
}
