import { PageHeader } from '../layout/PageHeader';
import { Button, useTheme } from '@mui/material';
import { ReactComponent as AddIcon } from '../../assets/icons/add-white.svg';
import { Typography } from '../../components/Typography/Typography';
import { ContentWrapper, HeaderActionWrapper } from '../layout/styled';
import { DealsTable } from './components/DealsTable';
import { useCallback, useState } from 'react';
import { DealFormModal } from './components/DealFormModal/DealFormModal';

const HeaderAction = () => {
  const { colors } = useTheme();
  const [addDealOpen, setAddDealOpen] = useState(false);

  const handleAddDealOpen = useCallback(() => {
    setAddDealOpen(true);
  }, []);

  const handleAddDealClose = useCallback(() => {
    setAddDealOpen(false);
  }, []);
  return (
    <HeaderActionWrapper>
      <Button
        onClick={handleAddDealOpen}
        variant='contained'
        style={{ height: '40px' }}
        startIcon={<AddIcon />}
      >
        <Typography variant='h4' color={colors.textAccent.inverse} style={{ marginBottom: '-1px' }}>
          Add Deal
        </Typography>
      </Button>
      {addDealOpen && (
        <DealFormModal addDealOpen={addDealOpen} handleAddDealClose={handleAddDealClose} />
      )}
    </HeaderActionWrapper>
  );
};

export const Deals = () => {
  return (
    <>
      <PageHeader
        title='Deals'
        description=''
        breadcrumbs={[{ title: 'Administration' }, { title: 'Deals' }]}
        actionComponent={<HeaderAction />}
      />
      <ContentWrapper>
        <DealsTable />
      </ContentWrapper>
    </>
  );
};
