import { Box, useTheme } from '@mui/material';
import {
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { TableV1 } from '../../../components/Table/TableV1';
import { Typography } from '../../../components/Typography/Typography';
import { Holiday, TimesheetRow, WeeklyTimesheet } from '../../../types';
import { useAccountantSingleWeekTableColumns } from '../hooks/useAccountantSingleWeekTableColumns';

interface AccountantSingleTimesheetWeekTableProps {
  weeklyTimesheet: WeeklyTimesheet[];
}

interface WeekTableProps {
  index: number;
  tableData: TimesheetRow[];
  weekDates: string[];
  weekStart: string;
  holidays: Holiday[];
}

export function WeekTable({ tableData, index, weekDates, weekStart, holidays }: WeekTableProps) {
  const { colors } = useTheme();
  const columns = useAccountantSingleWeekTableColumns(weekDates, weekStart, holidays);

  const weekTable = useReactTable({
    data: tableData ?? [],
    columns,
    enableSorting: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (item) => String(item.id),
  });

  return (
    <Box marginTop={3}>
      <Typography variant='h4' color={colors.text.main}>
        {`Week ${index + 1}`}
      </Typography>
      <TableV1
        hasFooter
        table={weekTable}
        height='auto'
        wrapperStyle={{
          maxHeight: 'calc(100vh - 288px)',
          border: `1px solid ${colors.border.default}`,
          marginTop: '10px',
        }}
      />
    </Box>
  );
}

export function AccountantSingleTimesheetWeekTable({
  weeklyTimesheet,
}: AccountantSingleTimesheetWeekTableProps) {
  return (
    <>
      {weeklyTimesheet?.map((timesheet, index) => (
        <WeekTable
          tableData={timesheet.timesheetRows}
          index={index}
          key={`table-${index}`}
          holidays={timesheet.holidays}
          weekDates={timesheet.weekDates}
          weekStart={timesheet.weekStart}
        />
      ))}
    </>
  );
}
