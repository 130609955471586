import {
  styled,
  TextareaAutosize as MuiTextareaAutosize,
  TextareaAutosizeProps,
} from '@mui/material';
import { FC } from 'react';

const TextareaAutosizeStyled = styled(MuiTextareaAutosize)`
  width: 100%;
  border: none;
  resize: none;
  background-color: transparent;
  outline: 0px none transparent;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  font-family: ${({ theme }) => theme.text.font.basierRegular};
  color: ${({ theme }) => theme.colors.text.secondary};
  border: 1px solid ${({ theme }) => theme.colors.border.default};
  padding: 4px 14px;
  border-radius: 4px;
  ::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    font-family: ${({ theme }) => theme.text.font.basierRegular};
    color: ${({ theme }) => theme.colors.text.caption};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.border.accent};
  }
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.border.accent};
  }
`;

export const TextareaAutosize: FC<TextareaAutosizeProps> = (props) => {
  return <TextareaAutosizeStyled {...props} />;
};
