import { Box, Button, useTheme } from '@mui/material';
import { HeaderContext } from '@tanstack/table-core';
import { useParams } from 'react-router';
import { Typography } from '../../../../components/Typography/Typography';
import { useLoadingBar } from '../../../../hooks/useLoadingBar';
import { useToastMessage } from '../../../../hooks/useToastMessage';
import { useClearTimesheetColumn } from '../../../../queries/hooks/useClearTimesheetColumn';
import { LoadingId, TimesheetRow, TimesheetStatus, WeekDay } from '../../../../types';
import { useSingleTimesheet } from '../../../../queries/hooks/useSingleTimesheet';
import { useMemo, useState } from 'react';
import { ReactComponent as CalendarDisabledIcon } from '../../../../assets/icons/calendar-disabled.svg';
import TooltipComponent from '../../../../components/TooltipComponent/TooltipComponent';
import { useSetAtom } from 'jotai';
import { loadingClearColumn } from '../../../../state/UITimesheetsState';

interface Props {
  row: HeaderContext<TimesheetRow, number>;
  text: string;
  style?: React.CSSProperties;
}

export const TableHeaderCellWithClear = ({ row, text, style }: Props) => {
  const { id } = useParams();
  const [showToolip, setShowToolip] = useState(false);
  const setLoading = useSetAtom(loadingClearColumn);

  const { data: singleTimesheet, isLoading: tsLoading } = useSingleTimesheet({
    id: Number(id),
  });

  const { colors } = useTheme();
  const { pushErrorToast, pushSuccessToast } = useToastMessage();
  const { startLoading, stopLoading } = useLoadingBar();

  const holiday = useMemo(() => {
    if (!text) return;
    const dayLabel = Number(text.split(',')[1].split('/')[1]);
    const monthLabel = Number(text.split(',')[1].split('/')[0]);

    return singleTimesheet?.holidays.find(
      (holiday) => holiday.day === dayLabel && holiday.month === monthLabel
    );
  }, [singleTimesheet, text]);

  const weekDay = row.header.id as WeekDay;
  const shouldDisableDay = singleTimesheet?.timesheetRows[0][weekDay] === null;
  const { mutate: onClearColumn } = useClearTimesheetColumn();
  const disableClear =
    shouldDisableDay ||
    (singleTimesheet?.status !== TimesheetStatus.ISSUED &&
      singleTimesheet?.status !== TimesheetStatus.REJECTED) ||
    tsLoading;
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '5px',
          alignItems: 'center',
        }}
      >
        <Typography
          variant='subtitle1'
          color={shouldDisableDay ? colors.textAccent.disabled : colors.text.main}
          style={{ letterSpacing: '0.25px', ...style }}
        >
          {text}
        </Typography>
        {!!holiday && (
          <Box marginTop='4px'>
            <TooltipComponent
              open={showToolip}
              onClose={() => setShowToolip(false)}
              onOpen={() => setShowToolip(true)}
              placement='top'
              content={
                <Typography variant='body' color={colors.text.main}>
                  {holiday.name}
                </Typography>
              }
            >
              <CalendarDisabledIcon
                onMouseEnter={() => setShowToolip(true)}
                onMouseLeave={() => setShowToolip(false)}
              />
            </TooltipComponent>
          </Box>
        )}
      </Box>
      {!disableClear && (
        <Button
          variant='text'
          sx={{
            paddingRight: 0,
          }}
          onClick={() => {
            startLoading(LoadingId.clearColumn);
            setLoading(true);
            onClearColumn(
              {
                timesheetId: Number(id),
                weekDay,
              },
              {
                onSuccess: () => {
                  pushSuccessToast({
                    title: 'Column is Cleared',
                    message: `The column has been cleared successfully.`,
                  });
                  setLoading(false);
                },
                onError: () => {
                  pushErrorToast({ message: 'Failed to update cell' });
                },
                onSettled: () => {
                  stopLoading(LoadingId.clearColumn);
                },
              }
            );
          }}
        >
          <Typography color={colors.textAccent.default} variant='subtitle1'>
            Clear
          </Typography>
        </Button>
      )}
    </Box>
  );
};
