import { useMutation } from 'react-query';
import { Http } from '../../services/Http';
import { User } from '../../types';
import { MUTATION_KEYS } from '../constants';
import { useInvalidateUsers } from './useUsers';

type Payload = {
  operatingPartnerDeals: number[];
  id: number;
};

async function assignDeals({ operatingPartnerDeals, id }: Payload): Promise<User[]> {
  const { data } = await Http.axios.patch<{ operatingPartnerDeals: number[] }, User[]>(
    `/user/${id}`,
    {
      operatingPartnerDeals,
    }
  );
  return data;
}

export const useAssignDealsToOperatingPartner = () => {
  const invalidateUsers = useInvalidateUsers();
  return useMutation(assignDeals, {
    mutationKey: MUTATION_KEYS.ASSIGN_DEALS_TO_OPERATING_PARTNER_KEY,
    onSuccess: () => {
      invalidateUsers();
    },
  });
};
