import { styled, useTheme } from '@mui/material';
import { Typography } from '../../components/Typography/Typography';
import { FC } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { Breadcrumb } from '../../types';

const Wrapper = styled('div')`
  padding: 28px 36px;
`;

const MainSection = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TitleAndSubtitleWrapper = styled('div')``;

interface Props {
  title: string | JSX.Element;
  description?: string;
  breadcrumbs: Breadcrumb[];
  actionComponent?: React.ReactNode;
}

export const PageHeader: FC<Props> = ({ title, description, breadcrumbs, actionComponent }) => {
  const { colors } = useTheme();

  return (
    <Wrapper>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <MainSection>
        <TitleAndSubtitleWrapper>
          <Typography
            variant='h1'
            color={colors.text.main}
            style={{ fontSize: '32px', lineHeight: '42px', marginBottom: '4px' }}
          >
            {title}
          </Typography>
          {description && (
            <Typography variant='body' color={colors.text.secondary}>
              {description}
            </Typography>
          )}
        </TitleAndSubtitleWrapper>
        {actionComponent && actionComponent}
      </MainSection>
    </Wrapper>
  );
};
