import { useQuery, useQueryClient } from 'react-query';
import { Http } from '../../services/Http';
import { QUERY_KEYS } from '../constants';
import { User } from '../../types';

async function fetchUserOperatingPartners(yearQueryParam?: string): Promise<User[]> {
  const currentYear = new Date().getFullYear();
  const { data } = await Http.axios.get<User[]>(
    `/user/operating-partners?year=${yearQueryParam || currentYear}`
  );
  return data;
}

export const useAllUserOperatingPartners = (yearQueryParam?: string) => {
  return useQuery(
    [QUERY_KEYS.USER_OPERATING_PARTNERS, yearQueryParam],
    () => fetchUserOperatingPartners(yearQueryParam),
    {
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    }
  );
};

export const useInvalidateUserOperatingPartners = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.USER_OPERATING_PARTNERS,
    });
  };
};
