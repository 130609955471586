import { useQuery, useQueryClient } from 'react-query';
import { Http } from '../../services/Http';
import { Holiday } from '../../types';
import { QUERY_KEYS } from '../constants';

async function fetchHolidays(yearQueryParam?: string): Promise<Holiday[]> {
  const currentYear = new Date().getFullYear();
  const { data } = await Http.axios.get<Holiday[]>(
    `/holiday?year=${yearQueryParam || currentYear}`
  );
  return data
    .filter(
      (holiday, index, arr) =>
        index === arr.findIndex((val) => val.multidayId === holiday.multidayId)
    )
    .map((holiday) => {
      const year = yearQueryParam || currentYear;
      return {
        ...holiday,
        startDate: year + holiday.startDate.slice(4),
        endDate: holiday.endDate ? year + holiday.endDate.slice(4) : null,
      };
    });
}

export const useHolidays = (yearQueryParam?: string) => {
  return useQuery([QUERY_KEYS.HOLIDAYS, yearQueryParam], () => fetchHolidays(yearQueryParam), {
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
};

export const useInvalidateHolidays = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.HOLIDAYS,
    });
  };
};
