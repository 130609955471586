import { Button, useTheme } from '@mui/material';
import { ReactComponent as RemoveIcon } from '../../../assets/icons/close-grey.svg';
import { Typography } from '../../../components/Typography/Typography';
import { useLoadingBar } from '../../../hooks/useLoadingBar';
import { useToastMessage } from '../../../hooks/useToastMessage';
import { useAssignProxyToOperatingPartner } from '../../../queries/hooks/useAssignProxyToOperatingPartner';
import { LoadingId, User } from '../../../types';

type Props = {
  user: User;
};

export function UnassignProxy({ user }: Props) {
  const { colors } = useTheme();
  const { mutate: onAssignProxyUser, isLoading: submitInProgress } =
    useAssignProxyToOperatingPartner();

  const { pushSuccessToast, pushErrorToast } = useToastMessage();
  const { startLoading, stopLoading } = useLoadingBar();

  const handleUnassignProxy = () => {
    startLoading(LoadingId.assignProxyToUser);
    onAssignProxyUser(
      {
        proxy: null,
        id: user.id,
      },
      {
        onSuccess: () => {
          pushSuccessToast({
            title: 'Proxy unassigned',
            message: `Proxy for ${user.name} Operating Partner Profile have been successfully unassigned`,
          });
        },
        onError: () => {
          pushErrorToast({ message: 'Failed to remove proxy' });
        },
        onSettled: () => {
          stopLoading(LoadingId.assignProxyToUser);
        },
      }
    );
  };

  return (
    <Button
      onClick={handleUnassignProxy}
      variant='text'
      style={{
        height: '28px',
        backgroundColor: colors.surfaceBackground.bg1,

        border: `1px solid ${colors.border.default}`,
      }}
      disabled={submitInProgress}
      startIcon={<RemoveIcon />}
      sx={{
        '.MuiButton-startIcon': { marginRight: '2px' },
        '&.Mui-disabled': { opacity: 0.4, cursor: 'not-allowed', pointerEvents: 'auto' },
        svg: { g: { path: { fill: colors.icon.accent } } },
      }}
    >
      <Typography variant='h4' color={colors.textAccent.default} style={{ marginBottom: '-2px' }}>
        Unassign
      </Typography>
    </Button>
  );
}
