import { Text } from './structure';

export const text: Text = {
  font: {
    basierRegular: 'BasierSquare-Regular',
    basierMedium: 'BasierSquare-Medium',
    basierBold: 'BasierSquare-Bold',
    argestaRegular: 'ArgestaDisplay-Regular',
    salvaTest: 'SalvaTest-Regular',
  },
  weight: {
    regular: 400,
    medium: 500,
  },
  size: {
    extraSmall: 10,
    small: 12,
    mediumSmall: 14,
    medium: 16,
    mediumLarge: 20,
    large: 24,
    extraLarge: 28,
    extraExtraLarge: 32,
  },
  lineHeight: {
    extraSmall: '16px',
    small: '18px',
    mediumSmall: '20px',
    medium: '24px',
    mediumLarge: '30px',
    large: '36px',
    extraLarge: '38px',
    extraExtraLarge: '48px',
  },
  letterSpace: {
    regular: '0px',
    large: '0.4px',
  },
};
