import { useTheme } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from '../../../components/Typography/Typography';

import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';

type Props = {
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  toggleDeleteDeal: () => void;
  toggleEditDealOpen: () => void;
};

export default function ActionsMenu({
  anchorEl,
  handleClose,
  toggleDeleteDeal,
  toggleEditDealOpen,
}: Props) {
  const open = Boolean(anchorEl);
  const { colors } = useTheme();
  return (
    <Menu
      id='demo-positioned-menu'
      aria-labelledby='demo-positioned-button'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <MenuItem
        sx={{ svg: { g: { path: { fill: colors.icon.default } } } }}
        onClick={() => {
          handleClose();
          toggleEditDealOpen();
        }}
      >
        <EditIcon fontSize='small' />
        <Typography
          variant='body'
          color={colors.text.main}
          style={{ marginLeft: '4px', marginBottom: '-2px' }}
        >
          Edit deal
        </Typography>
      </MenuItem>
      <MenuItem
        sx={{ svg: { g: { path: { fill: colors.icon.default } } } }}
        onClick={() => {
          handleClose();
          toggleDeleteDeal();
        }}
      >
        <DeleteIcon fontSize='small' />
        <Typography
          variant='body'
          color={colors.text.main}
          style={{ marginLeft: '4px', marginBottom: '-2px' }}
        >
          Delete deal
        </Typography>
      </MenuItem>
    </Menu>
  );
}
