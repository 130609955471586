import { Icon, styled, useTheme } from '@mui/material';
import {
  ColumnFiltersState,
  SortingState,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { TableSkeletonLoader } from '../../../components/SkeletonLoader/Table.SkeletonLoader';
import { TableV1 } from '../../../components/Table/TableV1';
import { useTimesheets } from '../../../queries/hooks/useTimesheets';
import { usePendingTimesheetsTableColumns } from '../hooks/usePendingTimesheetsTableColumns';
import { FilterSection } from './FilterSection';
import { useAtom, useAtomValue } from 'jotai';
import { userState } from '../../../state/UIState';
import { showOnboardingState } from '../../../state/UITimesheetsState';
import { TimesheetStatus, UserRole } from '../../../types';
import { useProxyIssuedTimesheetsTableColumns } from '../hooks/useProxyIssuedTimesheetsTableColumns';
import OnboardingIssuedTimesheets from './OnboardingIssuedTimesheets';
import { TS_MOCK_DATA } from '../../../components/Onboarding/OnboardingMockData';
import { mapTsStatuses } from '../../../utils/tsMappers';
import ContactAdminBanner from './ContactAdminBanner';
import { Typography } from '../../../components/Typography/Typography';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning-circle.svg';

export function IssuedTimesheetsTable() {
  const [showOnboarding, setShowOnboarding] = useAtom(showOnboardingState);
  const { colors } = useTheme();
  const user = useAtomValue(userState);
  const isOP = user?.role === UserRole.OPERATING_PARTNER || (user?.role === UserRole.OP_MANAGER && user?.isOp);

  const { isLoading: areIssuedTimesheetsLoading, data: timesheets } = useTimesheets({
    userId: user?.id,
  });

  useEffect(() => {
    const hasCompletedOnboarding = localStorage.getItem('hasCompletedOnboarding');
    if (hasCompletedOnboarding) {
      setShowOnboarding(false);
    }
  }, [setShowOnboarding]);

  const issuedTimesheets = useMemo(() => {
    if (!timesheets) return [];
    if (isOP)
      return timesheets.filter(
        (ts) => ts.status === TimesheetStatus.ISSUED || ts.status === TimesheetStatus.REJECTED
      );
    return timesheets.filter((ts) => ts.status === TimesheetStatus.ISSUED);
  }, [timesheets, isOP]);

  const countRejectedTS = useMemo(() => {
    return timesheets?.filter((ts) => ts.status === TimesheetStatus.REJECTED).length;
  }, [timesheets]);

  const statusMappedData = useMemo(() => {
    return mapTsStatuses(issuedTimesheets, UserRole.OPERATING_PARTNER);
  }, [issuedTimesheets]);

  const useTableColumns = (() => {
    switch (user?.role) {
      case UserRole.PROXY:
        return useProxyIssuedTimesheetsTableColumns;
      case UserRole.OPERATING_PARTNER:
        return usePendingTimesheetsTableColumns;
      default:
        return usePendingTimesheetsTableColumns;
    }
  })();

  const columns = useTableColumns();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const table = useReactTable({
    data: showOnboarding ? TS_MOCK_DATA : statusMappedData ?? [],
    columns,
    state: {
      sorting,
      columnFilters,
    },
    enableSorting: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (issuedItem) => String(issuedItem.id),
  });

  if (areIssuedTimesheetsLoading || !issuedTimesheets) return <TableSkeletonLoader />;

  return (
    <div>
      {isOP && showOnboarding && (
        <OnboardingIssuedTimesheets setShowOnboarding={setShowOnboarding} />
      )}
      {isOP && <ContactAdminBanner />}
      {isOP && !!countRejectedTS && (
        <RejectedBanner>
          <Icon sx={{ svg: { path: { fill: colors.iconStatus.error } } }}>
            <WarningIcon />
          </Icon>
          <Typography variant='h5' color={colors.textStatus.error}>
            {`${countRejectedTS} ${countRejectedTS > 1 ? 'Timesheets' : 'Timesheet'} Sent Back`}
          </Typography>
        </RejectedBanner>
      )}
      <FilterSection table={table} columnFilters={columnFilters} />
      <TableV1
        className='timesheet-table'
        table={table}
        height='auto'
        wrapperStyle={{
          maxHeight: showOnboarding ? 'calc(100vh - 525px)' : 'calc(100vh - 288px)',
          border: `1px solid ${colors.border.default}`,
        }}
      />
    </div>
  );
}

const RejectedBanner = styled('div')`
  background-color: ${({ theme }) => theme.colors.surfaceBackground.highlighted};
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  margin-bottom: 1rem;
`;
