import { atom } from 'jotai';
import { SelectItem } from '../types';

// All timesheets table
export const allTableSelectedDate = atom<string | null>(null);
export const allTableSearch = atom<string>('');
export const allTableSelectedStatus = atom<SelectItem | null>(null);
export const allTableSelectedOPs = atom<SelectItem[]>([]);

//Issued timesheets table
export const issuedTableSearch = atom<string>('');
export const issuedSelectedDate = atom<string | null>(null);
export const issuedSelectedOPs = atom<SelectItem[]>([]);
export const showOnboardingState = atom<boolean>(false);

export const loadingClearColumn = atom<boolean>(false);
