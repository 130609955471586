import { useMutation } from 'react-query';
import { Http } from '../../services/Http';
import { MUTATION_KEYS } from '../constants';
import { useInvalidateHolidays } from './useHolidays';
import { Holiday } from '../../types';

async function bulkDeleteHoliday(multidayIds: string[]): Promise<Holiday[]> {
  const { data } = await Http.axios.delete<Holiday[]>('/holiday/bulk', {
    data: multidayIds
  });
  return data;
}

export const useBulkDeleteHoliday = () => {
  const invalidateHolidays = useInvalidateHolidays();

  return useMutation(bulkDeleteHoliday, {
    mutationKey: MUTATION_KEYS.BULK_DELETE_HOLIDAY_KEY,
    onSuccess: () => {
        invalidateHolidays();
    },
  });
};
