import { Box, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useAtomValue } from 'jotai';
import { ReactComponent as ReportIcon } from '../../../../assets/icons/report.svg';
import { TableV1 } from '../../../../components/Table/TableV1';
import {
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useParams } from 'react-router';
import { FormValidationMessage } from '../../../../components/FormValidationMessage/FormValidationMessage';
import { TableSkeletonLoader } from '../../../../components/SkeletonLoader/Table.SkeletonLoader';
import { useSingleTimesheet } from '../../../../queries/hooks/useSingleTimesheet';
import { TimesheetRow, TimesheetStatus, UserRole } from '../../../../types';
import { formatWeekName } from '../../../../utils/formatters';
import { useSingleTableColumns } from '../../hooks/useSingleTableColumns';
import { SingleTimesheetHeader } from './SingleTimesheetHeader';
import {
  SINGLE_TS_TABLE_MOCK_DATA,
  SINGLE_TS_TABLE_MOCK_DATA_SUBMITTED,
} from '../../../../components/Onboarding/OnboardingMockData';
import OnboardingSingleTimesheet from './OnboardingSingleTimesheet';
import { userState } from '../../../../state/UIState';
import ContactAdminBanner from '../../../IssuedTimesheets/components/ContactAdminBanner';
import RejectedTimesheetBanner from './RejectedTimesheetBanner';
import { getWeeklyTimesheetWeekStart } from '../../../../utils/getWeeklyTimesheetWeekStart';

export function SingleTimesheetTable() {
  const { colors } = useTheme();
  const { id } = useParams();
  const user = useAtomValue(userState);
  const isOP = user?.role === UserRole.OPERATING_PARTNER;

  const isOnboarding = id === 'demo';
  // find first monday in the past and get iso string
  const demoWeekStartIso = useMemo(() => {
    if (!isOnboarding) return '';
    const date = new Date();
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    date.setDate(diff);
    return date.toISOString();
  }, [isOnboarding]);

  const { data: singleTimesheet, isLoading: dataIsLoading } = useSingleTimesheet({
    id: Number(id),
    enabled: !isOnboarding,
  });

  const columns = useSingleTableColumns(singleTimesheet?.weekStart ?? demoWeekStartIso, singleTimesheet?.weekDates ?? []);

  const [data, setData] = useState<TimesheetRow[]>([]);
  const [updateOnboardingData, setUpdateOnboardingData] = useState(false);

  const weekStart = useMemo(() => {
    if (!singleTimesheet) return '';
    return getWeeklyTimesheetWeekStart(singleTimesheet);
  }, [singleTimesheet]);

  const weekLength = useMemo(() => {
    if (!singleTimesheet) return 0;
    return singleTimesheet.weekDates.length + singleTimesheet.holidays.length;
  }, [singleTimesheet]);

  const weekName = formatWeekName(weekStart, weekLength);

  useEffect(() => {
    const updatedTimesheetRows = (singleTimesheet?.timesheetRows || []).map((row) => {
      return {
        ...row,
        editDisabled:
          singleTimesheet?.status !== TimesheetStatus.ISSUED &&
          singleTimesheet?.status !== TimesheetStatus.REJECTED,
      };
    });
    setData(updatedTimesheetRows || []);
  }, [singleTimesheet]);

  useEffect(() => {
    if (isOnboarding && !updateOnboardingData) {
      setData(SINGLE_TS_TABLE_MOCK_DATA);
    }
  }, [isOnboarding, updateOnboardingData]);

  useEffect(() => {
    if (updateOnboardingData) {
      setData(SINGLE_TS_TABLE_MOCK_DATA_SUBMITTED);
    }
  }, [updateOnboardingData]);

  const onUpdateOnboardingData = () => {
    setUpdateOnboardingData(true);
  };

  const table = useReactTable({
    data: data ?? [],
    columns,
    enableSorting: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: (item) => String(item.id),
    meta: {
      updateData: (rowIndex: number, columnId: string, value: string) => {
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
    },
  });

  const validationErrors = useMemo(() => {
    const accessors = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
    const errors = {};

    accessors.forEach((accessor) => {
      const total = table
        .getFilteredRowModel()
        .rows.reduce((total, row) => total + Number(row.getValue(accessor)), 0);
      const formatedAccessorName = accessor[0].toUpperCase() + accessor.slice(1);
      if (total > 100) {
        errors[
          accessor
        ] = `${formatedAccessorName}, ${weekName} timesheet exceeds 100%. Please correct the data.`;
      }
    });
    return errors;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, data]);

  if (!isOnboarding && dataIsLoading) return <TableSkeletonLoader />;

  return (
    <>
      <SingleTimesheetHeader
        data={data}
        table={table}
        updateOnboardingData={updateOnboardingData}
      />
      {isOP && <ContactAdminBanner />}
      {singleTimesheet?.status === TimesheetStatus.REJECTED && (
        <RejectedTimesheetBanner timesheet={singleTimesheet} />
      )}
      <TableV1
        className='single-timesheet'
        hasFooter
        table={table}
        height='auto'
        disableRowHover
        wrapperStyle={{
          maxHeight: 'calc(100vh - 288px)',
          border: `1px solid ${colors.border.default}`,
        }}
      />
      <Box mt={2} display={'flex'} flexDirection={'column'} gap={2}>
        {Object.keys(validationErrors).map((key) => {
          return (
            <FormValidationMessage
              key={key}
              text={validationErrors[key]}
              Icon={<ReportIcon />}
              textColor={colors.textStatus.error}
            />
          );
        })}
      </Box>
      {isOnboarding && (
        <OnboardingSingleTimesheet onUpdateOnboardingData={onUpdateOnboardingData} />
      )}
    </>
  );
}
