import { PageHeader } from '../layout/PageHeader';
import { ContentWrapper } from '../layout/styled';
import { AllTimesheetsTable } from './components/AllTimesheetsTable';

export const AllTimesheets = () => {
  return (
    <>
      <PageHeader
        title='All Timesheets'
        description=''
        breadcrumbs={[{ title: 'All Timesheets' }]}
      />
      <ContentWrapper>
        <AllTimesheetsTable />
      </ContentWrapper>
    </>
  );
};
